import { ReactNode, useEffect, useState } from 'react'
import LoadingSplash from '../LoadingSplash';
import { useThemeState } from './ThemeContext';

export default function ThemeWrapper({ children }: { children: ReactNode }) {
    const { theme, setTheme } = useThemeState();
    const [loaded, setLoaded] = useState(false);

    // Load theme from local storage
    useEffect(() => {
        const localTheme = window.localStorage.getItem("theme");
        if (localTheme) {
            setTheme(JSON.parse(localTheme));
        }
        setLoaded(true);
    }, []);

    if (!loaded) return <LoadingSplash />;
    return (
        <div data-theme={theme.value} className="text-neutral bg-neutral h-auto h-min-full">
            {children}
        </div>
    )
}
