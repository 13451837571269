import Button from "../../../common/Button";
import Datalist from "../../../common/Datalist";
import { getOperator, nullOperator, existsOperator, QueryField, validOperators } from ".././types";

export default function QueryBuilder({ fields, addField, removeField, updateField, fieldsList }: { fields: QueryField[]; addField: () => void; removeField: (index: number) => void; updateField: (index: number, field: QueryField) => void; fieldsList: string[] }) {
  return (
    <div>
      <div className="flex flex-col gap-2">
        {fields.map((field, i) => (
          <QueryItem key={field.id} field={field} removeField={removeField} updateField={updateField} index={i} />
        ))}
        <Button className="btn btn-sm btn-secondary" onClick={addField}>
          Add Field
        </Button>
      </div>
      <Datalist id="collection-fields-select" options={fieldsList} />
    </div>
  );
}

function QueryItem({ field, index, removeField, updateField }: { field: QueryField; index: number; removeField: (index: number) => void; updateField: (index: number, field: QueryField) => void }) {
  return (
    <div className="form-control" key={index}>
      <div className="relative flex flex-row">
        <input
          type="text"
          placeholder="Field"
          value={field.name ? field.name : ""}
          list="collection-fields-select"
          className="flex-grow input input-secondary input-bordered rounded-r-none bg-neutral text-neutral-content placeholder-neutral-content placeholder-opacity-50"
          onChange={(e) => {
            updateField(index, { ...field, name: e.target.value });
          }}
        />
        <select
          className="btn-secondary select select-bordered rounded-none max-w-xs"
          value={field.operator.value ? field.operator.value : ""}
          onChange={(e) => {
            updateField(index, { ...field, operator: getOperator(e.target.options[e.target.selectedIndex].value) });
          }}
        >
          {validOperators.map((operator, i) => (
            <option key={i} value={operator.value}>
              {operator.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Value"
          value={field.operator === nullOperator ? "null" : field.operator === existsOperator ? "true" : field.value ? field.value : ""}
          disabled={field.operator === nullOperator || field.operator === existsOperator}
          className="flex-grow input input-secondary input-bordered rounded-none bg-neutral text-neutral-content placeholder-neutral-content placeholder-opacity-50"
          onChange={(e) => {
            updateField(index, { ...field, value: e.target.value });
          }}
        />
        <Button className="rounded-l-none btn text-lg" type="secondary" onClick={() => removeField(field.id)}>
          &#215;
        </Button>
      </div>
    </div>
  );
}
