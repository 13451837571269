import { CSVLink } from "react-csv";
import { ChangeEvent, useMemo, useState } from "react";
import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import SingleQueryResult from "./SingleQueryResult";
import { jsonToCsv } from "../utils/json2csv";

export default function QueryResultView({
  resultFlat,
  resultSplit,
  viewModeToggle,
  viewAsJSON,
  tenant,
  collection,
}: {
  resultFlat: Record<string, any>[];
  resultSplit: Record<string, any>[];
  viewModeToggle?: () => void;
  viewAsJSON: boolean;
  tenant: tenantBaseInfo;
  collection: string;
}) {
  const [expandedSections, setExpandedSections] = useState<number[]>(resultFlat.length === 1 ? [0] : []);
  const expandSection = (section: number) => {
    setExpandedSections(prevSections => [...prevSections, section]);
  };
  const collapseSection = (section: number) => {
    setExpandedSections(prevSections => prevSections.filter(s => s !== section));
  };

  // fieldsParent = all headers - use for displaying checkboxes
  const fieldsParent = useMemo(() => {
    const parentObj: Record<string, any> = {};
    Object.keys(resultFlat[0] || {}).forEach(key => {
      const parentKey = key.split('.')[0];
      parentObj[parentKey] = true; // value doesn't matter, we just want the keys
    });
    return Object.keys(parentObj);
  }, [resultFlat]);

  const [isCheckAll, setIsCheckAll] = useState(true);
  // fieldsSelected = chosen headers, use for export
  const [fieldsSelected, setFieldsSelected] = useState<string[]>(fieldsParent)

  // handle checking options, creates new array of values
  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll(prev => !prev);
    setFieldsSelected(isCheckAll ? [] : fieldsParent);
  }

  // handle checking options, creates new array of values
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFieldsSelected(prevSelected => checked ? [...prevSelected, id] : prevSelected.filter(item => item !== id));

    
  }

  const csvData = jsonToCsv(resultFlat, fieldsSelected);

  return (
    <div className="flex flex-col gap-2">
      {viewModeToggle && (
        <button onClick={viewModeToggle} className="badge bg-primary border-opacity-0 px-4 pb-4 pt-3 absolute right-0 top-0 rounded-box rounded-t-none rounded-r-none text-primary-content hover:bg-primary-focus">
          view as {viewAsJSON ? "table" : "json"}
        </button>
      )}
      <CSVLink data={csvData} separator="," filename={`${tenant.id}_${collection}_export.csv`} className="btn mr-2 w-44 shadow-md min-h-0 h-7">
        Download as CSV
      </CSVLink>
      <div className="text-xs leading-6">
        <input type="checkbox" key="selectall" id="selectall" defaultChecked={true} onChange={handleSelectAll} /> Select All&nbsp;&nbsp;
        {[...Array(fieldsParent.length)].map((x, i) =>
          <span className="inline-block" key={i + "check"}>
            <input type="checkbox" key={i} id={fieldsParent[i]} onChange={handleCheck} checked={fieldsSelected.includes(fieldsParent[i])} />
            {" " + fieldsParent[i]}&nbsp;&nbsp;
          </span>
        )}
      </div>
      {resultSplit.map((row, index) => (
        <div key={`documents-row-${index}`}>
          <SingleQueryResult
            jsonSplit={row}
            jsonFlat={resultFlat[index]}
            tenant={tenant}
            collection={collection}
            expanded={expandedSections.includes(index)}
            setExpanded={(e: boolean) => {
              e ? expandSection(index) : collapseSection(index);
            }}
            totalDocs={resultSplit.length}
            index={index}
            viewAsJSON={viewAsJSON}
          />
        </div>
      ))}
    </div>
  );
}
