import React, { useCallback, useState } from "react";
import { useMessageListener } from "../useMessageListener";
import ToastContainer from "./ToastContainer";

const ToastContext = React.createContext<{
    removeToast: (id: number) => void;
    addToast: (toast: ToastInterface) => void;
}>({ addToast: () => { }, removeToast: () => { } });
let id = 0

export const ToastProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [toasts, setToasts] = useState([] as internalToastInterface[]);

    const addToast = useCallback((toast: ToastInterface) => {
        setToasts(toasts => [...toasts, { ...toast, id: id++ }]);
    }, [setToasts]);

    const removeToast = useCallback((id: number) => {
        setToasts(toasts => toasts.filter(t => t.id !== id));
    }, [setToasts]);

    useMessageListener(addToastMessageAction, (p: ToastInterface) => {
        addToast(p)
    })

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            <ToastContainer toasts={toasts} />
            {children}
        </ToastContext.Provider>
    )
}

export function useToast() {
    const toastHelpers = React.useContext(ToastContext);
    return toastHelpers;
}


export interface ToastInterface {
    title: string;
    message: string;
    context?: string;
    timeout?: number;
    type: "warning" | "error" | "success" | "info";
}

export interface internalToastInterface extends ToastInterface {
    id: number;
}

export const addToastMessageAction = "ADD_TOAST"