import { ReturnDetails } from "./HPDetails"

interface StatusProps {
    status: boolean | null
    metric: string
    critical?: boolean | null
}

// status false = metric is 0
export default function StatusIcon({ status, metric, critical }: StatusProps) {

    const details: string | undefined = ReturnDetails(metric, true).details

    if (status) {
        return (
            // Green check
                <div className="dropdown dropdown-left dropdown-hover dropdown-end text-left">
                    <svg className="fill-accent" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="-0.144 0 14.72 14.72">
                        <path d="M7.188 1.725q-1.553 0-2.875.776T2.214 4.6t-.776 2.875.776 2.875 2.099 2.099 2.875.776 2.875-.776 2.099-2.099.776-2.875-.776-2.875-2.099-2.099-2.875-.776zm-.719 8.884L3.335 7.59 4.37 6.239l2.041 2.444 3.939-3.996 1.035 1.409z" />
                    </svg>
                    {details !== "" && typeof details !== "undefined" && (
                        <div className="dropdown-content p-2 pl-4 mb-2 shadow-md bg-secondary rounded-box w-[400px] whitespace-pre-line font-normal">
                            {ReturnDetails(metric, true).details}
                        </div>
                    )}
                </div>
        )
    }

    
    return (
        <>
            {critical === true ? (
                // Red X
                    <div className="dropdown dropdown-left dropdown-hover dropdown-end text-left">
                        <svg className="fill-red-500" xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 6.88 6.88">
                            <path d="M3.44.537A2.902 2.902 0 1 0 6.342 3.44 2.907 2.907 0 0 0 3.44.537Zm0 5.16A2.257 2.257 0 1 1 5.697 3.44 2.26 2.26 0 0 1 3.44 5.697Zm1.087-2.89-.633.633.633.633a.322.322 0 0 1-.457.457l-.632-.633-.633.633a.322.322 0 0 1-.457-.457l.633-.633-.633-.633a.322.322 0 0 1 .457-.457l.633.633.633-.633a.322.322 0 0 1 .457.457Z" />
                        </svg>
                        <label className="modal cursor-pointer text-center">
                            <label className="modal-box relative" htmlFor="">
                                <h3 className="text-lg font-bold">Details and Information</h3>
                                <p className="py-4 whitespace-normal overflow-clip w-fill text-left">{details}</p>
                            </label>
                        </label>
                        {details !== "" && typeof details !== "undefined" && (
                            <div className="dropdown-content p-2 pl-4 mb-2 shadow-md bg-secondary rounded-box w-[400px] whitespace-pre-line font-normal">
                                {ReturnDetails(metric, true).details}
                            </div>
                        )}
                    </div>
            ) : (
                // Blue i
                    <div className="dropdown dropdown-left dropdown-hover dropdown-end text-left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" version="1" viewBox="0 0 1.26 1.26">
                            <path fill="#2196F3" d="M1.181.63a.552.552 0 0 1-.552.552.552.552 0 0 1-.55-.552.552.552 0 0 1 1.103 0z" />
                            <path fill="#fff" d="M.578.578h.105v.289H.578V.578zM.697.434A.067.067 0 0 1 .63.498.067.067 0 0 1 .565.434a.067.067 0 0 1 .132 0z" />
                        </svg>
                        {details !== "" && typeof details !== "undefined" && (
                            <div className="dropdown-content p-2 pl-4 mb-2 shadow-md bg-secondary rounded-box w-[400px] whitespace-pre-line font-normal">
                                {ReturnDetails(metric, true).details}
                            </div>
                        )}
                    </div>
            )
            }
        </>
    );
}