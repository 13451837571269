import { tenantBaseInfo } from "../../../../common/TenanInput/TenantInfoProvider";
import { _regexFilters } from "./pinRegexFilters";
import { _dynamicTags } from "./constants";
import { PinAction } from "./types";

function _domainsFromTenant(tenant: tenantBaseInfo): { api: string; app: string } {
  const app = tenant.cluster === "canary" ? "na.chilipiper.com" : tenant.environment === "staging" ? "chilipiper.io" : "chilipiper.com"; // hehe this looks bad but it's functional and I'm lazy
  const api = tenant.environment === "staging" ? "chilipiper.io" : "chilipiper.com";
  return { api, app };
}

function _insertTenantDynamicTags(str: string, tenantOptions: tenantBaseInfo): string {
  return str
    .replaceAll(_dynamicTags.tenantID, tenantOptions.id)
    .replaceAll(_dynamicTags.tenantApiDomain, _domainsFromTenant(tenantOptions).api)
    .replaceAll(_dynamicTags.tenantAppDomain, _domainsFromTenant(tenantOptions).app)
    .replaceAll(_dynamicTags.tenantEnvironment, tenantOptions.environment);
}

export function FastAddPinCheck(search: string): boolean {
  for (const filter of Object.values(_regexFilters)) {
    if (`${search}`.match(filter.regex)) return true;
  }
  return false;
}

// CompilePinOptions takes a string and returns a PinAction[] array
export function CompilePinActions(searchString: string, tenantOptions: tenantBaseInfo, singleResult: boolean = false): PinAction[] {
  // Strip HTML from string
  const searchStringNoHtml = _stripHtmlFromString(searchString);
  if (!searchStringNoHtml) {
    return [];
  }

  const sections: Record<string, PinAction[]> = {};
  const matchCounter: Record<string, number> = {};
  // Loop through all regex filters, record sections
  for (const filter in _regexFilters) {
    const filterSettings = _regexFilters[filter];
    const match = searchStringNoHtml.match(filterSettings.regex) || [];
    if (match.length < 1) continue;

    for (const m of match) {
      if (sections[m.replaceAll(" ", "-")]) continue; // This match was already processed and the results should be equal
      const sects: PinAction[] = [];
      // Build sections from section options
      for (const opts of filterSettings.options) {
        // Check match condition
        if (opts.condition && !opts.condition(m, tenantOptions)) continue;
        // Check limit
        if (typeof opts.limit !== "undefined" && matchCounter[opts.tag] && matchCounter[opts.tag] >= opts.limit) continue;

        let finalURL: string = "";
        if (opts.enabled) {
          let cleanValue = m;
          if (opts.cleanup) {
            cleanValue = opts.cleanup(m, tenantOptions);
          }
          finalURL = _insertTenantDynamicTags(opts.rawURL, tenantOptions);
          finalURL = finalURL.replaceAll(_dynamicTags.value, cleanValue);
        }
        let s: PinAction = { tag: opts.tag, text: opts.label, url: finalURL, enabled: opts.enabled, type: filter, value: m };
        sects.push(s);
        if (matchCounter[opts.tag]) matchCounter[opts.tag]++;
        else matchCounter[opts.tag] = 1;
      }
      if (sects.length < 1) continue;
      sections[m] = sects;
    }
    if (singleResult) break;
  }

  // Jank but I need to get an array of PinAction[]
  var actions: PinAction[] = [];
  for (const section in sections) {
    actions.push(...sections[section]);
  }
  return actions;
}

function _stripHtmlFromString(html: string): string {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}
