import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import { BookingSourceFromContext } from "./bookingSourceFromContext";
import { allRowPresets } from "./collectionPreviews/global";
import { FastAddPinCheck } from "./pinPresets/pinActionsConstructor";
import { MakePinFunctionFromSearchWithKey } from "./pinPresets/GetPinAction";
import type { RowPreset } from "../presets/collectionPreviews/types";

export type PresetCard = {
  title: string;
  rows: PresetRow[];
};

export type PresetRow = {
  label: string;
  value: string;
  pinAction?: () => void;
};
const globalRowPresets: RowPreset[] = [
  {
    key: "_id.$oid",
    label: "Document ID",
  },
];
const distroRowPresets: RowPreset[] = [
  {
    key: "groupId.$oid",
    label: "Distro Log ID",
  },
  {
    key: "_id.$oid",
    label: "Document ID",
  },
];

export const GetCollectionPreviewCards = (collectionName: string, data: Record<string, any>, tenant: tenantBaseInfo): PresetCard[] => {
  const cards: Record<string, PresetCard> = {};

  if(collectionName === "distro.logs") {
    allRowPresets.global = { cardName: "", presets: distroRowPresets }
  }else{
    allRowPresets.global = { cardName: "", presets: globalRowPresets }
  }

  // Fill info cards
  for (const key in allRowPresets) {
    if (!key.includes(collectionName) && key !== "global") continue;
    for (const row of allRowPresets[key].presets) {
      if (data[row.key] || typeof data[row.key] == "boolean") {
        if (!cards[allRowPresets[key].cardName]) {
          cards[allRowPresets[key].cardName] = {
            title: allRowPresets[key].cardName,
            rows: [],
          };
        }

        const rowData: PresetRow = { label: row.label, value: fixRowValueType(data[row.key]) };
        if (FastAddPinCheck(rowData.value)) {
          const action = MakePinFunctionFromSearchWithKey(row.key, rowData.value, tenant);
          if (action) rowData.pinAction = action;
        }

        cards[allRowPresets[key].cardName].rows.push(rowData);
      }
    }
  }

  // Source
  if (data["bookRequest.source"] && cards["Meeting Details"]) {
    if (data["bookRequest.source"] || typeof data["bookRequest.multipleSelection"] !== "undefined") {
    const source = BookingSourceFromContext(`${data["bookRequest.source"] ?? ""}`, `${data["bookRequest.bookingUrl"] ?? ""}`, `${data["bookRequest.multipleSelection"] ?? ""}`, `${data["routerId.$oid"] ?? ""}`, `${data["bookRequest.queueId.$oid"] ?? ""}`);
      cards["Meeting Details"].rows.push({ label: "Source", value: source });
    }
  }

  // Return
  return Object.values(cards);
};

const timeFormat = new Intl.DateTimeFormat("en-US", { dateStyle: "full", timeStyle: "long" });
const fixRowValueType = (value: string): string => {
  const now = new Date();
  if (typeof value === "undefined") return "";
  if (typeof value === "boolean") return value ? "True" : "False";
  if (`${value}`.length === 13 && !isNaN(Number(value))) {
    // Timestamp -> string
    const date = new Date(Number(value));
    if (date && date.toString() !== "Invalid Date" && date.getFullYear() > 2014 && date.getFullYear() < now.getFullYear() + 1) value = timeFormat.format(date);
  } else if (isNaN(Number(value)) && !isNaN(Date.parse(value))) {
    // Datetime -> timestamp -> string
    const date = new Date(value);
    if (date && date.toString() !== "Invalid Date" && date.getFullYear() > 2015 && date.getFullYear() < now.getFullYear() + 1) value = timeFormat.format(date);
  }
  return value;
};
