import { useState, PropsWithChildren } from "react";

export default function CollapsableContainer({ title, defaultExpanded, children, contentClass, buttonClass }: PropsWithChildren<{ title: any; defaultExpanded?: boolean; contentClass?: string; buttonClass?: string }>) {
  const [expanded, setExpanded] = useState(defaultExpanded || false);

  return (
    <div className={`rounded-box collapse-arrow w-full shadow-md ${buttonClass} overflow-hidden`}>
      <div className="relative select-none flex justify-between flex-row text-xl">
        <div className={`py-4 px-6 font-medium w-full h-full transition duration-500 ease-in-out rounded-box flex flex-row justify-between`} onClick={() => setExpanded(!expanded)}>
          <div>{title}</div>
          <div>{!expanded ? "▽" : "△"}</div>
        </div>
      </div>
      {expanded && (
        <div className={`h-full m-auto p-2 ${contentClass}`} style={{ maxWidth: "fit-content", minWidth: "100%" }}>
          {children}
        </div>
      )}
    </div>
  );
}
