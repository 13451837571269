import Card from "../common/Card";
import StatusIcon from "../common/StatusIcon";
import { StatusIconCheck, StatusIconCross } from "../common/StatusIconPresets";
import TenantInput from "../common/TenanInput/TenantInput";
import { useTenantInfo } from "../common/TenanInput/TenantInfoProvider";
import EndpointBuilder from "../PageComponents/QueryEndpoint/EndpointBuilder";
import QueryResult from "../PageComponents/QueryEndpoint/QueryResult";
import { useState, useEffect } from "react";
import { postEndpointQuery } from "../endpoints/postEndpointQuery";
import { useToast } from "../common/Notifications/ToastProvider";
// pins
import ImpersonationContainer from "../PageComponents/Query/ImpersonationContainer";
import PinsContainer from "../PageComponents/Query/Pins/PinsContainer";

function QueryEndpoint() {
  const { addToast } = useToast(); // Adds a notification to the toast queue

  const { getTenant } = useTenantInfo();
  const [queryResult, setQueryResult] = useState<Record<string, any>>({});
  const postQuery = async (value: string): Promise<Record<string, any>> => {
    const res = await postEndpointQuery(getTenant().id, getTenant().environment, value);
    if (!res.ok) {
      addToast({
        title: "Error",
        message: res.error.message,
        context: res.error.context,
        type: "error",
      });
      return {};
    }
    return res.body;
  };

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (getTenant().id) {
      setReady(true);
    }
  }, [getTenant]);

  return (
    <div className="flex gap-4">
      <div className="flex-grow flex flex-col gap-4" style={{ width: "900px" }}>
        {/* Tenant Selector */}
        <Card title="Select a tenant" titleClass="font-bold text-base-content text-opacity-50">
          {getTenant().id ? <StatusIcon color={StatusIconCheck.color} icon={StatusIconCheck.icon} /> : <StatusIcon color={StatusIconCross.color} icon={StatusIconCross.icon} />}
          <TenantInput />
        </Card>

        {ready && (
          <>
            <Card>
            <EndpointBuilder postQuery={postQuery} setResult={setQueryResult} />
            </Card>

            <Card title="Result" titleClass="font-bold text-base-content text-opacity-50">
              <QueryResult json={queryResult} />
            </Card>
          </>
        )}
      </div>
      <div style={{ width: "400px" }} className="relative flex flex-col gap-4 overflow-visible">
        <div className="sticky top-4 flex flex-col gap-4 pb-4 overflow-visible">
          {getTenant().id && <ImpersonationContainer tenant={getTenant()} />}
          <PinsContainer />
        </div>
      </div>
    </div>
  );
}

export default QueryEndpoint;
