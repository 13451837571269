import { GetCollectionPreviewCards, PresetCard, PresetRow } from "../presets/collectionPreviewConstructor";
import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import CollapsableContainer from "../../../common/CollapsableContainer";
import Button from "../../../common/Button";
import {detailPresets} from "../presets/detailPresets";

export default function CollectionPreviewCard({ collection, json, tenant }: { collection: any; json: Record<string, any>; tenant: tenantBaseInfo }) {
  const cards: PresetCard[] = GetCollectionPreviewCards(collection, json, tenant);
  if (cards.length === 0) return null;
  return (
    <div className="flex flex-col gap-2 my-2">
      {cards.map((card: PresetCard, index: number) => {
        return (
          <CollapsableContainer key={`${index}-collection-preview`} title={card.title} defaultExpanded={true} contentClass="bg-base-300" buttonClass="bg-base-300 hover:bg-base-300">
            <div className="overflow-x-auto rounded-box bg-base-200">
              <table className="table table-zebra w-full">
                <tbody>
                  {card.rows.map((row: PresetRow, i: number) => {
                    // get list of error messages for distro
                    let errorDetail = Object.values(detailPresets).find((col) => col.error === row.value)
                    return (
                      <tr key={`row-${row.value}-${i}`}>
                        <th>
                          <div className="">{row.label}</div>
                        </th>
                        <td className="flex flex-row align-middle justify-between gap-2">
                          <div className="break-all whitespace-normal select-all">{row.value}<br />{errorDetail?.value}</div>
                          {row.pinAction && (
                            <Button type="secondary" className="btn-sm" onClick={row.pinAction}>
                              Pin
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CollapsableContainer>
        );
      })}
    </div>
  );
}
