import React from 'react'

export default function LoadingSplash() {
    return (
        <div className="w-full h-full flex justify-center flex-col gap-4">
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-primary m-auto mb-0" />
            <div className="m-auto mt-0">Loading</div>
        </div>
    )
}
