import type { RowPresetsDict } from './types';

export const marketingFormsPresets: RowPresetsDict = {
    // Marketing Form
    cardName: 'Marketing Details',
    presets: [
        {
            key: 'type',
            label: 'Prospect Type'
        },
        {
            key: 'created.$date',
            label: 'Created Date'
        },
        {
            key: 'error',
            label: 'Error'
        }
    ]
};