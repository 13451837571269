import { equalsOperator, greaterThanOperator, existsOperator, lessThanOperator, notEqualsOperator, nullOperator, QueryField, regexOperator } from "../types";

const fixKeys = (key: string, value: any) => {
    // Type conversions
    if (Number(value)) value = Number(value);
    if (value?.toLowerCase && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false'))
        value = value.toLowerCase() === 'true';
    // Object keys
    let newKey = key;
    if (key.includes('$')) {
        const keysArray = key.split('.').reverse();
        const newKeysArray = [];
        for (const k of keysArray) {
            if (k.includes('$')) {
                value = { [k]: value };
            } else {
                newKeysArray.push(k);
            }
        }
        newKey = newKeysArray.reverse().join('.');
    }
    return [newKey, value];
};

export const buildQueryFromFields = (
    queryFields: QueryField[],
    queryOperator: string
): string => {
    if (queryFields.length === 0) return '{}';
    const op = queryOperator === 'AND' ? '$and' : '$or';
    const query: { [x: string]: any[] } = { [op]: [] };
    for (const q of queryFields) {
        if (!q.name || (!q.value && (q.operator !== nullOperator && q.operator !== existsOperator))) continue;
        const [key, value] = fixKeys(q.name, q.value);
        // trim strings for equals operation
        const trimmedValue: any = typeof value === 'string' ? value.trim() : value;
        if (q.operator.value === equalsOperator.value) {
            query[op].push({
                [key]: trimmedValue
            });
        } else if (q.operator.value === notEqualsOperator.value) {
            query[op].push({
                [key]: { $ne: value }
            });
        } else if (q.operator.value === regexOperator.value) {
            query[op].push({
                [key]: { $regex: value, $options: 'i' }
            });
        } else if (q.operator.value === greaterThanOperator.value) {
            query[op].push({
                [key]: { $gt: value }
            });
        } else if (q.operator.value === lessThanOperator.value) {
            query[op].push({
                [key]: { $lt: value }
            });
        } else if (q.operator.value === existsOperator.value) {
            query[op].push({
                [key]: { $exists: true }
            });
        } else if (q.operator.value === nullOperator.value) {
            query[op].push({
                [key]: null
            });
        }
        
    }
    return JSON.stringify(query[op].length > 0 ? query : {});
};