import { useEffect, useState } from "react";
import Button from "../../common/Button";
import { useToast } from "../../common/Notifications/ToastProvider";
import { tenantBaseInfo } from "../../common/TenanInput/TenantInfoProvider";
import { GetTenantAdminUser } from "../../endpoints/getTenantAdmin";
import { GetSFFields } from "../../endpoints/getSFFields";
import { ApiResponse } from "../../endpoints/apiResponse";

const PLANHAT_BASE_URL = "https://app-us2.planhat.com/profile";

export default function HPimpersonationContainer({ tenant }: { tenant: tenantBaseInfo }) {
  const { addToast } = useToast();
  const [buttonLoading, setButtonLoading] = useState(false);

  async function getPhUrl(tenant: string) {
    const sfRes: ApiResponse = await GetSFFields(tenant);
    return sfRes.body[0].Planhat_Company_ID__c
  }

  const [adminEmail, setAdminEmail] = useState("");
  useEffect(() => {
    if (!tenant.id) return;
    setButtonLoading(true);
    GetTenantAdminUser(tenant.id, tenant.environment).then((res) => {
      if (res.ok && res.body) {
        setAdminEmail(res.body);
      } else {
        setAdminEmail("");
        addToast({
          title: "Error",
          message: "Could not get admin user for tenant",
          context: res.error.context,
          type: "error",
        });
      }
      setButtonLoading(false);
    });
  }, [tenant.id, tenant.environment]);

  const redirectToAccounts = () => {
    const url = `https://x.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/accounts/${tenant.id}`;
    window.open(url, "_blank");
  };
  const impersonateRedirect = () => {
    const url = `https://api${tenant.cluster === "canary" ? ".na" : ""}.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/impersonate?tenant=${tenant.id}&email=${adminEmail}`.replace("+", "%2B");
    window.open(url, "_blank");
  };
  const productRedirect = () => {
    const url = `https://x.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/accounts/${tenant.id}/products`.replace("+", "%2B");
    window.open(url, "_blank");
  };

  const redirectToPlanhat = async () => {
    await getPhUrl(tenant.id).then((response) => {
      const url = `${PLANHAT_BASE_URL}/${response}`
      window.open(url, "_blank");
    });
  }

  return (
    <div>
      <div className="flex flex-row gap-2 w-full align-middle pt-1">
        <Button type="primary" className={`${buttonLoading ? "loading" : ""} btn-sm`} disabled={!tenant.id || !adminEmail || buttonLoading} onClick={productRedirect}>
          Product Activation
        </Button>
        <Button type="secondary" className={`${buttonLoading ? "loading" : ""} btn-sm`} disabled={!tenant.id || !adminEmail || buttonLoading} onClick={impersonateRedirect}>
          Impersonate Admin
        </Button>
        <Button type="secondary" className="border-transparent btn-sm" onClick={redirectToAccounts} disabled={!tenant.id}>
          Tenant Account
        </Button>
      </div>
    </div>
  );
}
