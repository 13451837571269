import { useState, ChangeEvent, useEffect } from "react";
import { parse, ParseResult } from "papaparse";
import Button from "../common/Button";
import LoadingSplash from '../common/LoadingSplash';
import { GetSFFieldsByAccountName } from "../endpoints/getSFFields";

const allowedExtensions = ["csv"];
type InfoCompany = {
    Name: string;
    Account_Status__c: string;
    Company_Segment__c: string;
    Customer__c: string;
    Customer: string;
    Company:string;
    CP_Calendar_Type__c: string;
    CRM__c: string;
    Current_Scheduling_Tool__c: string;
    Inbound_Chat__c: string;
    Lead_Routing_software__c: string;
    Marketing_Automation__c: string;
    Form_Enrichment__c: string;
}


export default function DinnerPlans() {

    // correct file extension is not used
    const [error, setError] = useState<string>('');
    const [loaded, setLoaded] = useState(true);

    // It will store the file uploaded by the user
    const [file, setFile] = useState<File | undefined>();

    const [companyInfo, setCompanyInfo] = useState<InfoCompany[]>([]);

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setError('');
        setCompanyInfo([]);

        if (event.target.files && event.target.files.length > 0) {
            const inputFile = event.target.files[0];

            const fileExtension = inputFile.type.split('/')[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError('Please input a csv file');
                return;
            }

            setFile(inputFile);
        }
    };

    const handleParse = async () => {
        if (!file) {
            setError("Please select a file");
            return;
        }
        setLoaded(false)
        setError('');
        setCompanyInfo([]);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const csvData: string = event.target?.result as string;
            const parsedData: ParseResult<string[]> = parse(csvData);

            const header: string[] = parsedData.data[0];
            if (header && header.length < 2) {
                setError("CSV should have at least 2 rows");
                return;
            }

            const companyIndex = header.findIndex((item) =>
                item.toLowerCase().includes("company")
            );
            const firstIndex = header.findIndex((item) =>
                item.toLowerCase().includes("first")
            );
            const lastIndex = header.findIndex((item) =>
                item.toLowerCase().includes("last")
            );
            const roleIndex = header.findIndex((item) =>
                ["role", "title", "job"].some((word) =>
                    item.toLowerCase().includes(word)
                )
            );
            if (companyIndex === -1) {
                setError("Could not find the column with the word 'company'");
                return;
            }

            const nameValues: string[] = parsedData.data.slice(1).map((row) => row[firstIndex] + " " + row[lastIndex] + " " + row[roleIndex]);
            const companyValues: string[] = parsedData.data.slice(1).map((row) => row[companyIndex]);
            const companyInfoPromises = companyValues.map((value, index) => {
                const name = nameValues[index];
                return getAccountInfo(value, name);
            });
            await Promise.all(companyInfoPromises);
        };
        reader.readAsText(file);
    };

    async function getAccountInfo(company: string, name: string) {
        if (company) {
            const sfAccount = await GetSFFieldsByAccountName(company);
            if (sfAccount.body[0]) {
                const result:InfoCompany = { Customer: name, Company: company, ...sfAccount.body[0] };
                setCompanyInfo(prevState => [...prevState, result]);
            }else{
                const result:InfoCompany = { Customer: name, Name: company, Company: company, Account_Status__c: "Not Found", Company_Segment__c: "", Customer__c: "false", CP_Calendar_Type__c: "", CRM__c: "", Current_Scheduling_Tool__c: "", Inbound_Chat__c: "", Lead_Routing_software__c: "", Marketing_Automation__c: "", Form_Enrichment__c: "" };
                setCompanyInfo(prevState => [...prevState, result]);
            }
        }
    }
    function getClassName(item: InfoCompany) {
        if (item.Customer__c === "false" && (item.Account_Status__c === "Opportunity Open" || item.Account_Status__c === "Sales Working" || item.Company_Segment__c === "ENT")) {
            return "highlighted";
        }
        return "";
    }

    useEffect(()=>{
        if(companyInfo.length > 0){
            setLoaded(true);
        }
    },[companyInfo.length])

    return (
        <div>

            <input
                className="file-input file-input-bordered file-input-primary file-input-sm leading-none rounded"
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
            />
            <div>
                <Button className="mt-5 btn-sm bg-transparent hover:bg-primary text-primary font-semibold hover:text-white border border-primary hover:border-transparent rounded" onClick={handleParse}>Parse</Button>
            </div>
            {!loaded && <LoadingSplash />}
            {error && <div className="text-red-500">{error}</div>}
            {companyInfo.length > 0 && !error && (
                <div style={{ marginTop: "3rem" }}>
                    <table className="w-full">
                        <thead>
                            <tr className="text-left">
                                <th>Name</th>
                                <th>Individual</th>
                                <th>Customer?</th>
                                <th>Account Status</th>
                                <th>Company Segment</th>
                                <th>CRM</th>
                                <th>Calendar</th>
                                <th>Scheduling Tool</th>
                                <th>Lead Routing App</th>

                            </tr>
                        </thead>
                        <tbody>
                            {companyInfo.map(item => (
                                <tr key={item.Name + Math.random()} className={getClassName(item)}>
                                    <td>{item.Name}</td>
                                    <td>{item.Customer}</td>
                                    <td>{item.Customer__c}</td>
                                    <td>{item.Account_Status__c}</td>
                                    <td>{item.Company_Segment__c}</td>
                                    <td>{item.CRM__c}</td>
                                    <td>{item.CP_Calendar_Type__c}</td>
                                    <td>{item.Current_Scheduling_Tool__c}</td>
                                    <td>{item.Lead_Routing_software__c}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>

    );
};