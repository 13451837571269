import type { RowPresetsDict } from './types';

export const distroDetailsPreset: RowPresetsDict = {
    cardName: 'Distribution Details',
    presets: [
        {
            key: 'groupId.$oid',
            label: 'Distro Log ID'
        },
        {
            key: 'createdAt.$date',
            label: 'Trigger Date'
        },
        {
            key: 'trigger.isSuccessful',
            label: 'Trigger Status'
        },
        {
            key: 'crmObject.Email',
            label: 'Prospect Email'
        },
        {
            key: 'crmId',
            label: 'Event CRM ID'
        },
        {
            key: 'status',
            label: 'Distro Result'
        },
        {
            key: 'error',
            label: 'Error message'
        },
        {
            key: 'ruleEvaluation.name',
            label: 'Rule Name'
        },
        {
            key: 'ruleEvaluation.formula',
            label: 'Rule Formula'
        },
        {
            key: 'ruleEvaluation.isSuccessful',
            label: 'Rule Outcome'
        }
       
    ]
};