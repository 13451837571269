import { PinCardInterface, PinCardProps } from "./types";
import { useMessageListener } from "../../../common/useMessageListener";
import { useEffect, useState } from "react";
import DefaultPinCard from "./DefaultPinCard";
import Button from "../../../common/Button";
import PinCard from "./PinCard";
import { AddPinActionName, ClearPinsActionName } from "./constants";

export default function PinsContainer() {
  const [currentId, setCurrentId] = useState(0);
  const getPinID = () => {
    const id = currentId + 1;
    setCurrentId(id);
    return id;
  };

  const [pins, setPins] = useState<PinCardProps[]>([]);
  const [newPin, setNewPinState] = useState<PinCardInterface | null>(null);
  const setSortPins = (pins: PinCardProps[]) => {
    const sortedPins = pins.sort((a, b) => b.timestamp - a.timestamp);
    setPins(sortedPins);
  };

  const clearPins = () => {
    setPins([]);
  };

  const removePin = (id: number) => {
    setPins(pins.filter((p) => p.id !== id));
  };

  useEffect(() => {
    if (newPin) {
      const newPins = [...pins];
      let pin = newPins.find((p) => p.title === newPin.title && p.content === newPin.content);
      if (!pin) {
        const id: number = getPinID();
        pin = {
          ...newPin,
          dismiss: () => {
            removePin(id);
          },
          id,
        };
        newPins.push(pin);
      }

      // Pin already exists, so we just update the timestamp
      pin.timestamp = Date.now();
      setSortPins([...newPins]);
    }
  }, [newPin]);

  useMessageListener(AddPinActionName, (newState: PinCardInterface) => {
    setNewPinState(newState);
  });
  useMessageListener(ClearPinsActionName, clearPins);

  return (
    <div className="flex flex-col gap-4 overflow-visible">
      {pins.length > 0 && (
        <div className="flex justify-end">
          <Button type="secondary" onClick={clearPins}>
            Clear
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-4 overflow-y-auto flex-grow overflow-visible">
        {pins.length > 0 ? (
          pins.map((pin, i) => <PinCard key={`pin-card-${i}`} timestamp={pin.timestamp} id={currentId} dismissable={pin.dismissable} dismiss={() => removePin(pin.id)} title={pin.title} content={pin.content} buttons={pin.buttons} />)
        ) : (
          <DefaultPinCard key={`pin-card--1`} />
        )}
      </div>
    </div>
  );
}
