import { ApiResponse } from "./apiResponse";
import { TENANT_HUBSPOT_QUERY_FMT } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function QueryTenantHubspot(tenantID: string, tenantEnv: string, type: string, value: string, skipCache = false): Promise<ApiResponse> {
  if (!tenantID || !type || !value) {
    return {
      ok: false,
      status: 400,
      error: { message: "Failed to query HubSpot, TenantID, Type or Value was blank", context: "" },
      body: {},
    };
  }
  const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_HUBSPOT_QUERY_FMT.replace("%id", tenantID).replace("%type", type).replace("%value", value)}?skipCache=${skipCache}&env=${tenantEnv}`;
  try {
    const res = await fetch(url, {
      credentials: "include",
      method: "GET",
    });
    return ParseApiResponse(res);
  } catch (error) {
    return {
      ok: false,
      status: 500,
      error: { message: "Failed to query a collection", context: `${error}` },
      body: {},
    };
  }
}
