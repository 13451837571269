import { ApiResponse } from "../../endpoints/apiResponse";
import { ParseApiResponse } from "../../endpoints/parseApiResponse";
import { DefaultThemes, Theme } from "../ThemeSwitch/ThemeContext";

export async function GetUserProfile(): Promise<ApiResponse> {
    if (import.meta.env.NODE_ENV === "development") {
        return fakeUserProfile()
    }

    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}/users/session`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to generate a new session", context: `${error}` },
            body: {},
        }
    }
}

async function fakeUserProfile(): Promise<ApiResponse> {
    return {
        status: 200,
        ok: true,
        error: {
            message: "",
            context: "",
        },
        body: {
            name: "name",
            email: "#fake#email@email.com",
            picture: "",
            themes: [DefaultThemes["dark"], DefaultThemes["light"]] as Theme[]
        }
    }
}

export async function getGoogleProfile(token: string):Promise<ApiResponse>{
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json`;
    try {
        const res = await fetch(url, { 
            method: 'GET',
            headers:{
                Authorization: "Bearer "+token,
                'Content-Type': "application/json",
            }
        });
        return res.json();
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to retrieve Google credentials", context: `${error}` },
            body: {},
        }
    }
}