import SelectorMenu, { SelectorMenuButton } from "../../../common/SelectorMenu";
import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import { ParseActions } from ".././presets/collectionActionsConstructor";

export default function QueryResultActions({ json, tenant, collection }: { json: Record<string, any>; tenant: tenantBaseInfo; collection: string }) {
  const actions = ParseActions(json, tenant, collection);
  const buttons: SelectorMenuButton[] = actions.map((a, i) => {
    return {
      type: "",
      label: a.label,
      value: `${a.label.replaceAll(" ", "")}-${i}`,
      onClick: a.action
        ? a.action
        : () => {
            openNewTab(a.link);
          },
    };
  });

  const openNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  if (buttons.length === 0) return null;
  return (
    <div>
      <SelectorMenu buttons={buttons} selectorName="Actions" />
    </div>
  );
}
