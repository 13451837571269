export default function StatusIcon({ color, icon }: { color: string; icon: string }) {
  const _color = "badge-" + color;
  return (
    <div className={`absolute right-4 top-4 badge ${_color} flex justify-center`}>
      <svg fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={icon}></path>
      </svg>
    </div>
  );
}
