import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Pi() {

  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false)

  function clickHandler({ ctrlKey, shiftKey, metaKey, button }: { ctrlKey: boolean, shiftKey: boolean, metaKey: boolean, button: number }) {
    if ((ctrlKey || metaKey) && shiftKey && button === 0)
      setClicked(true)
  }

  // Add event listeners
  useEffect(() => {
    if (clicked) {
      navigate("/pi2")
}
    }, [clicked]);

return (
  <div className="ghost fixed bottom-0 right-0 m-1 pr-1 text-black text-lg opacity-50 cursor-default" onClick={clickHandler} onMouseUp={() => {
    setClicked(false)
  }}>
    π
  </div>
)
    
}