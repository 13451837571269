import { ApiResponse } from "./apiResponse";
import { SEARCH_TENANTS_ENDPOINT, SEARCH_TENANT_GET } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function SearchTenants(search: string, skipCache = false): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Tenant ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${SEARCH_TENANTS_ENDPOINT}?search=${search}&skipCache=${skipCache}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to search for a tenant", context: `${error}` },
            body: {},
        }
    }
}

export async function SearchTenantsGet(search: string): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Tenant ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${SEARCH_TENANT_GET.replace("%value",search)}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to search for a tenant", context: `${error}` },
            body: {},
        }
    }
}