import { useEffect } from "react";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";

import { ToastProvider } from "./components/common/Notifications/ToastProvider";
import SilentAuthCheck from "./components/common/Auth/SilentAuthCheck";
import ProtectedRoute from "./components/common/Auth/ProtectedRoute";
import { UserStateProvider } from "./components/common/Auth/AuthContext";
import { ThemeStateProvider } from "./components/common/ThemeSwitch/ThemeContext";
import ThemeWrapper from "./components/common/ThemeSwitch/ThemeWrapper";
import PageContainer from "./components/common/PageContainer";
import { TenantInfoProvider } from "./components/common/TenanInput/TenantInfoProvider";
import { Navbar } from "./components/common/Navbar";

//import LandingPage from "./components/pages/Landing";
import LoginPage from "./components/pages/Login";
import QueryPage from "./components/pages/Query";
import QueryParser from "./components/pages/QueryParser";
import NotFound from "./components/pages/NotFound";
import HallPass from "./components/pages/HallPass"
import DinnerPlans from "./components/pages/DinnerPlans"
import RawSFQuery from "./components/pages/RawSFQuery"
import AdminDashboard from "./components/pages/AdminDashboard";
import Pi from "./components/pages/Pi";
import CrmQuery from "./components/pages/CrmQuery";
import QueryEndpoint from "./components/pages/QueryEndpoint"
import CodeGenerator from "./components/pages/CodeGenerator"
import TheNet from "./components/PageComponents/Pi2"
import AdminCheckWrapper from "./components/PageComponents/AdminDashboard/AdminCheckWrapper";
import Hubspot from "./components/pages/Hubspot";

function App() {
  return (
    <ThemeStateProvider>
      <ThemeWrapper>
        <div className="App">
          <UserStateProvider>
            <SilentAuthCheck />
            <BrowserRouter>
              <Navbar />
              <Pi />
              <ToastProvider>
                <RouterComponent />
              </ToastProvider>
            </BrowserRouter>
          </UserStateProvider>
        </div>
      </ThemeWrapper>
    </ThemeStateProvider>
  );
}

function RouterComponent() {
  return (
    <PageContainer>
      <Routes>

        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/" element={
          <ProtectedRoute>
            <TenantInfoProvider>
              <QueryPage />
            </TenantInfoProvider>
          </ProtectedRoute>
        } />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/raw-sf-query"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <RawSFQuery />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hubspot"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <Hubspot />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dinner"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <DinnerPlans />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hallpass"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <HallPass />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/code-generator"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <CodeGenerator />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/parser"
          element={
            <ProtectedRoute>
              <QueryParser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/query/tenant"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <QueryPage />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/query/crm"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <CrmQuery />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/query/endpoint"
          element={
            <ProtectedRoute>
              <TenantInfoProvider>
                <QueryEndpoint />
              </TenantInfoProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/dashboard"
          element={
            <ProtectedRoute>
              <AdminCheckWrapper>
                <AdminDashboard />
              </AdminCheckWrapper>
            </ProtectedRoute>
          }
        />

        {/* Not found */}
        <Route
          path="/not-found"
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pi2"
          element={
            <ProtectedRoute>
              <TheNet />
            </ProtectedRoute>
          }
        />
        {/* Catch all */}
        <Route path="*" element={<RedirectTo404 />} />
      </Routes>
    </PageContainer>
  );
}

function RedirectTo404() {
  const navigate = useNavigate();
  useEffect(() => navigate("/not-found"));
  return null;
}

export default App;
