import { ApiResponse } from "../../../endpoints/apiResponse"
import { ParseApiResponse } from "../../../endpoints/parseApiResponse";

export async function sendHttpRequest(url: string, method: string, body: any, credentials: boolean): Promise<ApiResponse> {
    const headers = new Headers();
    const opts: RequestInit = {};
    opts.method = method;
    opts.headers = headers;
    opts.credentials = credentials ? "include" : "omit";
    if (body.length > 0) {
        opts.body = JSON.stringify(body);
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
    }
    const res = await fetch(url, opts)
    return ParseApiResponse(res)
}