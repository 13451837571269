import Card from "../common/Card";
import StatusIcon from "../common/StatusIcon";
import { StatusIconCheck, StatusIconCross } from "../common/StatusIconPresets";
import TenantInput from "../common/TenanInput/TenantInput";
import { useTenantInfo } from "../common/TenanInput/TenantInfoProvider";
import { useState, useEffect, SetStateAction } from "react";
import { useLocation } from "react-router";
import Button from '../common/Button'
import { PutSalesforiceID, PostSandboxToggle } from "../endpoints/postAccounts"
import { useToast } from "../common/Notifications/ToastProvider";

// Pages
import { HPConnections, Connections, Products } from "../PageComponents/HallPass/HPConnections";
//import HallBasic from "../PageComponents/HallPass/HallBasic";
import HallConnections from "../PageComponents/HallPass/HallConnections";
import HPImpersonationContainer from "../PageComponents/HallPass/HPImpersonationContainer";


import OpenExternal from "../PageComponents/HallPass/OpenExternal";


export default function HallPass() {
    const version = "v 1.72"
    const { addToast } = useToast();
    const { getTenant } = useTenantInfo();
    const tenant = getTenant().id
    const [connectionsInit, products]: (Connections | Products)[] = HPConnections();
    const connections: Connections = connectionsInit as Connections

    const [sandbox, setSandbox] = useState(connections.sandbox);
    const sandboxColor: string = sandbox ? 'bg-primary' : 'bg-current';
    const [skipCache, setSkipCache] = useState(false);
    const [ready, setReady] = useState(false);
    const [sfId, setSfId] = useState(connections.salesforceId as string)
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [danger, setDanger] = useState(query.get("danger") || false);
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (tenant) {
            setReady(true);
            setSandbox(connections.sandbox);
            setSfId(connections.salesforceId as string);
            query.set("id", tenant);
            if (query.has("danger")) {
                query.delete("danger");
            }
        }

        window.history.replaceState({}, "", `${location.pathname}?${query.toString()}`);
        // window.history.replaceState({}, "", `${location.pathname}/${tenant}`);
        return () => setSkipCache(false);
    }, [connections.sandbox, connections.salesforceId, location.search, location.pathname, tenant]);

    //set sections in an array
    const sectionData: string[] = ["Connections"]
    const [sectionsOpen, setSectionsOpen] = useState<{ [key: string]: boolean }>({ "section-0": true, "section-1": true, "section-2": true });

    const toggleSection = (id: string) => {
        setSectionsOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    //render component based on section
    const renderSwitch = (param: string) => {
        switch (param) {
            // case 'Basic Information':
            //     return <HallBasic connections={connections} />;
            case 'Connections':
                return <HallConnections connections={connections} />;
            // case 'Queues':
            //     return <HallQueues />;
            // case 'Routers':
            //     return <HallRouters />;
            default:
                return null;
        }
    };
    //  let i: number = 0 // each card needs a unique key
    const changeInput = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSfId(e.target.value)
    }

    function captureSandbox(value: boolean) {
        setSandbox(value)
        PostSandboxToggle(tenant, getTenant().environment, value)
        addToast({
            title: "Sandbox",
            message: "Set Sandbox to " + value,
            type: "success",
        });
    }

    return (
        <div className="flex gap-4">
            <div className="flex-grow flex flex-col gap-4">
                {/* Tenant Selector */}
                <Card title="Select a tenant" titleClass="font-bold text-base-content text-opacity-50">
                    {getTenant().id ? <StatusIcon color={StatusIconCheck.color} icon={StatusIconCheck.icon} /> : <StatusIcon color={StatusIconCross.color} icon={StatusIconCross.icon} />}
                    <TenantInput skipCache={skipCache} setSkipCache={setSkipCache} />
                    <div className="form-control flex flex-row gap-2 justify-end align-middle">
                        {getTenant().id && <HPImpersonationContainer tenant={getTenant()} />}
                        <label className="cursor-pointer label flex gap-2">
                            <span className="label-text">Skip Cache</span>
                            <input type="checkbox" className="toggle" checked={skipCache} onChange={() => setSkipCache(!skipCache)} />
                        </label>
                        <span className="text-sm text-right pt-2 italic">{version}</span>
                    </div>
                </Card>
                {
                    ready && (
                        <>
                            <Card title={connections.name} titleClass="font-semibold text-xl text-base-content pl-7">
                                <div className="w-full py-1 px-2.5 pb-6">
                                    <span className="flex">
                                        {connections.logo !== "" && (
                                            <div className="w-32 h-32 bg-contain bg-center bg-no-repeat shadow-md rounded-lg bg-base-200" style={{ backgroundImage: `url("${connections.logo}")` }}></div>
                                        )}

                                        <div className="flex-col px-4 text-sm">
                                            <div><span className="font-bold">Tenant ID:</span> {tenant}</div>
                                            <div><span className="font-bold">Subdomain:</span> {connections.subDomain}</div>
                                            <div><span className="font-bold">AM Owner:</span> {connections.AM_Text__c}</div>
                                            <div><span className="font-bold">CSM Owner:</span> {connections.CS_Text_Field__c}</div>
                                            <div><span className="font-bold">Customer since:</span> {connections.Original_Transaction_Start_Date__c}</div>
                                            <div><span className="font-bold">Renewal date:</span> {connections.Renewal_Date__c}</div>
                                            <div className="w-[432px]">
                                                {typeof connections.domains == "object" ? (
                                                    <span><span className="font-bold">Domain list:</span> {connections.domains.map((e, index) => (<span key={index + "spanlist"}><a className="text-info" href={`https://backoffice-api.chilipiper.${getTenant().environment === "staging" ? "io" : "com"}/api/v1/admin/customer-success/tenants-by-domain?domain=${e}`} key={index + "links"} target="_blank" rel="noreferrer">{e}</a>{(index < connections.domains.length - 1 && (", "))}</span>))}</span>
                                                ) : (
                                                    <span><span className="font-bold">Domain list:</span> <a className="underline text-info" href={`https://backoffice-api.chilipiper.${getTenant().environment === "staging" ? "io" : "com"}/api/v1/admin/customer-success/tenants-by-domain?domain=${connections.domains}`} key={0 + "domains"} target="_blank" rel="noreferrer">{connections.domains}</a></span>
                                                )}
                                                {

                                                }
                                            </div>
                                        </div>
                                        <div className="pt-9 pl-4 w-96">Salesforce ID:
                                            <form>
                                                <input id="sfId" className=" input-sm h-[28px] input-bordered bg-neutral text-neutral-content border-0 placeholder-neutral-content placeholder-opacity-50 mr-2 shadow-md p-1 w-48" type="text" onChange={changeInput} value={sfId} />
                                                <Button className="btn btn-xs shadow-md" onClick={() => {
                                                    PutSalesforiceID(tenant, sfId, getTenant().environment)
                                                    addToast({
                                                        title: "Salesforce ID",
                                                        message: "Set SF ID to " + sfId,
                                                        type: "success",
                                                    });
                                                    connections.salesforceId = sfId

                                                }}>Save ID</Button>
                                                {sfId !== "" && (
                                                    <OpenExternal url={`https://chilipiper.lightning.force.com/lightning/r/Account/${sfId}/view`} tooltip="Browse to Salesforce Account" />
                                                )}
                                            </form>
                                        </div>
                                    </span>
                                </div>
                            </Card>

                            {/* Cards                                     */}
                            <div className="w-full columns-1 gap-0">
                                {sectionData.map((item, index) => {
                                    const sectionId = `section-${index}`;
                                    const isOpen = sectionsOpen[sectionId] ?? true;
                                    return (
                                        <div className="break-inside-avoid mb-2 items-start flex" key={sectionId}>
                                            <Card title={item} titleClass="font-bold text-base-content text-opacity-50" className="m-2">
                                                <div onClick={() => toggleSection(sectionId)} className="cursor-pointer">{isOpen ? "▽ Show" : "△ Hide"}</div>
                                                {isOpen && renderSwitch(item)}
                                            </Card>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <span>If you're looking for License Count and Tenant Usage, check XFire.</span>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}