import { useEffect, useState } from "react";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';
import Button from "../Button";
import { useToast } from "../Notifications/ToastProvider";
import { useUserState } from "./AuthContext";
import { CheckSession } from "./backendCheckSession";
import { NewSession } from "./backendNewSession";
import { setSessionValue } from "./setSessionValue";
import {getGoogleProfile} from "./backendUserProfile"
import { CHILI_VISION_BASE_URI } from "../constants";

const clientId = import.meta.env.REACT_APP_GOOGLE_CLIENT_ID || "";

export default function LoginButton() {
  const { addToast } = useToast();
  const { state, setState } = useUserState();
  const [buttonText, setButtonText] = useState("Login with Google");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  useEffect(() => {
    if (!clientId) {
      addToast({
        title: "Google Login Error",
        message: "Missing Google Client ID",
        type: "error",
      });
      setButtonEnabled(false);
      setButtonText("Missing Google Client ID");
    }
  }, []);

  const beforeLoginRedirect = `${sessionStorage.getItem("beforeLoginRedirect") || "/"}`;
  
  const signIn = useGoogleLogin({
    onSuccess: tokenResponse => {
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        'email',
        'profile',
        'openid',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      );
      if (hasAccess) {
        CheckSession().then((sessionExists) => {
          if (sessionExists) {
            getGoogleProfile(tokenResponse.access_token).then((res)=>{
              setState({
                isAuthenticated: true,
                name:res.name,
                email:res.email,
                picture:res.picture,
              })
            })
            setButtonText("You are logged in");
            setButtonEnabled(false);
            setSessionValue(tokenResponse.access_token);
            window.location.href = beforeLoginRedirect;
            sessionStorage.removeItem("beforeLoginRedirect");
            return null;
          } else {
            NewSession(tokenResponse.access_token).then((data) => {
              if (data.ok) {
                // Login and verification successful
                getGoogleProfile(tokenResponse.access_token).then((res)=>{
                  setState({
                    isAuthenticated: true,
                    name:res.name,
                    email:res.email,
                    picture:res.picture,
                  })
                })
                setButtonText("You are logged in");
                setButtonEnabled(false);
                window.location.href = beforeLoginRedirect;
                sessionStorage.removeItem("beforeLoginRedirect");
                return null;
              } else {
                setState({ ...state, isAuthenticated: false });
                addToast({
                  title: "Login Error",
                  message: "Unauthorized",
                  type: "error",
                });
                setButtonText("Try again");
              }
            });
          }
        });
      } else {
        setState({ ...state, isAuthenticated: false });
        addToast({
          title: "Login Failed",
          message: "Please try again",
          context: "missing accessToken",
          type: "error",
        });
        setButtonText("Try again");
      }
    },
    onError: (error:any) => {
      console.error(error);
      addToast({
        title: "Login Failed",
        message: "Please try again",
        context: error.details || "Something went wrong while logging in with Google",
        type: "error",
      });
      setButtonText("Try again");
    }
  });

  return (
    <Button type="primary" onClick={() => signIn()} disabled={!buttonEnabled}>
      {" "}
      {buttonText}{" "}
    </Button>
  );
}
