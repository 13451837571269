import { useEffect, useState } from "react";
import Button from "./Button";

export default function SelectorMenu({
  buttons,
  selectorName,
  defaultSelectionValue,
  selectedValue,
  buttonClass,
  selectedClass,
}: {
  buttons: SelectorMenuButton[];
  selectorName?: string;
  defaultSelectionValue?: any;
  selectedValue?: any;
  buttonClass?: string;
  selectedClass?: string;
}) {
  const [selected, setSelected] = useState(selectedValue || defaultSelectionValue || undefined);
  const clickHandler = (btn: SelectorMenuButton) => {
    setSelected(btn.value);
    if (btn.onClick) {
      btn.onClick(btn.value);
    }
  };

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue, setSelected]);

  return (
    <div className="w-full flex justify-center">
      <div className="items-stretch shadow-md bg-base-200 horizontal rounded-box flex gap-2 px-2">
        {selectorName && <div className="h-full -ml-2 px-4 bg-base-300 bg-opacity-25 rounded-r-none text-base-content text-opacity-75 text-sm font-bold uppercase flex items-center select-none z-10">{selectorName}</div>}
        <div className="flex flex-row flex-wrap gap-2 bg-base-200 z-20 py-2  justify-items-stretch">
          {buttons.map((b, i) => (
            <div key={`${b.type}-${i}`} className="flex grow">
              <Button
                type="secondary"
                className={`btn-sm w-full h-full border-opacity-0 hover:text-secondary-content ${selected === b.value ? selectedClass || "text-base-content bg-base-300" : buttonClass || "text-base-content bg-base-100"}`}
                onClick={() => clickHandler(b)}
              >
                {b.label}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export interface SelectorMenuButton {
  type: string;
  label: string;
  value: any;
  onClick?: (value: any) => void;
  disabled?: boolean;
}
