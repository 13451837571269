// Regex Filters
export const regexFilters = {
  objectId: /\b[a-fA-F0-9]{24}\b/,
  email: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
  salesforceId: /\b[a-z0-9]\w{4}0\w{12}|[a-z0-9]\w{4}0\w{9}\b/,
  customField: /\b[\S]{1,32}__c\b/,
  nonMatchFilter: /#no-match#/,
};

export const collectionPreviewRegexFilters: Record<string, RegExp> = {
  "MongoDB ID": /[a-fA-F0-9]{24}/,
  Email: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
  "Salesforce ID": /[a-z0-9]\w{4}0\w{12}|[a-z0-9]\w{4}0\w{9}/,
  "Custom field": /[\S]{1,32}__c/,
};
