export function jsonToCsv<T>(jsonData: any[], fieldsSelected: string[]): string {
  // Step 1: Extract headers
  const headersSet: Set<string> = new Set();

  jsonData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (fieldsSelected.some(field => key.startsWith(field))) {
        headersSet.add(key);
      }
    });
  });

  const headers = Array.from(headersSet);

  // Step 2: Build CSV rows
  const csvRows: string[] = [];

  // Add headers as the first row
  csvRows.push(headers.join(','));

  // Iterate through the JSON data
  jsonData.forEach(item => {
    const row = headers.map(header => {
      const value = getValueByFlattenedKey(item, header);
      if (value === null) {
        return '';
      }
      return value;
    });
    csvRows.push(row.join(','));
  });

  // Step 3: Combine all rows into a single CSV string
  return csvRows.join('\n');
}

function getValueByFlattenedKey(flattenedItem: any, key: string): any {
  if (key in flattenedItem) {
    const value = flattenedItem[key];
    return JSON.stringify(value).replace(/["']/g, '');
  }
  return null; // Return empty string if key is not present
}