import { equalsOperator, getFieldID, QueryField, validOperators } from "../types";

export function parseQueryFields(compiledQuery: string): QueryField[] {
  return rawQueryToFields(compiledQuery);
}

// This is old code ripped from Svelte, muight break in future, only used in parseQueryFields
const rawQueryToFields = (query: string): QueryField[] => {
  const queryFields: QueryField[] = [];
  // Parse field names from query
  let queryDict: Record<string, any> = {};
  try {
    queryDict = JSON.parse(query?.replace(/'/g, '"')) || {};
  } catch (error) {
    console.error("Failed to parse query:", error);
    return [];
  }
  if (Object.keys(queryDict).includes("$and")) {
    let fixedDict = {};
    for (const e of queryDict["$and"]) {
      fixedDict = { ...fixedDict, ...e };
    }
    queryDict = fixedDict;
  } else if (Object.keys(queryDict).includes("$or")) {
    let fixedDict = {};
    for (const e of queryDict["$or"]) {
      fixedDict = { ...fixedDict, ...e };
    }
    queryDict = fixedDict;
  }

  const flat = flattenJSON(queryDict);
  for (const key in flat) {
    if (!key || !flat[key]) continue;
    if (key.includes("$options")) continue;

    let fixedKey = key;
    const operator = validOperators.find((op) => key.includes(op.value)) || equalsOperator;
    fixedKey = key.replace(`.${operator.value}`, "");

    queryFields.push({
      value: `${flat[key]}`,
      id: getFieldID(),
      name: fixedKey,
      operator,
    });
  }
  return queryFields;
};

// This is old code ripped from Svelte, muight break in future, only used in parseQueryFields
const flattenJSON = (data: Record<string, any>): Record<string, unknown> => {
  const result: Record<string, any> = {};
  function recurse(cur: Record<string, any>, prop: string) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      if (cur.length === 1) result[prop] = cur[0];
      else {
        result[prop] = cur.map((c) => {
          return flattenJSON(c) || [];
        });
      }
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};
