import LoadingSplash from "../LoadingSplash";
import { useUserState } from "./AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function ProtectedRoute({ children }: { children: JSX.Element }) {
    const { state } = useUserState();
    const location = useLocation();
    const navigate = useNavigate();

    // Route protection is disabled in development mode
    if (state.isLoadingAuth === true) {
        return <LoadingSplash />;
    } else if (!state.isAuthenticated === true && import.meta.env.REACT_APP_DEV_MODE !== "true") {
        sessionStorage.setItem("beforeLoginRedirect", location.pathname+location.search);
        navigate("/login")
        return null
    } else {
        sessionStorage.removeItem("beforeLoginRedirect");
        return children;
    }
}