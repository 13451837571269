import { PinAction } from "./types";

export function regexToLabel(action: PinAction): string {
    switch (action.type) {
        case "email":
            return "Email Address";
        case "objectID":
            return "Chili Piper ID";
        case "salesforceID":
            if (action.value.startsWith("00Q")) return "Salesforce Lead"
            if (action.value.startsWith("005")) return "Salesforce User"
            if (action.value.startsWith("00U")) return "Salesforce Event"
            if (action.value.startsWith("003")) return "Salesforce Contact"
            if (action.value.startsWith("001")) return "Salesforce Account"
            return "Salesforce ID";
        case "hubSpotID":
            return "HubSpot ID";
        case "salesforceCustomField":
            return "Custom Field";
        case "jira":
            return "JIRA Ticket";
        case "names":
            return "User Name";
        case "bookingLinks":
            return "Chili Piper Link";
        default:
            return action.text;
    }
}

// 'email', 'objectID', 'salesforceID', 'hubSpotID', 'salesforceCustomField', 'jira', 'names', 'bookingLinks'