import { useUserState } from "../common/Auth/AuthContext";
import LoginButton from "../common/Auth/LoginButton";

export default function LoginPage() {
    const { state } = useUserState();

    return (
        <div className="w-full">
            {state.isAuthenticated
                ? (
                    <div className="flex flex-col justify-center gap-4">
                        <div className="text-center text-2xl" >
                            You just like this page this much, huh?
                        </div>
                        <div className="m-auto card pointer-events-none select-none w-64">
                            <img src="/mascot.jpg" alt="Chili Mascot" className="pointer-events-none select-none" />
                        </div>
                        <div className="text-center text-2xl" >
                            You are logged in already, feel free to use the app.
                        </div>
                        <div className="flex justify-center">
                            <LoginButton />
                        </div>
                    </div>
                )
                : (
                    <div className="flex flex-col justify-center gap-4">
                        <div className="text-center text-2xl" >
                            This app is intended to be used by Chili Piper employees only.
                        </div>
                        <div className="text-center text-xl" >
                            If you are not a Chili Piper employee, you will not be able to use this app.
                        </div>
                        <div className="flex justify-center">
                            <LoginButton />
                        </div>
                    </div>
                )
            }
        </div>
    )
}
