export default function RawSFQuery() {

    return (

        <div className="flex gap-4">
            <div className="alert alert-warning">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                <div>
                    <h3 className="font-bold">Deprecation Notice</h3>
                    <div className="text-xs">This version of Raw SF Query was already migrated to XFire (<a href='https://xfire.chilipiper.com/raw-sql-query' target="_blank">https://xfire.chilipiper.com/raw-sql-query</a>).</div>
                    <div className="text-xs">If you don't have access to XFire, feel free to reach us at the <a href='https://chili-piper.slack.com/archives/C06V05Q0VB3' target="_blank">#xfire-users</a> channel.</div>
                </div>
            </div>
        </div>
    );

};