export const getJsonValue = (): void => {
  const extensionIds = ['kamlmkjcchfkadlnkdekdhmdoonnonlm', 'mbnpicielnjndbeelploampjnlllcpfg'];

  if (typeof chrome.runtime !== "undefined") {
    extensionIds.forEach((extensionId) => {
      try {
        chrome.runtime.sendMessage(extensionId, { json: true }, (response) => {
          if (response) {
            for (const [key, value] of Object.entries(response)) {
              window.localStorage.removeItem(key);
              window.localStorage.setItem(key, JSON.stringify(value));
            }
          }
          if (chrome.runtime.lastError) {
            window.localStorage.removeItem('defaultToJsonView');
          }
        });
      } catch (e) {
        window.localStorage.removeItem('defaultToJsonView');
      }
    });
  }
};