export const collectionPresets = [
    { name: 'booker.reports', label: 'Reports' },
    { name: 'booker.routes', label: 'Routes' },
    { name: 'booker.routes.debug', label: 'Routes Debug' },
    { name: 'users', label: 'Users' },
    { name: 'distro.logs', label: 'Distro Logs'}
];

// collections added here will not have fields overwritten by Query page
// If they have special options but don't appear above, add them here
export const ignoreCollectionPresets: {name: string} = { name: 'example.collection.name'}
