import { ApiResponse } from "./apiResponse";
import { ACCOUNT_CHANGE_SF_ID, ACCOUNT_SYNC_LICENSES, ACCOUNT_SET_SANDBOX } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

// requires query param salesforceId = account ID from SF
export async function PutSalesforiceID(tenantID: string, salesforceId:string="", env: string, skipCache = false): Promise<ApiResponse> {
  if (!tenantID || tenantID.length === 0) {
    return {
      ok: false,
      status: 400,
      error: { message: "Failed to get collections, TenantID was blank", context: "" },
      body: {},
    };
  }
  const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${ACCOUNT_CHANGE_SF_ID.replace("%id", tenantID)}?skipCache=${skipCache}&env=${env}&salesforceId=${salesforceId}`;
  try {
    const res: Response = await fetch(url, { credentials: "include", method: "PUT", headers: { "Content-Type": "application/json" } });
    return ParseApiResponse(res);
  } catch (error) {
    return {
      ok: false,
      status: 500,
      error: { message: "Failed to get collections", context: `${error}` },
      body: {},
    };
  }
}

// requires query param sandbox (true/false)
export async function PostSandboxToggle(tenantID: string, env: string, sandbox:boolean=false, skipCache = false): Promise<ApiResponse> {
    if (!tenantID || tenantID.length === 0) {
      return {
        ok: false,
        status: 400,
        error: { message: "Failed to get collections, TenantID was blank", context: "" },
        body: {},
      };
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${ACCOUNT_SET_SANDBOX.replace("%id", tenantID)}?skipCache=${skipCache}&env=${env}&sandbox=${sandbox}`;
    try {
      const res: Response = await fetch(url, { credentials: "include", body: JSON.stringify({  }), method: "POST", headers: { "Content-Type": "application/json" } });
      return ParseApiResponse(res);
    } catch (error) {
      return {
        ok: false,
        status: 500,
        error: { message: "Failed to get collections", context: `${error}` },
        body: {},
      };
    }
  }

// DEPRECATED - use Maxio now
export async function PostSyncLicenses(tenantID: string, env: string, skipCache = false): Promise<ApiResponse> {
    if (!tenantID || tenantID.length === 0) {
      return {
        ok: false,
        status: 400,
        error: { message: "Failed to get collections, TenantID was blank", context: "" },
        body: {},
      };
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${ACCOUNT_SYNC_LICENSES.replace("%id", tenantID)}?skipCache=${skipCache}&env=${env}`;
    try {
      const res: Response = await fetch(url, { credentials: "include", body: JSON.stringify({ }), method: "POST", headers: { "Content-Type": "application/json" } });
      return ParseApiResponse(res);
    } catch (error) {
      return {
        ok: false,
        status: 500,
        error: { message: "Failed to get collections", context: `${error}` },
        body: {},
      };
    }
  }