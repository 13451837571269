import { isArray } from "lodash";
import { useState } from "react";
import { FastAddPinCheck } from "../presets/pinPresets/pinActionsConstructor";
import CollapsableContainer from "../../../common/CollapsableContainer";
import PinAvailableIcon from "../Pins/PinAvailableIcon";
import Button from "../../../common/Button";

export default function CollapsableTableView({ json }: { json: Record<string, any> }) {
  return (
    <div className="flex flex-col gap-2">
      {Object.keys(json).map((k, i) => (
        <CollapsableContainer key={`table-collapse-${k}-${i}`} title={k} buttonClass="bg-base-300" contentClass="bg-base-300">
          <TableView value={json[k]} />
        </CollapsableContainer>
      ))}
    </div>
  );
}

export function TableView({ value }: { value: any }) {
  const getType = (i: any) => {
    if (i === null) return "null";
    return typeof i;
  };
  const format = (i: any) => {
    switch (getType(i)) {
      case "function":
        return "f () {...}";
      case "symbol":
        return i.toString();
      case "boolean":
        return i.toString();
      case "null":
        return "null";
      case "undefined":
        return "null";
      default:
        return i;
    }
  };

  if (getType(value) !== "object") {
    return (
      <div className="p-4 flex flex-row justify-between bg-base-100 rounded-box">
        {value === null && <span className="badge badge-error">null</span>}
        {value !== null && (
          <>
            <span className={`break-all whitespace-normal`}>{`${format(value)}`}</span>
            {FastAddPinCheck(value) && <PinAvailableIcon />}
          </>
        )}
      </div>
    );
  }
  return (
    <table className="table table-zebra bg-base-200 rounded-box flex m-auto" style={{ maxWidth: "fit-content", minWidth: "100%" }}>
      <tbody className="w-full">
        {Object.keys(value).map((k, i) => (
          <TableRowAuto key={`table-row-${k}-${i}`} _key={k} value={value[k]} format={format} />
        ))}
      </tbody>
    </table>
  );
}

export function TableRowAuto({ _key, value, format }: { _key: string; value: any; format: (i: any) => string }) {
  return <tr>{typeof value === "object" && value != null ? <TableRowLarge _key={_key} value={value} format={format} /> : <TableRowSmall _key={_key} value={value} format={format} />}</tr>;
}

export function TableRowLarge({ _key, value, format }: { _key: string; value: any; format: (i: any) => string }) {
  const canCollapse = (isArray(value) && value.length > 1) || !isArray(value);
  const [collapsed, setCollapsed] = useState(true);

  if (!canCollapse) value = value?.[0];
  if (!value || typeof value !== "object") return <TableRowSmall _key={_key} value={null} format={format} />;
  return (
    <td colSpan={2}>
      {/* Key and Collapse button */}
      <div className="flex flex-row">
        <div>{_key}</div>
        {canCollapse && (
          <div className="flex flex-grow justify-end">
            <Button type={"secondary"} className="btn-sm" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? "▽" : "△"}
            </Button>
          </div>
        )}
      </div>
      {/* Value table */}
      {!collapsed && (
        <div className="bordered border-2 mt-4 p-2 rounded-lg border-neutral">
          <TableView value={value} />
        </div>
      )}
    </td>
  );
}
export function TableRowSmall({ _key, value, format }: { _key: string; value: any; format: (i: any) => string }) {
  return (
    <>
      {/* Key */}
      <td className="">
        <div className={`flex flex-row justify-between`}>
          <div className="break-words whitespace-pre col-span-3">{_key}</div>
        </div>
      </td>

      {/* Value */}
      {
        <td className="flex flex-col">
          <div className="flex flex-row align-middle justify-between gap-2">
            {value !== null ? (
              <div>
                <span className={`break-all whitespace-normal`}>{`${format(value)}`}</span>
              </div>
            ) : (
              <div>
                <span className="badge badge-error">null</span>
              </div>
            )}
            {FastAddPinCheck(value) && <PinAvailableIcon key={`icon-${value}-${Date.now()}`} />}
          </div>
        </td>
      }
    </>
  );
}
