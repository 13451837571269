import { ChangeEvent, useState, useEffect } from "react";
import Button from "../../common/Button";
import { useLocation } from "react-router";
import { useUserState } from "../../common/Auth/AuthContext";
import { useTenantInfo } from "../../common/TenanInput/TenantInfoProvider";
function QueryBuilderHubspot({ postQuery, setResult }: { postQuery: (type: string, value: string) => Promise<Record<string, any>>; setResult: (result: Record<string, any>) => void }) {
  const { state } = useUserState();
  const { getTenant } = useTenantInfo();

  const [type, setType] = useState("contact");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const setTypeFromEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.options[e.target.selectedIndex].value);
  };

  const runQuery = async () => {
    setLoading(true);

    const result = await postQuery(type, value);
    setResult(result);

    setLoading(false);
  };

  // Parse query params
  const location = useLocation();
  useEffect(() => {
    if (state.isLoadingAuth || !state.isAuthenticated) return;
    const query = new URLSearchParams(location.search);
    if (query.get("value")) {
      setValue(query.get("value") as string);
    }
    if (query.get("type")) {
      setType(query.get("type") as string);
    }
    if (query.get("value") && query.get("type")) {
      postQuery(query.get("type") as string, query.get("value") as string).then((result) => {
        setResult(result);
      });
    }
  }, [state.isLoadingAuth, state.isAuthenticated]);

  // Update query params
  useEffect(() => {
    if (state.isLoadingAuth || !state.isAuthenticated) return;
    const query = new URLSearchParams(location.search);
    query.set("id", getTenant().id);
    query.set("value", value);
    query.set("type", type);
    window.history.replaceState({}, "", `${location.pathname}?${query.toString()}`);
  }, [value, type, state.isLoadingAuth, state.isAuthenticated]);

  return (
    <div className="flex flex-col gap-2">
      <div className="form-control flex flex-row">
        <select className="bg-neutral text-neutral-content select rounded-lg rounded-r-none" value={type} onChange={setTypeFromEvent}>
          <option value="contact">Contact</option>
          <option value="company">Company</option>
        </select>

        <select className="bg-neutral text-neutral-content select rounded-none">
          <option value="id">ID</option>
        </select>

        <input
          type="text"
          placeholder="101010101"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="flex-grow input input-secondary input-bordered rounded-none bg-neutral text-neutral-content placeholder-neutral-content placeholder-opacity-50"
        />

        <Button className="rounded-l-none btn text-lg" type="secondary" onClick={() => setValue("")}>
          &#215;
        </Button>
      </div>

      <div className="flex justify-center">
        <Button onClick={runQuery} className={`shadow-md ${loading ? "loading" : ""}`} disabled={loading}>
          Run Query
        </Button>
      </div>
    </div>
  );
}

export default QueryBuilderHubspot;
