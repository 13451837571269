export type operator = { name: string, value: string }

export type QueryField = {
    id: number;
    name?: string,
    value?: string,
    operator: operator,
}
export const equalsOperator = { name: "=", value: "$eq" };
export const nullOperator = { name: "null", value: "null" };
export const lessThanOperator = { name: "<", value: "$lt" };
export const notEqualsOperator = { name: "!=", value: "$ne" };
export const greaterThanOperator = { name: ">", value: "$gt" };
export const existsOperator = {name: "exists", value:"$exists"}
export const regexOperator = { name: "regex", value: "$regex" };
export const validOperators = [equalsOperator, existsOperator, notEqualsOperator, lessThanOperator, greaterThanOperator, regexOperator, nullOperator];


export const getOperator = (operator: string): { name: string, value: string } => {
    const op = validOperators.find(o => o.value === operator);
    return op || validOperators[0];
}

export const NewField = (): QueryField => {
    return {
        operator: { name: "=", value: "$eq" },
        id: getFieldID()
    }
}

let id = 0
export function getFieldID(): number {
    return id++
}