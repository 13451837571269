import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import LoadingSplash from "../../common/LoadingSplash";
import { adminCheck } from "../../endpoints/adminCheck";

export default function AdminCheckWrapper({ children }: { children: JSX.Element }) {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        adminCheck().then((ok) => {
            if (ok !== true) {
                navigate("/not-found");
                return null
            }
            setIsLoading(false);
        });
    })

    return isLoading ? <LoadingSplash /> : children
}
