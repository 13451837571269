import { PropsWithChildren } from "react";

export default function PageContainer(props: PropsWithChildren<{ className?: string }>) {
    return (
        <div className={`rounded-xl ${props.className} pt-4 bg-neutral h-full min-h-full mb-0 pb-0`}>
            <div className={`mx-8 pb-8 ${props.className} min-h-[91vh]`}>
                {props.children}
            </div>
        </div>
    )
}
