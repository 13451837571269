import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import CollectionPreviewCard from "./CollectionPreviewCard";
import QueryResultActions from "./QueryResultActions";
import JsonView from "./JsonView";
import CollapsableTableView from "./TableView";
import TableSearch from "./TableSearch";

export default function SingleQueryResult({
  jsonFlat,
  jsonSplit,
  expanded,
  setExpanded,
  totalDocs,
  index,
  viewAsJSON,
  tenant,
  collection,
}: {
  jsonFlat: Record<string, any>;
  jsonSplit: Record<string, any>;
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  totalDocs: number;
  index: number;
  viewAsJSON: boolean;
  tenant: tenantBaseInfo;
  collection: string;
}) {
  return (
    <div className="rounded-box collapse-arrow w-full shadow-md bg-base-200">
      <div className="relative select-none flex justify-between flex-row text-xl cursor-pointer">
        <span className="p-4 font-medium rounded-box w-full h-full bg-base-200 hover:bg-base-300 hover:border-opacity-0 transition duration-500 ease-in-out" onClick={() => setExpanded(!expanded)}>
          Document {index + 1} of {totalDocs}
        </span>
      </div>
      {expanded && (
        <div className="h-full m-auto p-2 flex flex-col" style={{ maxWidth: "fit-content", minWidth: "100%" }}>
          <QueryResultActions json={jsonFlat} tenant={tenant} collection={collection} />
          {!viewAsJSON && (
            <div>
              <CollectionPreviewCard json={jsonFlat} collection={collection} tenant={tenant} />
              {/* <TableSearch json={json} tenant={tenant} /> */}
            </div>
          )}
          {viewAsJSON ? (
            <JsonView json={jsonSplit} />
          ) : (
            <div>
              <div className="mb-2">
                <TableSearch json={jsonFlat} />
              </div>
              <CollapsableTableView json={jsonSplit} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
