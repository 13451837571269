import { useEffect, useState } from "react";
import SelectorMenu, { SelectorMenuButton } from "../SelectorMenu";
import { tenantBaseInfo } from "./TenantInfoProvider";

export default function TenantSelectMenu({ tenants, selectedTenant, setSelectedTenant }: { tenants: tenantBaseInfo[]; selectedTenant: tenantBaseInfo; setSelectedTenant: (tenant: tenantBaseInfo) => void }) {
  const overload = tenants.length > 4 ? tenants.length - 4 : 0;
  if (tenants.length > 4) {
    tenants = tenants.slice(0, 4);
  }

  const [selectedValue, setSelectedValue] = useState(selectedTenant.id + selectedTenant.environment + selectedTenant.cluster);
  useEffect(() => {
    setSelectedValue(selectedTenant.id + selectedTenant.environment + selectedTenant.cluster);
  }, [selectedTenant, setSelectedValue]);

  const buttons: SelectorMenuButton[] = tenants.map((tenant) => ({
    type: "base-200",
    value: tenant.id + tenant.environment + tenant.cluster,
    label: tenant.id + " @" + tenant.environment,
    onClick: () => setSelectedTenant(tenant),
  }));

  return (
    <div className="py-2 relative flex justify-center">
      <SelectorMenu buttons={buttons} selectedValue={selectedValue} />
      {overload > 0 && (
        <div className="relative h-full flex items-center">
          <div className="absolute -right-12 font-bold text-lg bg-neutral-focus rounded-full h-10 w-10 flex items-center justify-center">
            <div data-tip="Try refining your search" className="tooltip z-20">
              +{overload}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
