/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Card from "../common/Card";
import TenantInput from "../common/TenanInput/TenantInput";
import StatusIcon from "../common/StatusIcon";
import { StatusIconCheck, StatusIconCross } from "../common/StatusIconPresets";
import CompiledQueryPreview from "../PageComponents/Query/QueryBuilder/CompiledQueryPreview";
import { useTenantInfo, GetCachedTenant } from "../common/TenanInput/TenantInfoProvider";
import { useToast } from "../common/Notifications/ToastProvider";

import PinsContainer from "../PageComponents/Query/Pins/PinsContainer";
import { NewField, QueryField } from "../PageComponents/Query/types";
import RunQueryButton from "../PageComponents/Query/QueryBuilder/RunQueryButton";
import AdvancedCllectionSelector from "../PageComponents/Query/CollectionSelector/AdvancedCollectionSelector";
import { GetTenantCollectionFields } from "../endpoints/getCollectionFields";
import { collectionFieldMap, presetField } from "../PageComponents/Query/presets/collectionFieldsPresets";
import { buildQueryFromFields } from "../PageComponents/Query/utils/buildQueryFromFields";
import { getJsonValue } from "../PageComponents/Query/utils/getJsonValue";
import { QueryCollection } from "../endpoints/queryCollection";
import ImpersonationContainer from "../PageComponents/Query/ImpersonationContainer";
import QueryResultView from "../PageComponents/Query/QueryResults/QueryResultView";
import { useLocation } from "react-router";
import { useUserState } from "../common/Auth/AuthContext";
import { parseQueryFields } from "../PageComponents/Query/utils/parseQueryFields";
import QueryBuilder from "../PageComponents/Query/QueryBuilder/QueryBuilder";
import QuickQuerySelector from "../PageComponents/Query/QueryBuilder/QuickQuerySelector";
import QuickCollectionSelector from "../PageComponents/Query/CollectionSelector/QuickCollectionSelector";
import { FastAddPinCheck } from "../PageComponents/Query/presets/pinPresets/pinActionsConstructor";
import { AddPinFromSearch } from "../PageComponents/Query/presets/pinPresets/GetPinAction";
import { useSelectionEventListener } from "../common/useSelectionPinCheckListener";
import { GetTenantCollections } from "../endpoints/getCollections";
import { ignoreCollectionPresets } from "../PageComponents/Query/presets/collectionPresets"

function getJson() {
  if (getJsonValue !== undefined) {
    getJsonValue();
  }
  return Boolean(window?.localStorage.getItem('defaultToJsonView'));
}

export default function QueryPage() {
  const { addToast } = useToast(); // Adds a notification to the toast queue

  // User in all requests to the API
  const [skipCache, setSkipCache] = useState(false);

  // Collection selector
  const [advancedQuery, setAdvancedQuery] = useState(false);
  const toggleAdvancedQuery = (addBlank = true) => {
    if (!advancedQuery && addBlank) addField();
    setAdvancedQuery(!advancedQuery);
  };
  const [advancedCollection, setAdvancedCollection] = useState(false);
  const toggleAdvancedCollection = () => {
    setAdvancedCollection(!advancedCollection);
  };
  const [collection, setCollectionState] = useState("booker.reports");
  const setCollection = (collection: string) => {
    setCollectionFields([]);
    setCollectionState(collection);
  };
  const [collectionFields, setCollectionFields] = useState<string[]>([]);
  const [collectionsList, setCollectionsList] = useState<string[]>([]);

  useEffect(() => {
    if (collectionsList.includes(collection)) {
      const tenant = getTenant();
      if (!tenant.id) return;
      GetTenantCollectionFields(tenant.id, tenant.environment, collection).then((data) => {
        if (!data.ok) {
          return addToast({
            title: "Error",
            message: data.error.message,
            context: data.error.context,
            type: "error",
          });
        }

        // Check if a collection has a preset
        let presetExists = Object.keys(collectionFieldMap).find((col) => col === collection);
        let ignoredCollection = Object.values(ignoreCollectionPresets).find((col) => col === collection)

        setCollectionFields(data.body);
        if (presetExists && !ignoredCollection) setAdvancedCollection(false);
        // This is to reset the view when some query params have been specifid, but not query.
        // initially, collectionsList will be empty and setAdvancedQuery(true) will be called
        if (queryCompiled === "{}" && presetExists) setAdvancedQuery(false);
        else if (!presetExists || ignoredCollection) {
          if (fields.length === 0) addField();
          setAdvancedQuery(true);
        }
      });
    } else {
      setAdvancedCollection(true);
      setAdvancedQuery(true);
    }
  }, [collection, collectionsList]);

  // Fields
  const [fields, setFields] = useState([] as QueryField[]);
  const updateField = (index: number, field: QueryField) => {
    const newFields = [...fields];
    newFields[index] = field;
    setFields(newFields);
  };
  const removeField = (id: number) => {
    const newFields = fields.filter((f, i) => id !== f.id);
    setFields(newFields);
    if (newFields.length === 0) {
      toggleAdvancedQuery();
    }
  };
  const addField = () => {
    setFields([...fields, NewField()]);
  };
  const setFieldFromPreset = (preset: presetField) => {
    setFields([{ ...NewField(), ...preset.fieldValues }]);
  };

  // Query
  const [queryLimit, setQueryLimitState] = useState(20);
  const [queryCount, setQueryCount] = useState(false);
  const [queryCompiled, setQueryCompiled] = useState("{}");
  const [queryResultSplit, setQueryResultSplit] = useState([] as Record<string, any>[]);
  const [queryResultFlat, setQueryResultFlat] = useState([] as Record<string, any>[]);
  const setQueryLimit = (limit: number) => {
    if (limit < 1) limit = 1;
    setQueryLimitState(limit);
  };
  const [queryLoading, setQueryLoading] = useState(false);
  const postQuery = (tenantID: string = getTenant().id, tenantEnv: string = getTenant().environment, coll: string = collection, query: string = queryCompiled, count: boolean = queryCount, limit: number = queryLimit) => {
    if (!tenantID) return
    setQueryLoading(true);
    setQueryResultSplit([]);
    setQueryResultFlat([]);
    const queryData = {
      query: query.replaceAll(`"`, `'`),
      count: count,
      limit: limit,
      order: -1,
    };
    QueryCollection(tenantID, tenantEnv, coll, JSON.stringify(queryData), skipCache).then((data) => {
      if (!data.ok) {
        setQueryLoading(false);
        return addToast({
          title: "Error",
          message: data.error.message,
          context: data.error.context,
          type: "error",
        });
      }
      if (count === true) {
        addToast({
          title: "Count Result",
          message: "Documents found: " + data.body.count,
          type: "info",
        });
      } else {
        setQueryResultSplit(data.body.entriesSplitFlat);
        setQueryResultFlat(data.body.entriesFullFlat);
      }

      setQueryLoading(false);
    });
    setSkipCache(false);
  };

  const [globalOperator, setGlobalOperator] = useState("AND");
  const toggleGlobalOperator = () => {
    setGlobalOperator(globalOperator === "AND" ? "OR" : "AND");
  };
  const buildQuery = () => {
    const query = buildQueryFromFields(fields, globalOperator);
    setQueryCompiled(query);
  };
  useEffect(() => {
    buildQuery();
  },[fields, globalOperator]);

  const [resultModeJSON, setResultModeJSON] = useState(getJson());

  // Result settings
  const skipTableView = ["booker.routes.debug"];


  // Parse query string parameters
  const [initialActionExecuted, setInitialActionExecuted] = useState(false);
  const { state } = useUserState();
  const location = useLocation();
  useEffect(() => {
    if (state.isLoadingAuth || initialActionExecuted || !state.isAuthenticated) return;
    setInitialActionExecuted(true);
    const query = new URLSearchParams(location.search);
    if (query.get("collection")) {
      setCollection(query.get("collection") as string);
    }
    if (query.get("limit")) {
      setQueryLimit(parseInt(query.get("limit") as string));
    }
    if (query.get("count")) {
      setQueryCount(query.get("count") === "true");
    }
    if (query.get("json")) {
      setResultModeJSON(query.get("json") === "true");
    }
    if (query.get("query")) {
      setFields(parseQueryFields(query.get("query")?.replaceAll(`'`, `"`) as string));
    }

    // Run query if all required parameters are present
    const t = (query.get("id") as string) || getTenant().id;
    const e = (query.get("env") as string) || getTenant().environment;
    const q = (query.get("query") as string) || queryCompiled;
    const c = query.get("count") === "true";
    const cl = (query.get("collection") as string) || collection;
    const l = query.get("limit") ? parseInt(query.get("limit") as string) : 20;
    if (t && cl && q) {
      postQuery(t, e, cl, q, c, l);
    }
  }, [state.isLoadingAuth, state.isAuthenticated]);

  const { getTenant } = useTenantInfo();

  // Updating url to reflect changes in tenant and etc
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if ((query.get("id") as string) !== getTenant().id) {
      query.set("id", getTenant().id)
    }
    if (collection) query.set("collection", collection);
    if (queryCompiled && queryCompiled !== "{}") query.set("query", queryCompiled);
    query.set("limit", queryLimit.toString());

    window.history.replaceState({}, "", `${location.pathname}?${query.toString()}`);
  }, [getTenant, collection, queryLimit, queryCount, queryCompiled]);

  // tenant change, fetch collections
  useEffect(() => {
    let isMounted = true;
    GetTenantCollections(getTenant().id, getTenant().environment, skipCache).then((res) => {
      if (isMounted) {
        if (res.ok) {
          setCollectionsList(res.body);
        } else {
          setCollectionsList([]);
        }
      }
    });
    return () => { isMounted = false };

  }, [getTenant])

  // Add pins based on selected text
  useSelectionEventListener((selection: string) => {
    if (!FastAddPinCheck(selection)) return;
    const tenant = GetCachedTenant();
    if (!tenant.id) return;
    AddPinFromSearch(selection, tenant);
  });

  return (
    <div className="flex gap-4">
      <div className="flex-grow flex flex-col gap-4 w-[900px]">
        {/* Tenant Selector */}
        <Card title="Select a tenant" titleClass="font-bold text-base-content text-opacity-50">
          {getTenant().id ? <StatusIcon color={StatusIconCheck.color} icon={StatusIconCheck.icon} /> : <StatusIcon color={StatusIconCross.color} icon={StatusIconCross.icon} />}
          <TenantInput />
        </Card>
        {/* Query builder */}
        {getTenant().id && (
          <Card title="Build a query" titleClass="font-bold text-base-content text-opacity-50">
            {collection && queryCompiled ? <StatusIcon color={StatusIconCheck.color} icon={StatusIconCheck.icon} /> : <StatusIcon color={StatusIconCross.color} icon={StatusIconCross.icon} />}
            <div className="flex flex-col gap-4">
              {/* Collection */}
              {!advancedCollection && <QuickCollectionSelector collection={collection} setCollection={setCollection} toggleAdvanced={toggleAdvancedCollection} />}
              {advancedCollection && <AdvancedCllectionSelector collections={collectionsList} setCollection={setCollection} selectedCollection={collection} toggleAdvanced={toggleAdvancedCollection} />}
              {/* Fields */}
              {collection && !advancedQuery && <QuickQuerySelector toggleAdvancedQuery={toggleAdvancedQuery} setFields={setFieldFromPreset} presetFields={collectionFieldMap[collection]?.fields || []} />}
              {collection && advancedQuery && <QueryBuilder fieldsList={collectionFields} fields={fields} addField={addField} removeField={removeField} updateField={updateField} />}
              {/* Preview and run */}
              {collection && queryCompiled && <CompiledQueryPreview collection={collection} globalOperator={globalOperator} toggleGlobalOperator={toggleGlobalOperator} query={queryCompiled} tenant={getTenant()} />}
              <RunQueryButton
                count={queryCount}
                setCount={setQueryCount}
                queryLoading={queryLoading}
                limit={queryLimit}
                setLimit={setQueryLimit}
                disabled={!collection || !queryCompiled}
                skipCache={skipCache}
                setSkipCache={setSkipCache}
                onClick={() => {
                  postQuery();
                }}
              />
            </div>
          </Card>
        )}
        {/* Query result */}
        {getTenant().id && collection && queryResultFlat.length > 0 && (
          <Card title="View results" titleClass="font-bold text-base-content text-opacity-50">
            <QueryResultView
              resultFlat={queryResultFlat}
              resultSplit={queryResultSplit}
              viewAsJSON={skipTableView.includes(collection) ? true : resultModeJSON}
              tenant={getTenant()}
              collection={collection}
              viewModeToggle={skipTableView.includes(collection) ? undefined : () => setResultModeJSON(!resultModeJSON)}
            />
          </Card>
        )}
      </div>

      <div style={{ width: "400px" }} className="relative flex flex-col gap-4 overflow-visible">
        <div className="sticky top-4 flex flex-col gap-4 pb-4 overflow-visible">
          {getTenant().id && <ImpersonationContainer tenant={getTenant()} />}
          <PinsContainer />
        </div>
      </div>
    </div>
  );
}
