import { ApiResponse } from "./apiResponse";
import { TENANT_SF_FIELDS_FMT, TENANT_SF_FIELDS_TENANTOBJ_FMT, TENANT_SF_FIELDS_ACCOUNT_FMT, TENANT_SF_RAW_QUERY } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function GetSFFields (search: string): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Account ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_SF_FIELDS_FMT.replace("%value",search)}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to find Account in SF", context: `${error}` },
            body: {},
        }
    }
}

export async function GetSFFieldsTenantObj (search: string): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Account ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_SF_FIELDS_TENANTOBJ_FMT.replace("%value",search)}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to find Account in SF", context: `${error}` },
            body: {},
        }
    }
}

export async function GetSFFieldsByAccountName (search: string): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Account ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_SF_FIELDS_ACCOUNT_FMT.replace("%value",search)}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to find Account in SF", context: `${error}` },
            body: {},
        }
    }
}

export async function PostSFQuery (query: string, fields: string, tenantId: string, env:string): Promise<ApiResponse> {
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_SF_RAW_QUERY}?env=${env}`;
    try {
        const res: Response = await fetch(url, { credentials: "include", body: JSON.stringify({"tenantId":tenantId, "query":query, "fields":fields}), method: "POST", headers: { "Content-Type": "application/json" } });
        return ParseApiResponse(res);
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to generate a new session", context: `${error}` },
            body: {},
        }
    }
}