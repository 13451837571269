import { useUserState } from "./Auth/AuthContext"
import ThemeSelector from "./ThemeSwitch/ThemeSelector"

export default function UserSettingsButton({ size }: { size: string }) {
    const { state } = useUserState()
    return (
        <div className="dropdown dropdown-end" style={{ height: size, width: size }}>
            <div tabIndex={0} className="btn p-0 rounded-box overflow-hidden border-transparent" style={{ height: size, width: size }}>
                <img src={state.picture || "/mascot.jpg"} alt="User avatar" />
            </div>
            <div tabIndex={0} className="p-2 menu dropdown-content bg-base-100 rounded-box w-64 flex flex-col gap-2">
                <div className="card bg-base-200 p-2 m-0">
                    <div className="card-title text-center m-0 text-md">Themes</div>
                    <div className="card-body p-2 m-0"><ThemeSelector /></div>
                </div>
            </div>
        </div>
    )
}
