import React from 'react'
import Button from '../common/Button'

const colors = ["/pi/1.png", "/pi/2.png", "/pi/3.png", "/pi/4.png", "/pi/5.png", "/pi/6.png", "/pi/7.png", "/pi/8.png"];
const delay = 165;

function Slideshow() {
    const [index, setIndex] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const timeoutRef = React.useRef(0);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        timeoutRef.current = window.setTimeout(() => {
            if (count < 3) {
                index === 6 ? setIndex(0) :
                    setIndex((prevIndex) =>
                        prevIndex === colors.length - 1 ? 0 : prevIndex + 1
                    )
                if (index === 6)
                    setCount(count + 1)
            } else {
                if (count === 10) {
                    setIndex(6)
                    return null;
                } else {
                    setCount(count + 1);
                    setIndex(() =>
                        index === 7 ? 6 : 7
                    )
                }

            }
        },
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index, count]);
    return (
        <div className="slideshow">
            <div className="slideshowSlider">
                <div className="slide w-[100%] h-[100vh] absolute inset-0 bg-center" key={index} style={{ backgroundImage: `url("${colors[index]}")` }}>
                {count >= 10 && (
                    <Button type="ghost" className="bg-base-100 left-[45%] top-[90vh] absolute" onClick={()=>{window.open("https://www.youtube.com/watch?v=pXPXMxsXT28", "_blank") }}>Enter The Net</Button>
                )}
                </div>
            </div>
        </div>
    );
}

export default function TheNet() {
    return (
        <Slideshow />
    )
}
