export async function CheckSession(): Promise<boolean> {
  const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}/auth/check-session`;
  try {
    const res = await fetch(url, { credentials: "include" });
    if (res.status === 200) {
      window.postMessage({ type: "SOLVER_SESSION", value: await res.text() }, "*");
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
  return false;
}
