export const TENANT_ADMIN_QUERY = "/tenants/%id/admin"; // %id is replaced with the tenant ID
export const SEARCH_TENANTS_ENDPOINT = "/tenants/search";
export const SEARCH_TENANT_GET = "/tenants/accounts/%value"
export const FAST_SEARCH_TENANTS_ENDPOINT = "/tenants/match";
export const TENANT_COLLECTIONS_FMT = "/tenants/%id/collections"; // %id is replaced with the tenant ID
export const TENANT_COLLECTION_QUERY_FMT = "/tenants/%id/collections/%col/query"; // %id is replaced with the tenant ID, %col is replaced with the collection name
export const TENANT_COLLECTION_FIELDS_FMT = "/tenants/%id/collections/%col/fields"; // %id is replaced with the tenant ID, %col is replaced with the collection name
export const TENANT_HUBSPOT_QUERY_FMT = "/tenants/%id/hubspot/%type/%value"; // %id is replaced with the tenant ID, %type with record type and %value with record value
export const TENANT_SF_FIELDS_FMT = "/tenants/salesforce/%value"
export const TENANT_SF_FIELDS_TENANTOBJ_FMT = "/tenants/salesforce/tenant/%value"
export const TENANT_SF_FIELDS_ACCOUNT_FMT = "/tenants/salesforce/company/%value"
export const TENANT_SF_RAW_QUERY = "/tenants/salesforce/raw-query"
export const TENANT_PRODUCT_LIST_FMT = "/tenants/products/%value"
export const ACCOUNT_LIST = "/tenants/list" // full list of accounts
export const ACCOUNT_LIST_FULL = "/tenants/list-accounts" // full list of accounts

export const USER_IS_ADMIN_CHECK = "/users/session/isAdmin";

export const ACCOUNT_CHANGE_SF_ID = "/admin/accounts/%id/change-sf-id"
export const ACCOUNT_SYNC_LICENSES = "/admin/accounts/%id/sync-licences"
export const ACCOUNT_SET_SANDBOX = "/admin/crm-sandbox/%id"

export const QUEUE_LIST = "/tenants/%id/queues"
export const ROUTER_LIST = "/tenants/%id/routers"