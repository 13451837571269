import { useEffect, useState } from "react";
import Button from "../../../common/Button";
import { useKeyPress } from "../../../common/useKeyPress";

export default function RunQueryButton({
  skipCache,
  setSkipCache,
  disabled,
  onClick,
  queryLoading,
  limit,
  setLimit,
  count,
  setCount,
}: {
  skipCache: boolean;
  setSkipCache: (skipCache: boolean) => void;
  disabled: boolean;
  onClick: () => void;
  queryLoading: boolean;
  limit: number;
  setLimit: (limit: number) => void;
  count: boolean;
  setCount: (count: boolean) => void;
}) {
  const enterPressed = useKeyPress("Enter");
  const commandPressed = useKeyPress("Meta");
  const controlPressed = useKeyPress("Control");
  const [timeout, setTimeoutState] = useState(false);
  useEffect(() => {
    if (!disabled && !timeout && enterPressed && (controlPressed || commandPressed)) {
      onClick();
      setTimeoutState(true);
      setTimeout(() => setTimeoutState(false), 5000);
    }
  }, [enterPressed, commandPressed, controlPressed]);

  const [limitInput, setLimitInputState] = useState(`${limit}`);
  const [limitValid, setLimitValidState] = useState(!isNaN(limitInput as unknown as number));
  const setLimitInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitInputState(e.target.value);
    if (!isNaN(e.target.value as unknown as number)) {
      const n: number = parseInt(e.target.value);
      if (n === limit) {
        setLimitValidState(true);
        return;
      }
      if (n < 1) {
        setLimit(20);
        setLimitInputState("20");
        setLimitValidState(true);
        return;
      }
      // anything higher than 6000 is not supported
      if (n > 6000) {
        setLimit(6000);
        setLimitInputState("6000");
        setLimitValidState(true);
        return;
      }
      setLimit(n);
      setLimitValidState(true);
      return;
    }
    setLimitValidState(false);
  };

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col gap-2">
        <div className="flex justify-center">
          <Button type="primary" disabled={disabled || queryLoading} onClick={onClick} className={`shadow-md ${queryLoading ? "loading" : ""}`}>
            Query Data
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <div data-tip="Skipping cache will require more resources from Chili Piper to complete your request" className="tooltip m-auto">
            <div className="form-control flex flex-row gap-2">
              <label className="cursor-pointer label flex gap-2">
                <span className="label-text">Skip Cache</span>
                <input type="checkbox" className="toggle" checked={skipCache} onChange={() => setSkipCache(!skipCache)} disabled={disabled || queryLoading} />
              </label>
            </div>
          </div>

          <div className="form-control flex flex-row gap-2">
            <label className="cursor-pointer label flex gap-2">
              <span className="label-text">Count</span>
              <input type="checkbox" className="toggle" checked={count} onChange={() => setCount(!count)} disabled={disabled || queryLoading} />
            </label>
          </div>

          <div className="form-control flex flex-row gap-2 w-24 rounded-box">
            <label className="cursor-pointer label flex gap-2 p-0">
              <span className="label-text">Limit</span>
              <input
                type="text"
                className={`input w-full h-8 p-1 text-center bordered ${limitValid ? "border-neutral-content border-opacity-20" : "border-red-500 border-opacity-100"}`}
                value={limitInput}
                onChange={setLimitInput}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
