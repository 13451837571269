import { useEffect, useState } from "react";
import CollapsableContainer from "../../../common/CollapsableContainer";
import DatalistInput from "../../../common/DatalistInput";
import TableView from "./TableView";
import { FastAddPinCheck } from "../presets/pinPresets/pinActionsConstructor";
import PinAvailableIcon from "../Pins/PinAvailableIcon";

export default function TableSearch({ json }: { json: Record<string, any> }) {
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(undefined as any);
  useEffect(() => {
    if (Object.keys(json).includes(search)) {
      setValue(json[search]);
    } else {
      setValue(undefined);
    }
  }, [search]);

  return (
    <CollapsableContainer title={searchTitle()} contentClass="bg-base-300" buttonClass="bg-base-300">
      <div className="w-full flex flex-col p-1 rounded-box text-base-content gap-1">
        <div className="flex flex-col gap-2">
          <div className="rounded-box overflow-hidden bg-base-200">
            <DatalistInput className="w-full bg-base-200" id="table-search-datalist" placeholder="_id.$oid" options={Object.keys(json)} value={search} onChange={setSearch}></DatalistInput>
          </div>
        </div>

        {typeof value !== "undefined" && (
          <div className="bg-base-100 rounded-box p-4">
            {value !== null && typeof value === "object" && <TableView json={value} />}
            {typeof value !== "object" && (
              <div className="flex flex-row justify-between">
                <div>{`${value}`}</div>
                {FastAddPinCheck(search) && <PinAvailableIcon />}
              </div>
            )}
            {value === null && <div className="badge badge-error">{"null"}</div>}
          </div>
        )}
      </div>
    </CollapsableContainer>
  );
}

const searchTitle = () => {
  return (
    <div className="flex flex-row">
      <div>Search</div>
      <div data-tip="You can search through all document keys using this feature" className="tooltip tooltip-right flex ml-2">
        <div className="badge badge-info m-auto">
          <span className="absolute top-50 left-50">!</span>
        </div>
      </div>
    </div>
  );
};
