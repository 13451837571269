export default function PinAvailableIcon({ simpleMode }: { simpleMode?: boolean }) {
  const text = simpleMode ? "Click on the value to pin it" : "Highlight some text to search for pins";
  return (
    <div data-tip={text} className="tooltip tooltip-left flex">
      <div className="badge badge-info m-auto">
        <span className="absolute top-50 left-50">!</span>
      </div>
    </div>
  );
}
