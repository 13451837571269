import { addToastMessageAction } from "../../../common/Notifications/ToastProvider";
import { GetTenantDomain, tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";
import { sendWindowMessage } from "../../../common/useMessageListener";
import { sendHttpRequest } from "../utils/sendHttpRequest";
import { actionPresets, ActionType } from "./collectionActionsPresets";
import { regexFilters } from "./regexFilters";

function getTenantEnv(tenant: tenantBaseInfo) {
  const validEnvironments: string[] = ["prod", "canary", "staging"];
  if (validEnvironments.includes(tenant.environment)) {
    return tenant.environment
  }
  return "prod"; // default environment is "prod"
}

export const ParseActions = (document: Record<string, any>, tenant: tenantBaseInfo, collection: string) => {
  const tenantDomain = GetTenantDomain(tenant);
  const env = getTenantEnv(tenant);

  const actions: ActionType[] = [];
  let found = [];

  // Find workspace
  let workspaceId: any;
  const workspaceSearchId: { type: string; value: string } = {} as { type: string; value: string };
  for (const key in document) {
    if (key.includes("workspaceId") && !key.includes("default")) {
      workspaceId = document[key];
    }
    if (key.includes("queueId")) {
      workspaceSearchId.type = "queueId";
      workspaceSearchId.value = document[key];
    }
    if (key.includes("routerId")) {
      workspaceSearchId.type = "routerId";
      workspaceSearchId.value = document[key];
    }
  }
  if (!workspaceId && workspaceSearchId) {
    // Get workspace from router / queue
  }

  for (const key in document) {
    // Special actions -- Queue History (because document key already used)
    if (
      document["_id.$oid"] &&
      key.includes("queue") &&
      actions.filter((a) => a.label === "Queue History").length === 0 &&
      !isNaN(document[key]) &&
      !key.includes("creationDate") &&
      !key.includes("values") &&
      !key.includes("prospect") &&
      !key.includes("template") &&
      !key.includes("router")
    ) {
      let qURL: string = `https://apps.${tenantDomain}/admin-center/meetings/${workspaceId}/queues/history/${document[key]}`
      actions.push({
        regex: regexFilters.nonMatchFilter,
        found: true,
        label: "Queue History",
        link: qURL,
        collectionLocked: true,
        workspaceRequired: true,
        sortWeight: 11.5,
      });
    }
    // Special actions -- Google logs
    if (
      document["_id.$oid"] &&
      actions.filter((a) => a.label === "Google Logs").length === 0 &&
      key.includes("creationDate") &&
      !isNaN(document[key]) &&
      !key.includes("values") &&
      !key.includes("prospect") &&
      !key.includes("template") &&
      !key.includes("router") &&
      !key.includes("queue")
      ) {
        const startTime = new Date(document[key] - 5 * 60000); // 5 minutes before
        const endTime = new Date(startTime.getTime() + 30 * 60000); // 30 minutes after
        const clusterName = env === 'prod' ? 'chilipiper-production-gke-usce1' : `chilipiper-${env}-gke-usce1`;
        const gcpURL = `https://console.cloud.google.com/logs/query;query=%22${document["_id.$oid"]}%22%0Aresource.type%3D%22k8s_container%22%0Aresource.labels.cluster_name%3D%22${clusterName}%22%0Aresource.labels.namespace_name%3D%22${env}%22%0Atimestamp%3C%3D%22${endTime.toISOString()}%22%0Atimestamp%3E%3D%22${startTime.toISOString()}%22#csOptions={"respectIncognito":true,"incognitoMode":"tab","tenant_id":"${tenant.id}","cp_domain":"${tenantDomain}"}`;
        actions.push({
          regex: regexFilters.nonMatchFilter,
          found: true,
          label: "Google Logs",
          link: gcpURL,
          collectionLocked: false,
          workspaceRequired: false,
          sortWeight: 99,
        });
        found.push("creationDate");
      }
    // Special Actions -- Replay SFDC Event
    if (collection === "booker.salesforce.event" && document["_id.$oid"] && !found.includes("replayEventButton")) {
      const requestURL = `https://backoffice-api.${tenantDomain.replace("na.", "")}/api/qa/reprocess-sf-event`;
      const requestPayload = { tenantId: tenant.id, reports: [document["_id.$oid"]] };
      actions.push({
        regex: regexFilters.nonMatchFilter,
        found: true,
        label: "Replay Event",
        link: "",
        collectionLocked: true,
        workspaceRequired: false,
        sortWeight: 99,
        action: async () => {
          const res = await sendHttpRequest(requestURL, "POST", requestPayload, true);
          if (res.ok) {
            sendWindowMessage(addToastMessageAction, { message: "Success", title: "Event Retry", type: "success" });
          } else {
            sendWindowMessage(addToastMessageAction, { message: res.error.message, context: res.error.context, title: "Event Retry Failed", type: "error" });
          }
        },
      });
      found.push("replayEventButton");
    } else
      // Special Actions -- View Distro Delay Collection
      if (collection === "distro.logs" && document["_id.$oid"] && !found.includes("distroDelayButton")) {
        const requestURL = `/query/tenant?id=${tenant.id}&collection=crmstreaming.distro.delay&query={'_id':{'$oid':'${document['groupId.$oid']}'}}&count=false&limit=1&order=-1`;
        actions.push({
          regex: regexFilters.nonMatchFilter,
          found: true,
          label: "CRM Distro Delay",
          link: requestURL,
          collectionLocked: false,
          workspaceRequired: false,
          sortWeight: 99,
        });
        found.push("distroDelayButton");
      }
    // Action presets
    for (const a of actionPresets) {
      if (a.search && key.includes(a.search) && a.search && !found.includes(a.search) && (!a.collectionLocked || collection === a.collection) && (!a.exclude || !key.includes(a.exclude)) && a.regex && `${document[key]}`.match(a.regex)) {
        // Get workspace id
        if (!workspaceId && a.workspaceRequired) continue;
        const replacePlaceholders = (link: string) => {
          const map: { [key: string]: string } = {
            "%tid": tenant.id,
            "%space": workspaceId,
            "%domain": tenantDomain,
            "%v": `${document[key]}`,
            "%coll": collection,
            "%apiDomain": tenantDomain.replace("na.", ""),
            "%query": (a.query || "").replace("%v", document[key]),
            "%env": tenant.environment
          };
        
          return link.replace(/%tid|%space|%domain|%v|%coll|%apiDomain|%query|%env/g, (matched) => {
            return map[matched];
          });
        };
        
        actions.push({
          ...a,
          label: a.label === "Template"? "Meeting Type" : a.label,
          found: true,
          action: async () => {
            const formattedLink = replacePlaceholders(a.link);
        
            if (a.label === "Queue") {
              await navigator.clipboard.writeText(formattedLink);
            }
        
            if (a.action) {
              a.action();
            } else {
              window.open(formattedLink, "_blank");
            }
          }
        });
        
        found.push(a.search);
      }
    }
  }
  if (found.length > 0) {
    actions.sort((i, j) => i.sortWeight - j.sortWeight);
    return actions;
  } else return [];
};

