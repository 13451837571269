import { ApiResponse } from "../../endpoints/apiResponse";
import { ParseApiResponse } from "../../endpoints/parseApiResponse";

export async function NewSession(token: string): Promise<ApiResponse> {
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}/auth/new-session`;
    try {
        const res = await fetch(url, { credentials: "include", body: JSON.stringify({ token }), method: "POST", headers: { "Content-Type": "application/json" } });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to generate a new session", context: `${error}` },
            body: {},
        }
    }
}