import { useEffect, useState } from "react";
import Button from "../../common/Button";
import Card from "../../common/Card";
import { useToast } from "../../common/Notifications/ToastProvider";
import { tenantBaseInfo } from "../../common/TenanInput/TenantInfoProvider";
import { GetTenantAdminUser } from "../../endpoints/getTenantAdmin";
import { CHILI_VISION_BASE_URI } from "../../common/constants";

export default function ImpersonationContainer({ tenant }: { tenant: tenantBaseInfo }) {
  const { addToast } = useToast();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [adminEmail, setAdminEmail] = useState("");
  useEffect(() => {
    if (!tenant.id) return;
    setButtonLoading(true);
    GetTenantAdminUser(tenant.id, tenant.environment).then((res) => {
      if (res.ok && res.body) {
        setAdminEmail(res.body);
      } else {
        setAdminEmail("");
        addToast({
          title: "Error",
          message: "Could not get admin user for tenant",
          context: res.error.context,
          type: "error",
        });
      }
      setButtonLoading(false);
    });
  }, [tenant.id]);

  const redirectToAccounts = () => {
    const url = `${CHILI_VISION_BASE_URI}/hallpass?id=${tenant.id}`
    window.open(url, "_blank");
  };
  const impersonateRedirect = () => {
    const url = `https://api${tenant.cluster === "canary" ? ".na" : ""}.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/impersonate?tenant=${tenant.id}&email=${adminEmail}`.replace("+","%2B");
    window.open(url, "_blank");
  };

  return (
    <div className="">
      <Card>
        <div className="flex flex-col gap-2 w-full">
          <Button type="primary" className={`${buttonLoading ? "loading" : ""}`} disabled={!tenant.id || !adminEmail || buttonLoading} onClick={impersonateRedirect}>
            Impersonate Admin
          </Button>
          <Button type="secondary" className="border-transparent" onClick={redirectToAccounts} disabled={!tenant.id}>
            Hall Pass
          </Button>
        </div>
      </Card>
    </div>
  );
}
