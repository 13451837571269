import type { RowPresetsDict } from './types';

export const reportDetailsPreset: RowPresetsDict = {
    cardName: 'Meeting Details',
    presets: [
        {
            key: 'creationDate.$date',
            label: 'Creation Date'
        },
        {
            key: 'event.start',
            label: 'Start Date'
        },
        {
            key: 'event.status',
            label: 'Status'
        },
        {
            key: 'crmId',
            label: 'Event CRM ID'
        },
        {
            key: 'booker.email',
            label: 'Event Booker'
        },
        {
            key: 'assignee.email',
            label: 'Event Assignee'
        },
        {
            key: 'assignee._id.$oid',
            label: 'Event Assignee ID'
        },
        {
            key: 'routeId.$oid',
            label: 'Route ID'
        },
        {
            key: 'routerId.$oid',
            label: 'Router ID'
        },
        {
            key: 'bookRequest.queueId',
            label: 'Queue ID'
        },
        {
            key: 'bookRequest.template',
            label: 'Template ID'
        },
        {
            key: 'marketingId.$oid',
            label: 'Marketing ID'
        },
        {
            key: 'bookRequest.bookingUrl',
            label: 'Booking URL'
        }
    ]
};