import Datalist from "./Datalist";

export default function DatalistInput({ options, onChange, value, className, placeholder, id }: { options: any[]; onChange: (newValue: any) => void; value: any; className?: string; placeholder?: string; id: string }) {
  return (
    <div className={className}>
      <input
        type="text"
        className={`input bg-neutral text-neutral-content placeholder-neutral-content placeholder-opacity-50 ${className}`}
        placeholder={placeholder}
        list={id}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
      />
      <Datalist id={id} options={options} />
    </div>
  );
}
