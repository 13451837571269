import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../common/Button'

export default function NotFound() {
    return (
        <div className="text-center flex flex-col gap-4 m-12">
            <div className="text-4xl m-auto">
                Not Found
            </div>
            <div className="text-xl m-auto">
                This page does not exist.
            </div>
            <div>
                <Link to="/"><Button type="ghost" className="bg-base-100">Home Page</Button></Link>
            </div>
        </div>
    )
}
