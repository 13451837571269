import { Link, useLocation } from "react-router-dom";
import { useUserState } from "./Auth/AuthContext";
import Button from "./Button";
import UserSettingsButton from "./UserSettingsButton";

export const Navbar = () => {
  const { state } = useUserState();
  const location = useLocation();

  return (
    <div className="p-0">
      <div className="navbar shadow-lg bg-base-100 text-base-content gap-2">
        <div className="flex-1 gap-2">
          <div className="flex-none w-36">
            <Link className="btn rounded-btn btn-primary text-lg font-bold logo flex flex-row" to="/" style={
              {
                backgroundImage: "url(/mascot.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center 60%",
                textShadow: "2px 2px black",
              }}>
              &nbsp;&nbsp;&nbsp;&nbsp;Vision&nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </div>
          {state.isAuthenticated && (
            <div className="flex flex-row gap-2">
              <div className="flex-none">
                <Link to="/query/tenant">
                  <Button type="ghost" className={location.pathname.startsWith("/query/tenant") || location.search.includes("collection") ? "bg-primary text-primary-content" : ""}>
                    Data Query Builder
                  </Button>
                </Link>
              </div>
              {/* <div className="flex-none">
                <Link to="/query/crm">
                  <Button type="ghost" className={location.pathname.startsWith("/query/crm") ? "bg-primary text-primary-content" : ""}>
                    CRM Query Builder
                  </Button>
                </Link>
              </div> */}
              {/* <div className="flex-none">
                <Link to="/query/endpoint">
                  <Button type="ghost" className={location.pathname.startsWith("/query/endpoint") ? "bg-neutral text-neutral-content" : ""}>
                    Route Debug Test
                  </Button>
                </Link>
              </div>      */}
              <div className="flex-none">
                <Link to="/hallpass">
                  <Button type="ghost" className={location.pathname.startsWith("/hallpass") ? "bg-primary text-primary-content" : ""}>
                    Tenant Hall Pass
                  </Button>
                </Link>
              </div>
              <div className="flex-none">
                <Link to="https://xfire.chilipiper.com/raw-sf-query">
                  <Button type="ghost" className={location.pathname.startsWith("/raw-sf-query") ? "bg-primary text-primary-content" : ""}>
                    Raw SF Query 2.0
                  </Button>
                </Link>
              </div>
              <div className="flex-none">
                <Link to="https://xfire.chilipiper.com/snippet-generator">
                  <Button type="ghost" className={location.pathname.startsWith("/code-generator") ? "bg-primary text-primary-content" : ""}>
                    Code Generator
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
        {!state.isAuthenticated && (
          <div className="flex-none">
            <Link to="/login">
              <Button className="btn rounded-btn btn-ghost">Login</Button>
            </Link>
          </div>
        )}
        {state.isAuthenticated && (
          <div className="flex flex-row gap-2">
            <div className="flex-none">
              {!location.pathname.startsWith("/hallpass") ? (
                <a href="https://csops.atlassian.net/servicedesk/customer/portal/2" className="btn btn-primary" rel="noreferrer" target="_blank">
                  CS Ops Portal
                </a>
              ) : (
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScKIdjTb9ifAg9LQsMqb_zRqgqlEmtGp9mvJQJo0T9ohkg9cA/viewform" className="btn btn-primary" rel="noreferrer" target="_blank">
                  HallPass Feedback
                </a>
              )}
            </div>
            <div className="flex flex-none align-middle">
              <UserSettingsButton size="3rem" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
