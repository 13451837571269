import Button from "../../../common/Button";
import { tenantBaseInfo } from "../../../common/TenanInput/TenantInfoProvider";

export default function CompiledQueryPreview({ query, tenant, toggleGlobalOperator, globalOperator, collection }: { query: string; tenant: tenantBaseInfo; toggleGlobalOperator: () => void; globalOperator: string; collection: string }) {
  const legacyAction = () => {
    const url = `https://x.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/query?tenant=${tenant.id}&collection=${collection}&query=${query}`;
    window.open(url, "_blank");
  };

  return (
    <div className="flex flex-row">
      <Button type="secondary" className="rounded-r-none h-full" onClick={toggleGlobalOperator}>
        {globalOperator}
      </Button>
      <div className="alert bg-neutral text-neutral-content relative overflow-hidden flex-1 rounded-l-none shadow-md">
        <pre className="break-all whitespace-normal" style={{ maxWidth: "fit-content" }}>
          {query}
        </pre>
        <button
          onClick={legacyAction}
          disabled={tenant.id === ""}
          className="badge bg-base-100 hover:bg-base-300 text-base-content bg-opacity-50 border-opacity-0 px-3 p2-3 absolute right-0 bottom-0 rounded-box rounded-r-none rounded-b-none"
        >
          legacy view
        </button>
      </div>
    </div>
  );
}
