import { tenantBaseInfo } from "../../../../common/TenanInput/TenantInfoProvider";
import { sendWindowMessage } from "../../../../common/useMessageListener";
import { AddPinActionName } from "../../Pins/constants";
import { PinCardButton, PinCardInterface } from "../../Pins/types";
import { CompilePinActions } from "./pinActionsConstructor";
import { regexToLabel } from "./regexToLabel";

const keyBlacklist = ["routeId", "reportId", "queueId", ".template", "marketingId"];
const actionsCache: Record<string, () => void> = {};

export function AddPinFromSearch(search: string, tenant: tenantBaseInfo) {
  MakePinFunctionFromSearchWithKey("pin-search", search, tenant)?.();
}

export function MakePinFunctionFromSearchWithKey(key: string, value: any, tenant: tenantBaseInfo): (() => void) | null {
  if (actionsCache[key + value]) {
    return actionsCache[key + value];
  }

  const listed = keyBlacklist.find((i) => key.includes(i));
  if (listed) return null;

  const actions = CompilePinActions(value, tenant, true);
  if (actions.length === 0) return null; // Blank function
  const types: string[] = [];
  const matches: string[] = [];
  const buttons: PinCardButton[] = actions.map((action) => {
    if (!matches.includes(action.value)) matches.push(action.value);
    if (!types.includes(regexToLabel(action))) types.push(regexToLabel(action));
    return {
      color: "secondary",
      type: "secondary",
      label: action.text,
      value: `${action.text.replaceAll(" ", "")}-${key}`,
      link: action.url,
    };
  });

  const pinData: PinCardInterface = {
    content: matches.join("\n"),
    title: types.join("\n"),
    timestamp: Date.now(),
    dismissable: true,
    buttons,
  };

  const fn = () => {
    AddPin(pinData);
  };
  actionsCache[key + value] = fn;
  return fn;
}

function AddPin(pin: PinCardInterface) {
  sendWindowMessage(AddPinActionName, pin);
}
