import { regexFilters } from "./regexFilters";

export const actionPresets: ActionType[] = [
    {
        query: `{"_id":{"$oid":"%v"}}`, // Needed to find a workspace
        collection: 'booker.routers', // Needed to find a workspace
        regex: regexFilters.objectId,
        collectionLocked: false,
        workspaceRequired: true,
        search: 'router',
        found: false,
        label: 'Router',
        sortWeight: 10,
        link: 'https://apps.%domain/admin-center/meetings/%space/inbound-routers/edit/%v'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`, // Needed to find a workspace
        collection: 'booker.queues', // Needed to find a workspace
        regex: regexFilters.objectId,
        collectionLocked: false,
        workspaceRequired: true,
        search: 'queue',
        exclude: 'member',
        found: false,
        label: 'Queue',
        sortWeight: 11,
        link: 'https://apps.%domain/admin-center/meetings/%space/queues/edit/%v'
    },
    {
        query: `{"_id":"%v"}`, // Needed to find a workspace
        collection: 'booker.templates', // Needed to find a workspace
        regex: regexFilters.objectId,
        collectionLocked: false,
        workspaceRequired: true,
        search: 'template',
        found: false,
        label: 'Template',
        sortWeight: 12,
        link: 'https://apps.%domain/admin-center/meetings/%space/templates/edit/%v/calendar-invite'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`,
        collection: 'marketing.forms',
        regex: regexFilters.objectId,
        collectionLocked: false,
        workspaceRequired: false,
        search: 'marketingId.$oid',
        found: false,
        label: 'Marketing Values',
        sortWeight: 20,
        link: '/query/tenant?id=%tid&collection=marketing.forms&query=%query&env=%env'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`,
        collection: 'booker.routes',
        regex: regexFilters.objectId,
        collectionLocked: false,
        workspaceRequired: false,
        search: 'routeId.$oid',
        found: false,
        label: 'Route',
        sortWeight: 21, // Unique to 1 collection
        link: '/query/tenant?id=%tid&collection=booker.routes&query=%query&env=%env'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`,
        collection: 'booker.routes',
        regex: regexFilters.objectId,
        collectionLocked: true,
        workspaceRequired: false,
        search: '_id.$oid',
        found: false,
        label: 'Route Debug',
        sortWeight: 1, // Unique to 1 collection
        link: '/query/tenant?id=%tid&collection=booker.routes.debug&query=%query&env=%env'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`,
        collection: 'booker.routes.debug',
        regex: regexFilters.objectId,
        collectionLocked: true,
        workspaceRequired: false,
        search: '_id.$oid',
        found: false,
        label: 'Route',
        sortWeight: 1, // Unique to 1 collection
        link: '/query/tenant?id=%tid&collection=booker.routes&query=%query&env=%env'
    },
    {
        query: `{"_id":{"$oid":"%v"}}`,
        collection: 'users',
        regex: regexFilters.objectId,
        collectionLocked: true,
        workspaceRequired: false,
        search: '_id.$oid',
        found: false,
        label: 'User Actions',
        sortWeight: 1, // Unique to 1 collection
        link: 'https://x.%apiDomain/query?tenant=%tid&collection=%coll&query=%query#actions=true&env=%env'
    }
];
export type ActionType = {
    query?: string;
    collection?: string;
    collectionLocked: boolean;
    workspaceRequired: boolean;
    sortWeight: number;
    regex: RegExp;
    search?: string;
    exclude?: string;
    found: boolean;
    label: string;
    link: string;
    action?: () => void;
};