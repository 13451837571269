import { useEffect } from 'react'
import { internalToastInterface, useToast } from './ToastProvider'

export default function Toast({ toast }: { toast: internalToastInterface }) {
    const { removeToast } = useToast()
    useEffect(() => {
        const timeout = setTimeout(() => {
            removeToast(toast.id)
        }, toast.timeout || 3000)

        return () => {
            clearTimeout(timeout)
        }
    }, [removeToast, toast])
    const _type = "alert-" + toast.type

    return (
        <div className="alert z-50 p-0">
            <div className={`flex-1 alert flex flex-col ${_type}`}>
                {toast.title && <h4 className="text font-bold">{toast.title}</h4>}
                {toast.message && <p className="text">{toast.message}</p>}
                {toast.context && <p className="text-sm">{toast.context}</p>}
            </div>
        </div>
    )
}
