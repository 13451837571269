import {JsonViewer, NamedColorspace} from '@textea/json-viewer'

export const chili: NamedColorspace = {
  scheme: "Chili",
  author: "Chad Allard (Chili Piper)",
  base00: "var(--bg-base-100)", // Default Background
  base01: '#343d46',
  base02: "var(--bg-base-100)", // Null background
  base03: '#65737e',
  base04: "var(--bg-content)", // Array labels
  base05: '#c0c5ce',
  base06: '#dfe1e8',
  base07: "hsla(var(--wa))", // Keys and brackets
  base08: '#bf616a',
  base09: "hsla(var(--bc))", // Strings
  base0A: "hsla(var(--er) / var(--tw-text-opacity))", // Null
  base0B: "hsla(var(--in) / var(--tw-text-opacity))", // float
  base0C: '#96b5b4',
  base0D: '#8fa1b3',
  base0E: "hsla(var(--su) / var(--tw-text-opacity))", // Boolean
  base0F: "hsla(var(--in) / var(--tw-text-opacity))", // Integer
}

function ReactJsonView({ json }: { json: Record<string, any> }) {
  return (
    <JsonViewer
      value={json}
      theme={chili}
      valueTypes={[]}
      collapseStringsAfterLength={2000}
      editable={false}
      indentWidth={2}
      displayDataTypes={false}
      defaultInspectDepth={6}
      maxDisplayLength={100}
    />
  );
}

export default ReactJsonView;
