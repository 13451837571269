import { PropsWithChildren, ReactNode } from "react";

export default function Button(btn: PropsWithChildren<ButtonProps>) {
  const _type = btn.type ? "btn-" + btn.type : "btn-primary";
  return (
    <div {...btn} className={`btn ${_type} ${btn.className || ""}`} onClick={btn.onClick ? btn.onClick : () => {}}>
      {btn.children}
    </div>
  );
}

export type ButtonProps = {
  type?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  [key: string]: any; // Overload
};
