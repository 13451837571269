import { PropsWithChildren } from "react";

export default function Card({ children, className, title, titleClass, bodyClass }: PropsWithChildren<{ className?: string; title?: string; titleClass?: string; bodyClass?: string }>) {
  return (
    <div className={`card shadow-lg ${className || ""} bg-base-100 text-base-content overflow-visible w-full`}>
      <div className={`card-body overflow-visible p-4 ${bodyClass || ""}`}>
        {title && <h2 className={`card-title ${titleClass || ""} break-all whitespace-none`}>{title}</h2>}
        {children}
      </div>
    </div>
  );
}
