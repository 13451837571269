import { DefaultThemes, useThemeState } from "./ThemeContext";

export default function ThemeSelector() {
  const { theme, setTheme } = useThemeState();
  const setThemeState = (name: string) => {
    const selected = Object.values(DefaultThemes || {}).find((t) => t.value === name) || DefaultThemes["dark"];
    setTheme(selected);
  };

  return (
    <div>
      <select className="select select-bordered w-full max-w-xs" value={theme.value} onChange={(e) => setThemeState(e.currentTarget.value)}>
        {DefaultThemes &&
          Object.values(DefaultThemes).map((t, i) => (
            <option key={`${t.value}-${i}`} value={t.value} disabled={(t.disabled || !t.found) && !t.default}>
              {!t.found && !t.default && "🔒"} {t.name}
            </option>
          ))}
      </select>
    </div>
  );
}
