type Details = {
    metric:string
    details: string
    critical: boolean
}

const detailArray = {
    //administrative
    Id:"If Empty, the CRM is not connected.",
    salesforceAdmin:"Lists the tenant's global integration admin.",
    salesforceEmail:"Lists the tenant's global integration email.",
    // licenses
    Concierge_User_Licenses__c:"Describes the amount of users assigned to a Concierge license.",
    Events_User_Licenses__c:"Describes the amount of users assigned to a Event license.",
    Number_Distro_User_Licenses__c:"Describes the amount of users assigned to a Distro license.",
    Spicy_User_Licenses__c:"Describes the amount of users assigned to a Instant Booker license.",
    Meetings_User_Licenses__c:"Describes the amount of users assigned to a Handoff license.",
    // usage
    Active_Users__c: "Shows anyone who has used Chili Piper (logged in or booked a meeting or received a meeting) in the last 30 days.",
    Enabled_Users_CP__c: "Describes anyone who has been assigned a Instant Booker, Handoff, Concierge or Distro license.",
    Meeting_Booked_Last_7_Days__c: "Describes the number of meetings booked via Concierge in the last 7 days.",
    N_CONCIERGE_BOOKING_7_DAY__c:"# of Concierge meetings booked in the last 7 days.",
    Total_Calls_Initiated__c:"The number of calls initiated by the prospects in the last 30 days if you have an active phone queue.",
    Total_Concierge_Calls_Connected__c:"The number of calls that were completed (the prospect was connected to the rep) in the last 30 days if you have an active phone queue.",
    CPLicenses__c:"The percentage of licenses being used by the users from all licenses associated to the account.",
    MAU_Concierge__c: "The number of active users associated to a Concierge license in the last 30 days.",
    MAU_Distro__c: "he number of active users associated to a Distro license in the last 30 days.",
    MAU_Handoff__c:"The number of active users associated to a Handoff license in the last 30 days.",
    MAU_Instant_Booker__c: "The number of active users associated to a Instant Booker license in the last 30 days.",
    MAU__c: "The number of active users (anyone who has used Chili Piper) in the last 30 days.",
    WAU__c:"The number of active users (anyone who has used Chili Piper) in the last 7 days.",
    DAU__c:"The number of active users (anyone who has used Chili Piper) in the last 1 day.",
    N_CONCIERGE_FORM_SUBMISSION_28_DAY__c: "The number of form submissions through Concierge in the last 28 days.",
    N_CONCIERGE_QUAL_FORM_SUBMIT_28_DAY__c: "The number of qualified form submissions in the last 28 days.",
    N_DISTRO_ROUTES_LAST_7_DAYS__c: "The number of Distro routesin the last 7 days.",
    RATE_CONCIERGE_BOOK_28_BILLING__c: "The conversion rate of prospects that came from Concierge in the last 28 days.",
    // integrations
    crm: "The CRM connected (HubSpot/Salesforce).",
    sfid: "The ID of this Account in our SF instance (set above).",
    crmStatus: "The CRM connected (HubSpot/Salesforce).",
    calendar: "The calendar connected (Google/Outlook).",
    calendarStatus:"If the calendar is connected or disconnected.",
    calendarGlobal:"For GCal only, if they have installed at the org-level marketplace",
    G2_Integration_Enabled__c: "The G2 Integration status (checkbox in SF)",
    gong:"The Gong integration status.",
    intercom:"The Intercom integration status.",
    slack:"The Slack integration status.",
    instanceUrl:"In SF this is a subdomain, in HS this will be the domain it was installed for. This references a unique CRM Account.",
    appId:"References the unique HubSpot CRM Account this was installed to.",
    //health
    Open_Tickets__c: "Current number of open tickets for this account.",
    Closed_Tickets__c:"The number of closed tickets for this account.",
    Premium_Support__c: "If the customer is premium or standard.",
}

export function ReturnDetails(metric:string, critical:boolean) : Details{

    let details:string = Object(detailArray)[metric]

    let detailResult: Details = {
        metric,
        details,
        critical
    }

    return detailResult;
}