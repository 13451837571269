import Toast from './Toast'
import { internalToastInterface } from './ToastProvider'

export default function ToastContainer({ toasts }: { toasts: internalToastInterface[] }) {
    return (
        <div className="fixed right-0 top-0 flex flex-col gap-4 p-4 w-64 z-50 pointer-events-none">
            {toasts.map((toast, index) => (
                <Toast key={toast.id} toast={toast} />
            ))}
        </div>
    )
}
