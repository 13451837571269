import { ApiResponse } from "./apiResponse";
import { TENANT_COLLECTION_QUERY_FMT } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function QueryCollection(tenantID: string, tenantEnv: string, collection: string, payload: string, skipCache = false): Promise<ApiResponse> {
  if (!tenantID || !tenantID.trim() || !collection || !collection.trim()) {
    return {
      ok: false,
      status: 400,
      error: { message: "Failed to query a collection, TenantID, query or collection was blank", context: "" },
      body: {},
    };
  }
  
  const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_COLLECTION_QUERY_FMT.replace("%id", tenantID).replace("%col", collection)}?skipCache=${skipCache}&env=${tenantEnv}`;
  
  try {
    const res = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload,
    });
    
    return ParseApiResponse(res);
    
  } catch (error) {
    return {
      ok: false,
      status: 500,
      error: { message: "Failed to query a collection", context: `${error}` },
      body: {},
    };
  }
}
