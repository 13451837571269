import type { RowPresetsDict } from './types';

export const salesforceEvents: RowPresetsDict = {
    cardName: 'Event Details',
    presets: [
        {
            key: 'createdDate',
            label: 'Report Creation Date'
        },
        {
            key: 'retry',
            label: 'Retries Total'
        },
        {
            key: 'nextRetry.$date',
            label: 'Next Retry Time'
        },
        {
            key: 'lastRetrialTime',
            label: 'Last Retry Time'
        },
        {
            key: 'status',
            label: 'Event Status'
        },
        {
            key: 'reason',
            label: 'Reason'
        }
    ]
};