import { useState, useEffect } from "react";
import Button from "../../common/Button";
import { useLocation } from "react-router";
import { useUserState } from "../../common/Auth/AuthContext";
import { useTenantInfo } from "../../common/TenanInput/TenantInfoProvider";
function EndpointBuilder({ postQuery, setResult }: { postQuery: (value: string) => Promise<Record<string, any>>; setResult: (result: Record<string, any>) => void }) {
  const { state } = useUserState();
  const { getTenant } = useTenantInfo();

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const runQuery = async () => {
    setLoading(true);

    const result = await postQuery(value);
    setResult(result);

    setLoading(false);
  };

  // Parse query params
  const location = useLocation();
  useEffect(() => {
    if (state.isLoadingAuth || !state.isAuthenticated) return;
    const query = new URLSearchParams(location.search);
    if (query.get("value")) {
      setValue(query.get("value") as string);
    }
    if (query.get("value")) {
      postQuery(query.get("value") as string).then((result) => {
        setResult(result);
      });
    }
  }, [state.isLoadingAuth, state.isAuthenticated]);

  // Update query params
  useEffect(() => {
    if (state.isLoadingAuth || !state.isAuthenticated) return;
    const query = new URLSearchParams(location.search);
    query.set("id", getTenant().id);
    query.set("value", value);
    window.history.replaceState({}, "", `${location.pathname}?${query.toString()}`);
  }, [value, state.isLoadingAuth, state.isAuthenticated]);

  return (
    <div className="flex flex-col gap-2">
      <div className="form-control flex flex-row">
        <select className="bg-neutral text-neutral-content select rounded-box rounded-r-none">
          <option value="id">Route ID</option>
        </select>

        <input
          type="text"
          placeholder="60aff585a7380d0001639901"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="flex-grow input input-secondary input-bordered rounded-none bg-neutral text-neutral-content placeholder-neutral-content placeholder-opacity-50"
        />

        <Button className="rounded-l-none btn text-lg" type="secondary" onClick={() => setValue("")}>
          &#215;
        </Button>
      </div>

      <div className="flex justify-center">
        <Button onClick={runQuery} className={`shadow-md ${loading ? "loading" : ""}`} disabled={loading}>
          Run Query
        </Button>
      </div>
    </div>
  )
}

export default EndpointBuilder;
