export const _dynamicTags = {
  value: "{!v}",
  tenantID: "{!tid}",
  apiURI: "{!api_uri}",
  queryString: "{!query}",
  collection: "{!collection}",
  tenantAppDomain: "{!app_domain}",
  tenantApiDomain: "{!api_domain}",
  tenantEnvironment: "{!tenant_env}",
};

export const _chiliVisionURI = `/query/tenant/?id=${_dynamicTags.tenantID}&collection=${_dynamicTags.collection}&query=${_dynamicTags.queryString}`;
export const _gcpQueryTemplate = `https://console.cloud.google.com/logs/query;query="${_dynamicTags.value}"%0Aresource.type%3D"container"?project=chilipiper-cloud#csOptions={"tenant_id":"${_dynamicTags.tenantID}","cp_domain":"${_dynamicTags.tenantAppDomain}"}`;
export const _backOfficeURI = `https://backoffice-api.chilipiper.com`;

export const CRM_TYPE_SALESFORCE = "Salesforce";
export const CRM_TYPE_HUBSPOT = "HubSpot";
