import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import Card from "../common/Card";

export default function QueryParser() {
    const [urlValue, setUrlValue] = useState("");
    const [query, setQuery] = useState("");
    const [coll, setColl] = useState("");
    const [id, setId] = useState("");
    const [localURL, setLocalURL] = useState("");

    useEffect(() => {
        setId("");
        setColl("");
        setQuery("");
        setLocalURL("");
        if (!urlValue) return

        let url: URL
        try {
            url = new URL(urlValue);
        } catch (error) {
            return
        }
        // Tenant ID
        if (url) {
            const linkSplit = url.pathname.split("/")
            const tenantId = linkSplit[linkSplit.length - 1]
            if (tenantId) setId(tenantId);
        }
        if (url.searchParams) {
            if (url.searchParams.get("collection")) setColl(url.searchParams.get("collection") as string)
            if (url.searchParams.get("query")) setQuery(url.searchParams.get("query") as string)
        }
    }, [urlValue])

    useEffect(() => {
        if (!id && !coll && !query) return
        const localUrl = `/query/tenant/?id=${id}&collection=${coll}&query=${query}`
        setLocalURL(localUrl)
    }, [coll, id, query])

    return (
        <div className="flex flex-col gap-4">
            <div className="text-center text-xl">Query Parser</div>

            <Card>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Enter Solver query URL</span>
                    </label>
                    <input type="text" value={urlValue} placeholder="https://solver.forchili.com/..." onChange={(e) => setUrlValue(e.target.value)} className="input bg-neutral text-neutral-content" />
                </div>
            </Card>

            <Card>
                <div className="flex flex-col gap-2 text-base-content">
                    <div className="text-lg font-bold">Preiew
                    </div>
                    <div>
                        Tenant: {id || "none"}
                    </div>
                    <div>
                        Collection: {coll || "none"}
                    </div>
                    <div>
                        Query:
                        <pre>
                            {query && query}
                        </pre>
                    </div>
                    <Link to={localURL}>
                        <Button className="w-full" disabled={!localURL}>Open</Button>
                    </Link>
                </div>
            </Card>
        </div>
    )
}
