import { throttle } from "lodash";
import { useEffect } from "react";

// Hook
export function useSelectionEventListener(callback: (search: string) => void) {
  const throttledCallback = throttle(callback, 500, { trailing: true, leading: false });

  useEffect(() => {
    function messageHandler() {
      const selection = window?.getSelection()?.toString();
      if (selection) {
        throttledCallback(selection);
      }
    }
    document.addEventListener("selectionchange", messageHandler);
    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener("selectionchange", messageHandler);
    };
  }, [throttledCallback]);
  return;
}
