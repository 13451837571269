import { createContext, useContext, useState } from "react";

const TenantContext = createContext<{
  getTenant: () => tenantBaseInfo;
  setTenant: (t: tenantBaseInfo) => void;
}>({} as any);

export const TenantInfoProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [tenant, setTenant] = useState({} as tenantBaseInfo);
  const getTenant = () => {
    return tenant;
  };
  const setTenantWithCache = (t: tenantBaseInfo) => {
    window?.localStorage?.setItem("tenant", JSON.stringify(t));
    setTenant(t);
  };

  return <TenantContext.Provider value={{ getTenant: getTenant, setTenant: setTenantWithCache }}>{children}</TenantContext.Provider>;
};

export function useTenantInfo() {
  const tenantHelpers = useContext(TenantContext);
  return tenantHelpers;
}

export type tenantBaseInfo = {
  environment: string;
  cluster: string;
  crmType: string;
  id: string;
};

export function GetTenantDomain(t: tenantBaseInfo): string {
  if (t.environment === "staging") {
    return "chilipiper.io";
  }
  if (t.environment === "canary" || t.cluster === "canary") {
    return "na.chilipiper.com";
  }
  return "chilipiper.com";
}

export const addToastMessageAction = "ADD_TOAST";

export const GetCachedTenant = () => {
  const t = window?.localStorage?.getItem("tenant");
  return t ? JSON.parse(t) : {};
};
