import React from 'react'
import SelectorMenu, { SelectorMenuButton } from '../../../common/SelectorMenu';
import { presetField } from '.././presets/collectionFieldsPresets'

export default function QuickQuerySelector({ toggleAdvancedQuery, presetFields, setFields }: { toggleAdvancedQuery: (addBlank?: boolean) => void, presetFields: presetField[], setFields: (field: presetField) => void }) {
    const menuButtons: SelectorMenuButton[] = presetFields.map((p, _) => ({
        type: "neutral",
        value: p,
        label: p.label,
        onClick: (p: presetField) => { setFields(p); toggleAdvancedQuery(false) },
    }));
    menuButtons.push({
        value: true, // Value is passed to toggleAdvancedQuery
        type: "neutral",
        label: "Advanced",
        onClick: toggleAdvancedQuery,
    });

    return (
        <div className="w-full flex justify-center">
            <SelectorMenu buttons={menuButtons} selectorName="Query" />
        </div>
    )
}