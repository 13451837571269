import { CRM_TYPE_SALESFORCE, _chiliVisionURI, _dynamicTags, _gcpQueryTemplate, CRM_TYPE_HUBSPOT } from "./constants";
import { tenantBaseInfo } from "../../../../common/TenanInput/TenantInfoProvider";
import { _checkSectionOptionConfig } from "./checkSectionOptionConfig";
import { _regexFilter } from "./types";

const _defaultMatchLimit = 3; // How many results to match in an element/parent

export const _regexFilters: Record<string, _regexFilter> = {
  // Order here is the order of execution, which may impact accuracy of the match
  // ie objectID and salesforceID might match the same string, typically objectID is the right match in such a case
  email: {
    // DONE
    category: "Emails",
    disableAutoScrape: false,
    regex: /\b([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)\b/gi,
    options: [
      // Each option will create a menu entry per match
      {
        enabled: _checkSectionOptionConfig("cp_impersonate_email"), // TODO -- Add feature flags to disable specific menu options with a remote config
        rawURL: `https://api.${_dynamicTags.tenantApiDomain}/impersonate?tenant=${_dynamicTags.tenantID}&email=${_dynamicTags.value}`,
        tag: "cp_impersonate_email",
        label: "Impersonate",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "").replace("+","%2B");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_user_email"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "users").replaceAll(_dynamicTags.queryString, `{'email':'${_dynamicTags.value}'}`)}`,
        tag: "cp_user_email",
        label: "CP User",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "").replace("+","%2B");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_user_email"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Id,Email,Username,Name,isActive from User where Email = '${_dynamicTags.value}'&fields=Id,Email,Username,Name,isActive`,
        tag: "sfdc_user_email",
        label: "SFDC User",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_SALESFORCE && !value.includes("@chilipiper.com");
        },
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "");
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_route_email"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.routes").replaceAll(_dynamicTags.queryString, `{'prospect.email':'${_dynamicTags.value}'}`)}`,
        tag: "cp_route_email",
        label: "CP Route",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "").replace("+","%2B");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_report_email"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.reports").replaceAll(_dynamicTags.queryString, `{'prospectInfo.email':'${_dynamicTags.value}'}`)}`,
        tag: "cp_report_email",
        label: "CP Report",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "").replace("+","%2B");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_distro_email"),  
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "distro.logs").replaceAll(_dynamicTags.queryString, `{'crmObject.Email':'${_dynamicTags.value}'}`)}`,
        tag: "cp_distro_email",
        label: "Distro Log",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "").replace("+","%2B");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_hs_prospect_email"),
        rawURL: `https://backoffice-api.${_dynamicTags.tenantApiDomain}/api/dev/describe-sf-prospect?tenantId=${_dynamicTags.tenantID}&id=${_dynamicTags.value}`,
        tag: "cp_hs_prospect_email",
        label: "SFDC Prospect",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com") && opts?.crmType === CRM_TYPE_SALESFORCE;
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_hs_prospect_email"),
        rawURL: `https://backoffice-api.${_dynamicTags.tenantApiDomain}/api/dev/describe-prospect?tenantId=${_dynamicTags.tenantID}&id=${_dynamicTags.value}`,
        tag: "cp_hs_prospect_email",
        label: "HS Prospect",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com") && opts?.crmType === CRM_TYPE_HUBSPOT;
        },
      },
      {
        limit: _defaultMatchLimit,
        label: "GCP Logs",
        tag: "gcp_user_email",
        rawURL: `${_gcpQueryTemplate}`,
        enabled: _checkSectionOptionConfig("gcp_user_email"),
        cleanup(value: string): string {
          return value.replaceAll(".jpg", "");
        },
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.includes("@chilipiper.com");
        },
      },
    ],
  },
  objectID: {
    // DONE
    category: "Chili Piper IDs",
    disableAutoScrape: false,
    regex: /\b[a-fA-F0-9]{24}\b/g,
    options: [
      {
        enabled: _checkSectionOptionConfig("cp_object_id_report"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.reports").replaceAll(_dynamicTags.queryString, `{'_id':{'$oid':'${_dynamicTags.value}'}}`)}`,
        tag: "cp_object_id_report",
        label: "CP Report",
        limit: _defaultMatchLimit,
      },
      {
        enabled: _checkSectionOptionConfig("cp_object_id_route"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.routes").replaceAll(_dynamicTags.queryString, `{'_id':{'$oid':'${_dynamicTags.value}'}}`)}`,
        tag: "cp_object_id_route",
        label: "CP Route",
        limit: _defaultMatchLimit,
      },
      {
        enabled: _checkSectionOptionConfig("cp_object_id_user"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "users").replaceAll(_dynamicTags.queryString, `{'_id':{'$oid':'${_dynamicTags.value}'}}`)}`,
        tag: "cp_object_id_user",
        label: "CP User",
        limit: _defaultMatchLimit,
      },
      {
        enabled: _checkSectionOptionConfig("cp_object_id_distro"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "distro.logs").replaceAll(_dynamicTags.queryString, `{'groupId':{'$oid':'${_dynamicTags.value}'}}`)}`,
        tag: "cp_object_id_distro",
        label: "Distro Log",
        limit: _defaultMatchLimit,
      },
      {
        enabled: _checkSectionOptionConfig("cp_object_id_sfdc_event"),
        rawURL: `https://backoffice-api.${_dynamicTags.tenantApiDomain}/api/qa/salesforce-by-report?tenantId=${_dynamicTags.tenantID}&reportId=${_dynamicTags.value}`,
        tag: "cp_object_id_sfdc_event",
        label: "SFDC Event",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_SALESFORCE;
        },
      },
      {
        enabled: _checkSectionOptionConfig("cp_object_id_event_status"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.salesforce.event").replaceAll(_dynamicTags.queryString, `{'_id':{'$oid':'${_dynamicTags.value}'}}`)}`,
        tag: "cp_object_id_event_status",
        label: "SF Event Status",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_SALESFORCE;
        },
      },
    ],
  },
  salesforceID: {
    // DONE
    category: "Salesforce IDs",
    disableAutoScrape: false,
    regex: /\b[a-z0-9]\w{4}0\w{12}|[a-z0-9]\w{4}0\w{9}\b/g,
    options: [
      // User object
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_user_reports"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.reports").replaceAll(_dynamicTags.queryString, `{'bookRequest.assignee.crmId':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_user_reports",
        label: "CP Reports",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("005");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_user_info"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "users").replaceAll(_dynamicTags.queryString, `{'connections.salesforce._id':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_user_info",
        label: "CP User",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("005");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_user_info_sfdc"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Id,Email,Username,Name,isActive from User where Id = '${_dynamicTags.value}'&fields=Id,Email,Username,Name,isActive`,
        tag: "sfdc_object_id_user_info_sfdc",
        label: "SFDC User",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("005");
        },
      },
      // Lead/Contact object
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_reports"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.reports").replaceAll(_dynamicTags.queryString, `{'prospectInfo._id':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_prospect_reports",
        label: "CP Reports",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00Q") || value.startsWith("003");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_routes"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.routes").replaceAll(_dynamicTags.queryString, `{'prospect._id':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_prospect_routes",
        label: "CP Routes",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00Q") || value.startsWith("003");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_describe"),
        rawURL: `https://backoffice-api.${_dynamicTags.tenantApiDomain}/api/dev/describe-sf-prospect?tenantId=${_dynamicTags.tenantID}&id=${_dynamicTags.value}`,
        tag: "sfdc_object_id_prospect_describe",
        label: "SFDC Prospect",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00Q") || value.startsWith("003");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_debug"),
        rawURL: `https://api.${_dynamicTags.tenantApiDomain}/api/v1/booker/rule/debug?id=${_dynamicTags.value}&isLead=true`,
        tag: "sfdc_object_id_prospect_debug",
        label: "CP Debug",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00Q");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_debug"),
        rawURL: `https://api.${_dynamicTags.tenantApiDomain}/api/v1/booker/rule/debug?id=${_dynamicTags.value}&isLead=false`,
        tag: "sfdc_object_id_prospect_debug",
        label: "CP Debug",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("003");
        },
      },
      // Distro all objects
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_prospect_distro"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "distro.logs").replaceAll(_dynamicTags.queryString, `{'crmId':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_prospect_distro",
        label: "Distro Log",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          // Lead, Contact, Opp, Account, Case
          return value.startsWith("00Q") || value.startsWith("003") || value.startsWith("006") || value.startsWith("001") || value.startsWith("500");
        },
      },
      // Event object
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_event_report"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.reports").replaceAll(_dynamicTags.queryString, `{'crmId':'${_dynamicTags.value}'}`)}`,
        tag: "sfdc_object_id_event_report",
        label: "CP Report",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00U");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_event_record"),
        rawURL: `https://backoffice-api.${_dynamicTags.tenantApiDomain}/api/dev/find-sf-event?tenantId=${_dynamicTags.tenantID}&id=${_dynamicTags.value}`,
        tag: "sfdc_object_id_event_record",
        label: "SFDC Event",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("00U");
        },
      },
      // Account object
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_account_contacts"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Email, Name, Id from Contact where AccountId = '${_dynamicTags.value}'&fields=Email, Name, Id`,
        tag: "sfdc_object_id_account_contacts",
        label: "SFDC Contacts",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("001");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_account_opps"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Name, CloseDate, IsClosed from Opportunity where AccountId = '${_dynamicTags.value}'&fields=Name, CloseDate, IsClosed`,
        tag: "sfdc_object_id_account_opps",
        label: "SFDC Opportunities",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("001");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_account_cases"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Subject, IsClosed from Case where AccountId = '${_dynamicTags.value}'&fields=Subject, IsClosed`,
        tag: "sfdc_object_id_account_cases",
        label: "SFDC Cases",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("001");
        },
      },
      {
        enabled: _checkSectionOptionConfig("sfdc_object_id_account_cases"),
        rawURL: `https://x.chilipiper.com/raw-sf-query?tenant=${_dynamicTags.tenantID}&query=select Name, Id, OwnerId from Account where Id = '${_dynamicTags.value}'&fields=Name, Id, OwnerId`,
        tag: "sfdc_object_id_account_cases",
        label: "SFDC Account",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return value.startsWith("001");
        },
      },
    ],
  },
  hubSpotID: {
    // TODO
    category: "HubSpot IDs",
    disableAutoScrape: true,
    regex: /\b([0-9]{6,12})\b/g,
    options: [
      {
        enabled: _checkSectionOptionConfig("hubspot_id_contact"),
        rawURL: `/query/crm?id=${_dynamicTags.tenantID}&value=${_dynamicTags.value}&type=contact`,
        tag: "hubspot_id_contact",
        label: "HubSpot Contact",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_HUBSPOT;
        },
      },
      {
        enabled: _checkSectionOptionConfig("hubspot_id_company"),
        rawURL: `/query/crm?id=${_dynamicTags.tenantID}&value=${_dynamicTags.value}&type=company`,
        tag: "hubspot_id_company",
        label: "HubSpot Company",
        limit: _defaultMatchLimit,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_HUBSPOT;
        },
      },
    ],
  },
  salesforceCustomField: {
    // DONE
    category: "Custom Fields",
    disableAutoScrape: false,
    regex: /\b[\S]{6,24}__c\b/gi,
    options: [
      {
        enabled: _checkSectionOptionConfig("sfdc_custom_field"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "salesforce.types").replaceAll(_dynamicTags.queryString, `{'fields.name':{'$regex':'${_dynamicTags.value}','$options':'i'}}`)}`,
        tag: "sfdc_custom_field",
        label: "CP Custom Field",
        limit: 1,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return opts?.crmType === CRM_TYPE_SALESFORCE;
        },
      },
    ],
  },
  jira: {
    // DONE
    category: "JIRA Tickets",
    disableAutoScrape: false,
    regex: /CPP-[0-9]{2,6}\b/gi,
    options: [
      {
        enabled: _checkSectionOptionConfig("jira_by_id"),
        rawURL: `https://floatingapps.atlassian.net/browse/${_dynamicTags.value}`,
        tag: "jira_by_id",
        label: "Jira Ticket",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.toUpperCase();
        },
      },
    ],
  },
  zendesk: {
    // DONE
    category: "Zendesk Tickets",
    disableAutoScrape: true,
    regex: /#[0-9]{3,5}\b/gi,
    options: [
      {
        enabled: _checkSectionOptionConfig("zendesk_by_id"),
        rawURL: `https://chilipiper.zendesk.com/agent/tickets/${_dynamicTags.value}`,
        tag: "zendesk_by_id",
        label: "Zendesk Ticket",
        limit: _defaultMatchLimit,
        cleanup(value: string): string {
          return value.replaceAll("#", "");
        },
      },
    ],
  },
  names: {
    // DONE
    category: "Names",
    disableAutoScrape: true,
    regex: /\b[A-Z][a-z]{3,10}\s[A-Z][a-z]{3,10}\b/g,
    options: [
      {
        enabled: _checkSectionOptionConfig("user_by_name"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "users").replaceAll(_dynamicTags.queryString, `{'displayName':{'$regex':'${_dynamicTags.value}','$options':'i'}}`)}`,
        tag: "user_by_name",
        label: "CP User",
        limit: 1,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.toLowerCase().includes("data-");
        },
      },
    ],
  },
  bookingLinks: {
    // DONE
    category: "Booking Links",
    disableAutoScrape: true,
    regex: /\b[a-z]{3,10}(-[a-z0-9]{3,10}){1,5}\b/gi,
    options: [
      {
        enabled: _checkSectionOptionConfig("user_by_link"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "users").replaceAll(_dynamicTags.queryString, `{'linkName':{'$regex':'${_dynamicTags.value}','$options':'i'}}`)}`,
        tag: "user_by_link",
        label: "CP User",
        limit: 1,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.toLowerCase().includes("data-") && !value.toLowerCase().includes("cpp-");
        },
      },
      {
        enabled: _checkSectionOptionConfig("queue_by_link"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "booker.queues").replaceAll(_dynamicTags.queryString, `{'onlineSettings.linkName':{'$regex':'${_dynamicTags.value}','$options':'i'}}`)}`,
        tag: "queue_by_link",
        label: "CP Queue",
        limit: 1,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.toLowerCase().includes("data-") && !value.toLowerCase().includes("cpp-");
        },
      },
      {
        enabled: _checkSectionOptionConfig("distro_error"),
        rawURL: `${_chiliVisionURI.replaceAll(_dynamicTags.collection, "distro.logs").replaceAll(_dynamicTags.queryString, `{'error':{'$regex':'${_dynamicTags.value}','$options':'i'}}`)}`,
        tag: "distro_error",
        label: "Distro Error",
        limit: 1,
        condition: (value: string, opts: tenantBaseInfo): boolean => {
          return !value.toLowerCase().includes("data-") && !value.toLowerCase().includes("cpp-");
        },
      },
    ],
  },
};
