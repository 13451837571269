import { ApiResponse } from "./apiResponse";
import { TENANT_COLLECTION_FIELDS_FMT } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function GetTenantCollectionFields(tenantID: string, env: string, collection: string, skipCache = false): Promise<ApiResponse> {
  if (!tenantID || tenantID.length === 0 || !collection || collection.length === 0) {
    return {
      ok: false,
      status: 400,
      error: { message: "Failed to get collection fields, TenantID or collection was blank", context: "" },
      body: {},
    };
  }
  const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_COLLECTION_FIELDS_FMT.replace("%id", tenantID).replace("%col", collection)}?skipCache=${skipCache}&env=${env}`;
  try {
    const res = await fetch(url, { credentials: "include" });
    return ParseApiResponse(res);
  } catch (error) {
    return {
      ok: false,
      status: 500,
      error: { message: "Failed to get collection fields", context: `${error}` },
      body: {},
    };
  }
}
