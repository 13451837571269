import ReactJsonView from "../../../common/ReactJsonView";

export default function JsonView({ json }: { json: Record<string, any> }) {
  return (
    <div className="p-4 rounded-box">
      <pre className="break-all whitespace-pre-wrap rounded-box">
        <ReactJsonView json={json} />
      </pre>
    </div>
  );
}
