import type { RowPresetsDict } from './types';

export const prospectRoutesPreset: RowPresetsDict = {
    cardName: 'Prospect',
    presets: [
        {
            key: 'prospect.fullName',
            label: 'Name'
        },
        {
            key: 'prospect.email',
            label: 'Email'
        },
        {
            key: 'prospect.inCrm',
            label: 'Exists in CRM'
        },
        {
            key: 'prospect.inSalesforce',
            label: 'Exists in Salesforce'
        },
        {
            key: 'prospect.inHubspot',
            label: 'Exists in HubSpot'
        },
        {
            key: 'prospect._id',
            label: 'Prospect CRM ID'
        },
        {
            key: 'prospect.newProspectType',
            label: 'Type'
        },
        {
            key: 'prospect.accountId',
            label: 'Account'
        },
        {
            key: 'prospect.createdDate',
            label: 'Created Date'
        },
        {
            key: 'prospect.ownerEmail',
            label: 'Owner Email'
        }
    ]
};
export const prospectReportsPreset = {
    cardName: 'Prospect',
    presets: [
        {
            key: 'prospectInfo.fullName',
            label: 'Name'
        },
        {
            key: 'prospectInfo.email',
            label: 'Email'
        },
        {
            key: 'prospectInfo.inCrm',
            label: 'Exists in CRM'
        },
        {
            key: 'prospectInfo._id',
            label: 'Prospect CRM ID'
        },
        {
            key: 'prospectInfo.newProspectType',
            label: 'Type'
        },
        {
            key: 'prospectInfo.accountId',
            label: 'Account'
        },
        {
            key: 'prospectInfo.createdDate',
            label: 'Created Date'
        },
        {
            key: 'prospectInfo.ownerEmail',
            label: 'Owner Email'
        }
    ]
};