export const detailPresets:Array<{error:string, value:string}> = [
    { error: 'crm.streaming.action-update-failed', value: 'The action has failed. Possible reasons are invalid SFDC credentials, outdated SFDC credentials, field is unable to be updated, or SFDC outage' },
    { error: 'crm.streaming.crm-unsupported', value: 'Your SFDC is not connected or set up' },
    { error: 'crm.streaming.crm-target-retrieval-failed', value: 'Distro has failed to retrieve ownership from an object and/or failed to apply ownership' },
    { error: 'crm.streaming.credentials-not-found', value: 'Distro could not find admin org credentials' },
    { error: 'crm.streaming.refresh-token-not-found', value: 'Distro has found the credentials but there is no refresh token to use'},
    { error: 'crm.streaming.assignee-not-found', value: 'The Assignee that Distro has picked does not have a CRM ID' },
    { error: 'crm.streaming.route-invalid', value: 'An element is missing from the router' },
    { error: 'crm.streaming.trigger-invalid', value: 'An element is missing from the trigger' },
    { error: 'crm.streaming.failed-routing', value: 'Route rule execution was not successful' },
    { error: 'crm.streaming.rules-not-found', value: 'Rules not found. Missing Rule IDs' },
    { error: 'crm.streaming.rules-not-found', value: 'Assignment rulesets not found. Missing Ruleset IDs' },
    { error: 'crm.streaming.create-external-error', value: 'Failed to create the config in the external service' },
    { error: 'crm.streaming.request-failed', value: 'Request that was sent to Distro failed' },
    { error: 'crm.streaming.tenant-not-found', value: 'Tenant not found. Tenant ID lookup failed' },
    { error: 'crm.streaming.team-not-found', value: 'Routed Team is not found. Was it deleted?' },
    { error: 'crm.streaming.router-not-found', value: 'CRM Router not found. Was it deleted?' },
    { error: 'crm.streaming.field-not-found', value: "We can't locate the CRM object's field used in routing" },
    { error: 'crm.streaming.route-successful-not-found', value: 'Successful route was not found. Review logs' },
    { error: 'crm.streaming.trigger-event-not-matching', value: 'Trigger event type not matching' },
    { error: 'crm.streaming.trigger-object-not-matching', value: 'Trigger object not matching' },
    { error: 'crm.streaming.trigger-rule-not-matching', value: 'Trigger rule not matching' },
    { error: 'crm.streaming.trigger-rules-failed', value: "The Trigger's rules failed" },
    { error: 'crm.streaming.routes-rules-failed', value: 'The route rulesets failed' },
    { error: 'crm.streaming.assignee-not-in-team', value: 'The assignee is not in a team. Were they removed during route?' },
    { error: 'crm.streaming.routes-rules-suceeded-sla', value: 'SLA rule execution is successful' },
    { error: 'crm.streaming.action-update-failed', value: 'An error occurred when updating Salesforce' },
    { error: 'crm.streaming.sync-invalid', value: 'No appropriate status could be retrieved for sync status' },
    { error: 'crm.streaming.target-not-supported', value: 'Ownership object not supported' },
    { error: 'crm.streaming.sla-setup-error', value: 'There is an issue in how SLA is setup. Please review SLA settings' },
    { error: 'crm.streaming.sla-not-implemented', value: 'SLA Action has not been configured' },
    { error: 'crm.streaming.object-not-found', value: 'The CRM object was not found or cannot be accessed' },
    { error: 'crm.streaming.distribution-failed', value: 'We failed to do distribution (ownership) upsert. Check permissions' },
    { error: 'crm.streaming.sla-rules-failed', value: 'SLA rules have failed to match' },
    { error: 'crm.streaming.crm-target-retrieval-failed', value: 'There is an issue with the Salesforce connection' },
    { error: 'crm.streaming.target-not-supported', value: 'Ownership object not supported' },
    { error: 'crm.streaming.parsing-error', value: 'Parsing error. Check rules logic is formatted correctly' },
    { error: 'crm.streaming.log-not-found', value: 'Log ID not found for this Distro route' },
    { error: 'crm.streaming.team-not-found', value: 'Team that was referenced in this route was not found' },
    { error: 'crm.streaming.rule-not-found', value: 'Rule was not found. Was it deleted or removed?' },
    { error: 'crm.streaming.assignment-ruleset-not-found', value: 'Assignment ruleset not found. Was it deleted or removed?' },
    { error: 'crm.streaming.transformation-error', value: 'JSON transformation error. Likely caused by invalid rule info or service error' },
    { error: 'crm.streaming.serialization-error', value: 'Could not serialize the string. Please review logs' },
    { error: 'crm.streaming.ownership-not-valid', value: 'Ownership of this route could not be validated. Does owner exist? Does object have owner?' },
];