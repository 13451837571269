import { useEffect } from "react";

// Hook
export function useMessageListener(targetAction: string, callback: (data: any) => void) {
    // If pressed key is our target key then set to true
    function messageHandler(m: MessageEvent) {
        if (m.data && m.data.action === targetAction && m.data.payload) {
            callback(m.data.payload);
        }
    }
    // Add event listeners
    useEffect(() => {
        window.addEventListener("message", messageHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, []);
    return;
}

export function sendWindowMessage(action: string, payload: any) {
    window.parent.postMessage({ action, payload }, "*");
}