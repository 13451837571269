import { useEffect, useState } from "react";
import { SearchTenants, SearchTenantsGet } from "../../endpoints/searchTenants";
import { GetSFFields, GetSFFieldsTenantObj } from "../../endpoints/getSFFields";
import { GetProductList } from "../../endpoints/getProductList";
import { useTenantInfo } from "../../common/TenanInput/TenantInfoProvider";

interface ProductArr {
    name: string
    enabled: boolean
}

export interface Products {
    Meetings: boolean
    Concierge: boolean
    MildMeetings: boolean
    ConciergeLiveCalls: boolean
    Events: boolean
    Distribution: boolean
    Enterprise: boolean
}

const initProd = {
    Meetings: false,
    Concierge: false,
    MildMeetings: false,
    ConciergeLiveCalls: false,
    Events: false,
    Distribution: false,
    Enterprise: false,
}

export interface Connections {
    //administrative
    Id: string | undefined
    salesforceId: string
    name: string
    salesforceAdmin: string | null
    salesforceEmail: string | null
    subDomain: string
    logo: string
    sandbox: boolean
    domains: Array<string>
    AM_Text__c: string
    CS_Text_Field__c: string
    Customer__c: string | boolean
    Customer_Lifecycle_Phase__c: string
    // licenses
    Concierge_User_Licenses__c: number
    Events_User_Licenses__c: number
    Number_Distro_User_Licenses__c: number
    Spicy_User_Licenses__c: number
    Meetings_User_Licenses__c: number
    // usage
    Original_Transaction_Start_Date__c: string
    Renewal_Date__c: string
    CP_Users__c: number
    Active_Users__c: number
    Enabled_Users_CP__c: number
    meetings_booked_last_7_days__c: number
    N_CONCIERGE_BOOK_7_BILLING__c: number
    Total_Calls_Initiated__c: number
    Total_Concierge_Calls_Connected__c: number
    CPLicenses__c: number
    mau_concierge__c: number
    mau_distro__c: number
    mau_handoff__c: number
    mau_instant_booker__c: number
    MAU__c: number
    wau__c: number
    dau__c: number
    N_CONCIERGE_SUBMIT_FORM_28_BILLING__c: number
    N_CONCIERGE_QUAL_28_BILLING__c: number
    Distro_Routes_Last_7_Days__c: number
    RATE_CONCIERGE_BOOK_28_BILLING__c: number
    // integrations
    crm: string
    crmStatus: boolean
    calendar: string
    calendarStatus: boolean
    calendarGlobal: boolean
    G2_Integration_Enabled__c: boolean
    gong: boolean
    gongEmail: string
    intercom: boolean
    intercomEmail: string
    slack: boolean
    slackEmail: string
    instanceUrl: string
    appId: number
    //health
    Open_Tickets__c: number
    Closed_Tickets__c: number
    Premium_Support__c: boolean
}

const initialConnections = {
    //administrative
    Id: "",
    salesforceId: "",
    name: "",
    salesforceAdmin: null,
    salesforceEmail: null,
    subDomain: "",
    logo: "",
    sandbox: false,
    domains: [""],
    AM_Text__c: "",
    CS_Text_Field__c: "",
    Customer__c: false,
    Customer_Lifecycle_Phase__c: "",
    // licenses
    Concierge_User_Licenses__c: 0,
    Events_User_Licenses__c: 0,
    Number_Distro_User_Licenses__c: 0,
    Spicy_User_Licenses__c: 0,
    Meetings_User_Licenses__c: 0,
    // usage
    Original_Transaction_Start_Date__c: "",
    Renewal_Date__c: "",
    CP_Users__c: 0,
    Active_Users__c: 0,
    Enabled_Users_CP__c: 0,
    meetings_booked_last_7_days__c: 0,
    N_CONCIERGE_BOOK_7_BILLING__c: 0,
    Total_Calls_Initiated__c: 0,
    Total_Concierge_Calls_Connected__c: 0,
    CPLicenses__c: 0,
    mau_concierge__c: 0,
    mau_distro__c: 0,
    mau_handoff__c: 0,
    mau_instant_booker__c: 0,
    MAU__c: 0,
    wau__c: 0,
    dau__c: 0,
    N_CONCIERGE_SUBMIT_FORM_28_BILLING__c: 0,
    N_CONCIERGE_QUAL_28_BILLING__c: 0,
    Distro_Routes_Last_7_Days__c: 0,
    RATE_CONCIERGE_BOOK_28_BILLING__c: 0,
    // integrations
    crm: "Not installed",
    crmStatus: false,
    calendar: "Unknown",
    calendarStatus: false,
    calendarGlobal: false,
    G2_Integration_Enabled__c: false,
    gong: false,
    gongEmail: "",
    intercom: false,
    intercomEmail: "",
    slack: false,
    slackEmail: "",
    instanceUrl: "",
    appId: 0,
    //health
    Open_Tickets__c: 0,
    Closed_Tickets__c: 0,
    Premium_Support__c: false,
}

export function HPConnections() {

    const { getTenant } = useTenantInfo();
    const tenant = getTenant().id
    const [connections, setConnections] = useState<Connections>(initialConnections);
    const [products, setProducts] = useState<Products>(initProd);

    // easily update our object of connections on the fly
    const updateObj = (value: {}) => {
        setConnections(connections => ({
            ...connections, ...value
        }))
    }
    const updateProductObj = (value: {}) => {
        setProducts(product => ({
            ...product, ...value
        }))
    }

    const getConnections = async (search: string, noCache = false) => {
        const [acctRes, sfRes, tenantObj, productRes, acctGetter] = await Promise.all([
            SearchTenants(search, noCache),
            GetSFFields(search),
            GetSFFieldsTenantObj(search),
            GetProductList(search),
            SearchTenantsGet(search),
        ]);
        const updates: Connections = {} as Connections;

        // load active products
        if (productRes.body.length > 0) {
            Object.values((productRes.body as ProductArr)).forEach(entry => {
                const value = entry;
                updateProductObj({ [value.name]: value.enabled })
            });
        }

        // if no connection returned, update licenses with default values (re-render)
        if (typeof sfRes.body[0] !== "undefined" || typeof acctRes.body[0] !== "undefined") {
            setConnections(initialConnections)
        }

        //Active Products
        if (typeof sfRes.body[0] !== "undefined") {
            updates.Meetings_User_Licenses__c = sfRes.body[0].Meetings_User_Licenses__c
            updates.Concierge_User_Licenses__c = sfRes.body[0].Concierge_User_Licenses__c
            updates.Events_User_Licenses__c = sfRes.body[0].Events_User_Licenses__c
            updates.Number_Distro_User_Licenses__c = sfRes.body[0].Number_Distro_User_Licenses__c
            updates.Spicy_User_Licenses__c = sfRes.body[0].Spicy_User_Licenses__c
            updates.Original_Transaction_Start_Date__c = sfRes.body[0].Original_Transaction_Start_Date__c
            updates.Renewal_Date__c = sfRes.body[0].Renewal_Date__c
        }

        // Calendars
        if (typeof acctRes.body[0] !== "undefined" && acctRes.ok) {
            if (typeof acctRes.body[0].connections.microsoft !== "undefined" || typeof acctRes.body[0].connections.google !== "undefined") {
                if (typeof acctRes.body[0].connections.google === "undefined" || acctRes.body[0].connections.google === null) {
                    updates.calendar = "Microsoft"
                    if (acctRes.body[0].connections.microsoft.status === "Connected") {
                        updates.calendarStatus = true
                    }
                    updates.calendarStatus = acctRes.body[0].connections.microsoft.status
                } else {
                    updates.calendar = "Google"
                    updates.calendarStatus = true // will always be true unless there is a global connection!
                    if (acctRes.body[0].connections.google.status === "Connected") {
                        updates.calendarStatus = true
                    }
                }
                // update domains
                updates.domains = acctGetter.body.domains
            } else {
                updates.domains = [""]
            }

            // CRMs
            if (typeof acctRes.body[0].connections.salesforce !== "undefined" || typeof acctRes.body[0].connections.hubspot !== "undefined") {
                if (typeof acctRes.body[0].connections.salesforce !== "undefined") {
                    updates.crm = "Salesforce"
                    updates.salesforceAdmin = acctRes.body[0].connections.salesforce.admin.displayName
                    updates.salesforceEmail = acctRes.body[0].connections.salesforce.admin.email
                    updates.sandbox = acctRes.body[0].connections.salesforce.sandbox
                    if (acctRes.body[0].connections.salesforce.status === "Connected") {
                        updates.crmStatus = true
                    }
                    updates.instanceUrl = acctRes.body[0].connections.salesforce.instanceUrl
                } else {
                    updates.crm = "HubSpot CRM"
                    updates.salesforceAdmin = acctRes.body[0].connections.hubspot.admin.displayName
                    updates.salesforceEmail = acctRes.body[0].connections.hubspot.admin.email
                    if (acctRes.body[0].connections.hubspot.status === "Connected") {
                        updates.crmStatus = true
                    }
                    updates.instanceUrl = acctRes.body[0].connections.hubspot.domain
                    updates.appId = acctRes.body[0].connections.hubspot.appId
                }
            }

            // Integrations
            if (typeof acctRes.body[0].connections.slack !== "undefined") {
                updates.slackEmail = acctRes.body[0].connections.slack.admin.email
                if (acctRes.body[0].connections.slack.status === "Connected")
                    updates.slack = true
            }
            if (typeof acctRes.body[0].connections.gong !== "undefined") {
                updates.gongEmail = acctRes.body[0].connections.gong.email
                if (acctRes.body[0].connections.gong.status === "Connected")
                    updates.gong = true
            }
            if (acctRes.body[0].connections.intercom.length > 0) {
                updates.intercom = true
                updates.intercomEmail = acctRes.body[0].connections.intercom[0].admin.email
            }
        } // all account info

        //Active Products
        if (typeof sfRes.body[0] !== "undefined" && sfRes.ok) {
            updates.Customer__c = sfRes.body[0].Customer__c
            updates.Customer_Lifecycle_Phase__c = sfRes.body[0].Customer_Lifecycle_Phase__c
            updates.AM_Text__c = sfRes.body[0].AM_Text__c
            updates.CS_Text_Field__c = sfRes.body[0].CS_Text_Field__c
            updates.Active_Users__c = sfRes.body[0].Active_Users__c
            updates.Enabled_Users_CP__c = sfRes.body[0].Enabled_Users_CP__c
            updates.CPLicenses__c = sfRes.body[0].CPLicenses__c
            updates.Total_Calls_Initiated__c = sfRes.body[0].Total_Calls_Initiated__c
            updates.Total_Concierge_Calls_Connected__c = sfRes.body[0].Total_Concierge_Calls_Connected__c
            updates.MAU__c = sfRes.body[0].MAU__c
            updates.G2_Integration_Enabled__c = sfRes.body[0].G2_Integration_Enabled__c
        }

        //Tenant Object
        if (typeof tenantObj.body[0] !== "undefined" && tenantObj.ok) {
            updates.RATE_CONCIERGE_BOOK_28_BILLING__c = tenantObj.body[0].RATE_CONCIERGE_BOOK_28_BILLING__c
            updates.N_CONCIERGE_QUAL_28_BILLING__c = tenantObj.body[0].N_CONCIERGE_QUAL_28_BILLING__c
            updates.N_CONCIERGE_SUBMIT_FORM_28_BILLING__c = tenantObj.body[0].N_CONCIERGE_SUBMIT_FORM_28_BILLING__c
            updates.N_CONCIERGE_BOOK_7_BILLING__c = tenantObj.body[0].N_CONCIERGE_BOOK_7_BILLING__c
            updates.dau__c = tenantObj.body[0].dau__c
            updates.wau__c = tenantObj.body[0].wau__c
            updates.mau_concierge__c = tenantObj.body[0].mau_concierge__c
            updates.mau_distro__c = tenantObj.body[0].mau_distro__c
            updates.mau_handoff__c = tenantObj.body[0].mau_handoff__c
            updates.mau_instant_booker__c = tenantObj.body[0].mau_instant_booker__c
            updates.meetings_booked_last_7_days__c = tenantObj.body[0].meetings_booked_last_7_days__c
            updates.Distro_Routes_Last_7_Days__c = tenantObj.body[0].Distro_Routes_Last_7_Days__c
            updates.CP_Users__c = tenantObj.body[0].CP_Users__c
        }

        // MISC Connection Data
        if (typeof acctGetter.body.success !== "undefined") {
            updates.salesforceId = acctGetter.body.success.salesforceId
        }
        if (typeof acctRes.body[0] !== "undefined" && acctRes.ok) {
            updates.Id = acctRes.body[0].id
            updates.subDomain = acctRes.body[0].subDomain
            updates.logo = acctRes.body[0].logo
            updates.name = acctRes.body[0].name
        }

        updateObj(updates)

    }
    useEffect(() => {
        getConnections(tenant, false)
    }, [tenant])

    return [connections, products]

}