import type { RowPresetsDict } from './types';

export const usersPreset: RowPresetsDict = {
    cardName: 'User Details',
    presets: [
        {
            key: 'isAdmin',
            label: 'Super Admin'
        },
        {
            key: 'licences.meetings.status',
            label: 'Hot License'
        },
        {
            key: 'workspaces.default.$oid',
            label: 'Default Workspace'
        },
        {
            key: 'displayName',
            label: 'Name'
        },
        {
            key: 'email',
            label: 'Email'
        },
        {
            key: 'linkName',
            label: 'Personal Link'
        },
        {
            key: 'connections.salesforce.status',
            label: 'Salesforce Status'
        },
        {
            key: 'connections.salesforce._id',
            label: 'Salesforce ID'
        },
        {
            key: 'connections.salesforce.updatedAt.$date',
            label: 'Salesforce Updated'
        },
        {
            key: 'connections.hubspot.status',
            label: 'HubSpot Status'
        },
        {
            key: 'connections.hubspot.userId',
            label: 'HubSpot ID'
        },
        {
            key: 'connections.hubspot.updatedAt.$date',
            label: 'HubSpot Updated'
        },
        {
            key: 'connections.google.status',
            label: 'Google Status'
        },
        {
            key: 'connections.google.updatedAt.$date',
            label: 'Google Updated'
        },
        {
            key: 'connections.microsoft.status',
            label: 'Microsoft Connection'
        },
        {
            key: 'connections.microsoft.updatedAt.$date',
            label: 'Microsoft Updated'
        },
        {
            key: 'connections.zoom.status',
            label: 'Zoom Status'
        },
        {
            key: 'activeInQueue',
            label: 'Active in Queue'
        }
    ]
};