import Card from "../common/Card";
import SelectorMenu, { SelectorMenuButton } from "../common/SelectorMenu";

export default function AdminDashboard() {
    const actions: SelectorMenuButton[] = [{
        type: "",
        label: "Refresh prod tenant cache",
        value: "production"
    }, {
        type: "",
        label: "Refresh staging tenant cache",
        value: "staging"
    }]

    return (
        <div className="flex flex-col gap-4">
            Nothing here works fyi
            <Card title="Quick Actions">
                <SelectorMenu buttons={actions} />
            </Card>
            <Card title="Stats">
                <div className="shadow stats">
                    <div className="stat bg-neutral text-neutral-content">
                        <div className="stat-title">Total Page Views</div>
                        <div className="stat-value">89,400</div>
                        <div className="stat-desc">21% more than last month</div>
                    </div>
                    <div className="stat bg-neutral text-neutral-content">
                        <div className="stat-title">Total Page Views</div>
                        <div className="stat-value">89,400</div>
                        <div className="stat-desc">21% more than last month</div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
