import React from 'react'

export default function Datalist({ options, id }: { options: any[], id: string }) {
    return (
        <div>
            <datalist id={id}>
                {options.map((option, index) => (
                    <option key={`${index}-${option}`} value={option}>{option}</option>
                ))}
            </datalist>
        </div>
    )
}
