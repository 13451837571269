import { ApiResponse } from "./apiResponse";
import DOMPurify from "dompurify"

// When returning an ApiResponse, the response body is always sanitized. Error messages are not sanitized in some cases.
export async function ParseApiResponse(response: Response): Promise<ApiResponse> {
    const { status, headers, url } = response;

    if (status === 401) {
        return {
            ok: false,
            status,
            error: { message: "Unauthorized", context: `You do not have permission to execute that.` },
            body: {},
        };
    }

    if (headers.get("Content-Type") !== "application/json") {
        return {
            ok: false,
            status,
            error: { message: "Unexpected response received from the server", context: `Content-Type header is not application/json. URL: ${url}` },
            body: {},
        };
    }

    try {
        const body = await response.text();
        const safeBody = DOMPurify.sanitize(body)
        const bodyData = JSON.parse(safeBody);
        const finalBody = bodyData.data ?? bodyData;

        return {
            ok: status === 200,
            status,
            error: bodyData.error,
            body: finalBody,
        };
    } catch (error) {
        return {
            ok: false,
            status,
            error: { message: "Failed to deserialize the response body as JSON", context: `${error}` },
            body: {},
        };
    }
}
