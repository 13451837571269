import ReactJsonView from "../../common/ReactJsonView";

function QueryResult({ json }: { json: Record<string, any> }) {
  return (
    <div>
      <pre className="bg-neutral p-4 break-all whitespace-pre-wrap rounded-box">
        <ReactJsonView json={json || {}} />
      </pre>
    </div>
  );
}

export default QueryResult;
