import Button from "../../../common/Button";
import Card from "../../../common/Card";
import { PinCardProps } from "./types";

export default function PinCard({ title, content, buttons, dismiss, dismissable }: PinCardProps) {
  return (
    <Card title={title}>
      {dismissable && dismiss && (
        <Button className="btn btn-ghost btn-square btn-sm absolute top-2 right-2" onClick={dismiss}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </Button>
      )}
      <div className="flex flex-col gap-2">
        <div className="break-words whitespace-none">{content}</div>
        {buttons && buttons.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {buttons.map((button, index) => (
              <div className="flex-grow" key={`pin-card-button-${index}`}>
                {button.onClick && (
                  <Button className={`btn-block btn ${button.className || ""}`} type={button.color || "primary"} onClick={button.onClick} disabled={button.disabled}>
                    {button.label}
                  </Button>
                )}
                {button.link && !button.onClick && (
                  <a href={button.link} target="_blank" rel="noreferrer">
                    <Button key={`pin-card-button-link-${index}`} className={`btn-block btn ${button.className || ""}`} type={button.color || "primary"} disabled={button.disabled}>
                      {button.label}
                    </Button>
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
}
