import { useEffect, useRef, useState } from "react";
import { FastSearchTenants } from "../../endpoints/fastSearchTenants";
import Button from "../Button";
import Datalist from "../Datalist";
import { useKeyPress } from "../useKeyPress";
import TenantSelectMenu from "./TenantSelectMenu";
import { debounce } from "lodash";
import { tenantBaseInfo, useTenantInfo } from "./TenantInfoProvider";
import { useToast } from "../Notifications/ToastProvider";
import { SearchTenants } from "../../endpoints/searchTenants";
import { useUserState } from "../Auth/AuthContext";
import { useLocation } from "react-router-dom";

interface TenantProps {
  skipCache?: boolean
  setSkipCache?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TenantInput({ skipCache, setSkipCache }: TenantProps) {
  const { addToast } = useToast();
  const { state } = useUserState();
  const { getTenant, setTenant } = useTenantInfo();

  if (typeof skipCache === "undefined") {
    skipCache = false;
  }

  const setSelectedTenant = (tenant: Partial<tenantBaseInfo>, resetSearch: boolean = true) => {
    setTenant(tenant as tenantBaseInfo);
    if (resetSearch) setTenantSearch(tenant.id || ("" as string));
  };

  const [tenants, setTenants] = useState<tenantBaseInfo[]>([]);
  const [tenantSearch, setTenantSearch] = useState("");

  const [searchLoading, setSearchLoading] = useState(false);
  const searchTenant = async (search: string, noCache: boolean = false) => {
    search = search.toLowerCase();
    if (search === getTenant().id || search === "") return;
    setSearchLoading(true);
    setSelectedTenant({}, false);
    const res = await SearchTenants(search, noCache);
    if (!res.ok) {
      setSearchLoading(false);
      return addToast({
        title: "Error",
        message: res.error.message,
        context: res.error.context,
        type: "error",
      });
    }

    const newTenants: tenantBaseInfo[] = res.body;
    setTenants(newTenants);
    newTenants.sort((a, b) => a.environment.localeCompare(b.environment));

    // Find the exact matching tenant
    const exactMatch = newTenants.find(tenant => tenant.id.toLowerCase() === search.toLowerCase());

    // If an exact match is found, use it; otherwise, use the first tenant in the sorted list
    const selected = exactMatch || newTenants[0];

    if (selected) {
      setTenantSearch(selected.id);
      setSelectedTenant(selected, false);
    } else {
      setSelectedTenant({}, false);
    }

    if (typeof setSkipCache !== "undefined")
      setSkipCache(false); // sets hallpass skipcache value

    setSearchLoading(false);
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const enterPressed = useKeyPress("Enter");
  const [timeout, setTimeoutState] = useState(false);
  useEffect(() => {
    if (!timeout && !searchLoading && enterPressed && tenantSearch && searchInputRef.current === document.activeElement) {
      searchTenant(tenantSearch, skipCache);
      setTimeoutState(true);
      const timer = setTimeout(() => setTimeoutState(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [enterPressed]);

  const trimSpace = (str: string): string => str.replace(/\s/g, '');

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = trimSpace(e.target.value)
    setTenantSearch(searchText);
    if (searchText && searchText.length > 3 && suggestList.includes(searchText) && !timeout) {
      searchTenant(searchText);
      setTimeout(() => setTimeoutState(false), 1000);
    }
    if (searchText === "") {
      setTenant({} as tenantBaseInfo);
    }
  };
  const onSearch = () => {
    searchTenant(tenantSearch, skipCache);
  };

  const [suggestList, setSuggestList] = useState<string[]>([""]);
  const debounceFastSearch = useRef(
    debounce(
      (search: string) => {
        FastSearchTenants(search).then((res) => {
          if (res.ok) setSuggestList(res.body.map((t: tenantBaseInfo) => t.id));
        });
      },
      500,
      { leading: false, trailing: true }
    )
  ).current;

  useEffect(() => {
    debounceFastSearch(tenantSearch);
  }, [tenantSearch, debounceFastSearch]);

  // Parse query params
  const location = useLocation();
  useEffect(() => {
    if (state.isLoadingAuth || !state.isAuthenticated) return;

    const query = new URLSearchParams(location.search);
    if (query.get("id")) {
      const tenantId = query.get("id") as string;
      setTenantSearch(tenantId);
      searchTenant(tenantId);
    }
  }, [state.isLoadingAuth, state.isAuthenticated]);

  // Remove tenant name if changing pages
  const url = location.pathname.split('/').pop();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("id")) {
      const tenantId = query.get("id") as string;
      setTenantSearch(tenantId);
      searchTenant(tenantId);
    } else {
      setTenantSearch("");
      setTenant({} as tenantBaseInfo);
    }
  }, [url]);

  // TAB auto-completion
  const tabPressed = useKeyPress("Tab");
  const [spam, setSpam] = useState(false);
  useEffect(() => {
    if (!spam && !searchLoading && tabPressed && searchInputRef.current === document.activeElement && searchInputRef.current !== null && suggestList.length > 0) {
      const start: number = searchInputRef.current.selectionStart!
      setTenantSearch(suggestList[0] + searchInputRef.current.value.slice(start));
      setSpam(true);
      const timer = setTimeout(() => setSpam(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [tabPressed]);

  searchInputRef.current?.addEventListener('keydown', e => {
    if (e.key === "Tab") {
      e.preventDefault();
    }
  })

  return (
    <div className="form-control w-full flex flex-col gap-2">
      {/* Input field to search for tenant */}
      <div>
        <label className="input-group w-full flex shadow-md rounded-box">
          <span className="bg-base-300 text-base-content">Tenant ID</span>
          <input
            ref={searchInputRef}
            type="text"
            list="tenant-auto-complete"
            placeholder="floatingapps.com"
            value={tenantSearch || getTenant().id || ""}
            onChange={handleOnChange}
            className="input input-bordered flex-grow bg-neutral text-neutral-content border-0 placeholder-neutral-content placeholder-opacity-50"
          />
          {getTenant().environment && (
            <span className="bg-base-300 text-base-content">
              @ {getTenant().crmType || "Unknown CRM"} ({getTenant().environment})
            </span>
          )}
          <Button className={`shadow-md btn ${searchLoading ? "loading" : ""}`} type="primary" disabled={searchLoading} onClick={onSearch}>
            search
          </Button>
        </label>
      </div>
      {/* Dropdown to select tenant if multiple tenants exist */}
      {tenants.length > 1 && (
        <div className="flex justify-center">
          <TenantSelectMenu tenants={tenants} selectedTenant={getTenant()} setSelectedTenant={setSelectedTenant} />
        </div>
      )}
      {/* Tenant auto-complete datalist */}
      <Datalist id="tenant-auto-complete" options={suggestList} />
    </div>
  );
}
