import { equalsOperator, operator } from "../types";

export type presetField = {
    fieldValues: {
        value: string,
        operator: operator,
        name: string
    },
    label: string,
    placeholder: string
}

export const collectionFieldMap: {
    [key: string]: {
        fields: Array<presetField>,
    }
} = {
    // Reports
    'booker.reports': {
        fields: [
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: '_id.$oid'
                },
                label: 'Report ID',
                placeholder: '57895394e4b0e79cec798b03'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'prospectInfo.email'
                },
                label: 'Prospect Email',
                placeholder: 'email@prospect.com'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'crmId'
                },
                label: 'CRM ID',
                placeholder: '00U5Y00000rpDWuUAM'
            }
        ]
    },
    // Routes
    'booker.routes': {
        fields: [
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: '_id.$oid'
                },
                label: 'Route ID',
                placeholder: '57895394e4b0e79cec798b03'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'prospect.email'
                },
                label: 'Prospect Email',
                placeholder: 'email@prospect.com'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'prospect._id'
                },
                label: 'Prospect ID',
                placeholder: '003f200002ovFG2AAM'
            }
        ]
    },
    // Route debug
    'booker.routes.debug': {
        fields: [
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: '_id.$oid'
                },
                label: 'Route ID',
                placeholder: '57895394e4b0e79cec798b03'
            }
        ]
    },
    // Users
    users: {
        fields: [
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: '_id.$oid'
                },
                label: 'User ID',
                placeholder: '57895394e4b0e79cec798b03'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'email'
                },
                label: 'Email',
                placeholder: 'email@user.com'
            },
            {
                fieldValues: {
                    value: '',
                    operator: equalsOperator,
                    name: 'connections.salesforce._id'
                },
                label: 'SFDC ID',
                placeholder: '00U5Y00000rpDWuUAM'
            }
        ]
     },
     'distro.logs': {
         fields: [
             {
                 fieldValues: {
                     value: '',
                     operator: equalsOperator,
                     name: 'groupId.$oid'
                 },
                 label: 'Distro Log ID',
                 placeholder: '57895394e4b0e79cec798b03'
             },
             {
                 fieldValues: {
                     value: '',
                     operator: equalsOperator,
                     name: 'crmObject.Email'
                 },
                 label: 'Email',
                 placeholder: 'email@user.com'
             },
             {
                 fieldValues: {
                     value: '',
                     operator: equalsOperator,
                     name: 'crmId'
                 },
                 label: 'SFDC ID',
                 placeholder: '00Q5Y00000rpDWuUAM'
             }
         ]
    }
};