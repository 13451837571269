import { GetTenantAdminUser } from "../../endpoints/getTenantAdmin";
import { tenantBaseInfo, useTenantInfo } from "../../common/TenanInput/TenantInfoProvider";
import { useEffect, useState } from "react";

interface OpenExternalProps {
  url: string;
  displayText?: string;
  tooltip?: string;
  showSVG?: boolean;
  impersonate?: boolean;
  error?: string[];
}

export default function OpenExternal({ url, displayText, tooltip, showSVG = true, impersonate = false, error }: OpenExternalProps): JSX.Element {
  const { getTenant } = useTenantInfo();
  const tenant: tenantBaseInfo = getTenant()
  const hasTooltip: boolean = typeof tooltip !== "undefined";

  const [adminEmail, setAdminEmail] = useState("");
  useEffect(() => {
    if (!tenant.id) return;
    GetTenantAdminUser(tenant.id, tenant.environment).then((res) => {
      if (res.ok && res.body) {
        setAdminEmail(res.body);
      } else {
        setAdminEmail("");
      }
    });
  }, [tenant.id, tenant.environment]);
  const impersonateUrl = `https://api${tenant.cluster === "canary" ? ".na" : ""}.chilipiper.${tenant.environment === "staging" ? "io" : "com"}/impersonate?tenant=${tenant.id}&email=${adminEmail}`.replace("+", "%2B");

  const handleClick: () => void = () => {
    const windowName = "myWindow";
    let win1: Window | null;
    if (impersonate) {
      win1 = window.open(impersonateUrl, "_blank");
      if (win1) {
        setTimeout(() => {
          window.open(url, windowName);
          win1?.location.replace(url);
        }, 700);
      }
      return;
    }
    window.open(url, "_blank");
  }

  const link: JSX.Element = (
    <div className={`text-left ${error && error.length > 0 ? 'dropdown dropdown-right dropdown-hover dropdown-end' : ''}`}>
      <button className="outline-none mr-1 mb-1 px-3 py-1 bg-transprent focus:outline-none" onClick={handleClick}>
        {displayText}
        {showSVG && (
          <svg className="fill-black ml-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
          </svg>
        )}
      </button>
      {error && error.length > 0 && (
        <div className="dropdown-content p-2 pl-4 mb-2 shadow-md rounded-box w-[400px] whitespace-pre-line bg-slate-700 text-white">
          <ul className="list-disc">
          {error.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        </div>
      )}
    </div>
  )

  return (
    <>
      {hasTooltip ? (
        <div className="tooltip tooltip-info" data-tip={tooltip}>{link}</div>
      ) : (
        <div>{link}</div>
      )}
    </>
  )
}