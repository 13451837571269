import { ApiResponse } from "./apiResponse";
import { ParseApiResponse } from "./parseApiResponse";

export async function postEndpointQuery(tenantID: string, tenantEnv: string,value: string, skipCache = false): Promise<ApiResponse> {
  if (!tenantID || !value) {
    return {
      ok: false,
      status: 400,
      error: { message: "Failed to query TenantID or Value was blank", context: "" },
      body: {},
    };
  }
  const url = `https://api.chilipiper.${tenantEnv === "staging" ? "io" : "com"}/api/v1/booker/route/debug/${value}?skipCache=${skipCache}&env=${tenantEnv}&tenantId=${tenantID}`;
  try {
    const res = await fetch(url, {
      credentials: "include",
      method: "GET",
    });
    return ParseApiResponse(res);
  } catch (error) {
    return {
      ok: false,
      status: 500,
      error: { message: "Failed to query route information", context: `${error}` },
      body: {},
    };
  }
}
