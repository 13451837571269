import { useEffect } from "react";
import { useToast } from "../Notifications/ToastProvider";
import { useUserState } from "./AuthContext";
import { CheckSession } from "./backendCheckSession";
import { GetUserProfile } from "./backendUserProfile";
import { UserStateInterface } from "./AuthContext";

interface ErrorInfo {
  message: string;
  context: string;
}

const useSilentAuthCheck = () => {
  const { addToast } = useToast();
  const { state, setState } = useUserState();

  useEffect(() => {
    const handleLoginError = (error: ErrorInfo) => {
      addToast({
        title: "Login Error",
        message: error.message,
        context: error.context,
        type: "error",
      });
      setState({
        ...state,
        isLoadingAuth: false,
      });
    };

    const fetchUserProfile = async () => {
      try {
        const data = await GetUserProfile();
        if (data.ok) {
          setState({
            ...state,
            isAuthenticated: true,
            name: data.body?.name,
            email: data.body?.email,
            picture: data.body?.picture,
            themes: data.body?.themes ? Object.values(data.body.themes) : [],
            isLoadingAuth: false,
          });
        } else {
          handleLoginError(data.error);
        }
      } catch (error) {
        handleLoginError({ message: "Failed to fetch user profile", context: "Network error" });
      }
    };

    const checkUserSession = async () => {
      try {
        setState({
          ...state,
          isLoadingAuth: true,
        });
        const sessionExists = await CheckSession();
        if (sessionExists && !state.isAuthenticated) {
          await fetchUserProfile();
        } else {
          setState({
            ...state,
            isLoadingAuth: false,
          });
        }
      } catch (error) {
        handleLoginError({ message: "Failed to check session", context: "Network error" });
      }
    };

    checkUserSession();
  }, [state.isAuthenticated]);
};

export default useSilentAuthCheck;
