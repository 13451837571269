import { createContext, useState, useContext } from "react";
import { Theme } from "../ThemeSwitch/ThemeContext";

export interface UserStateInterface {
  settings?: Record<string, any> | undefined;
  isAuthenticated: boolean;
  isLoadingAuth: boolean;
  picture?: string;
  email?: string;
  name?: string;
  themes?: Theme[];
  skipTracking?: boolean;
}

const UserStateContext = createContext({
  state: {} as Partial<UserStateInterface>,
  setState: (state: Partial<UserStateInterface>) => {
    console.error("state is not defined");
  },
});

export const UserStateProvider = ({ children, value = {} as UserStateInterface }: { children: React.ReactNode; value?: Partial<UserStateInterface> }) => {
  const [state, setState] = useState(value);
  const setStateRemote = (newState: Partial<UserStateInterface>) => {
    if (newState.isAuthenticated === true) newState.isLoadingAuth = false;
    setState(newState);
    // Update remote config
    // TODO
  };

  return <UserStateContext.Provider value={{ state, setState: setStateRemote }}>{children}</UserStateContext.Provider>;
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserStateContext");
  }
  return context;
};
