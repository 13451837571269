import { ApiResponse } from "./apiResponse";
import { TENANT_ADMIN_QUERY } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

export async function GetTenantAdminUser(tenantID: string, env: string): Promise<ApiResponse> {
    if (!tenantID || tenantID.length === 0 || !env || env.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Failed to get collections, TenantID or Environment was blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${TENANT_ADMIN_QUERY.replace("%id", tenantID)}?env=${env}&skipCache=true`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to get collections", context: `${error}` },
            body: {},
        }
    }
}