import { createContext, useState, useContext } from "react";

export const DefaultThemes: Record<string, Theme> = {
  light2: { name: "Light", value: "emerald", disabled: false, isDark: false, default: true },
  light: { name: "Spicy Caramel", value: "spicy-caramel", disabled: false, isDark: false, default: true },
  dark: { name: "Ghost Pepper", value: "ghost-pepper", disabled: false, isDark: true, default: true },
  cyberpunk: { name: "Cyberpunk", value: "cyberpunk", disabled: false, isDark: false, default: true },
  blackout: { name: "Blackout", value: "blackout", disabled: false, isDark: true, default: true },
  synthwave: { name: "Purple Spice", value: "synthwave", disabled: false, isDark: true, default: true },
  cupcake: { name: "Sweet Chili", value: "cupcake", disabled: false, isDark: false, default: true },
  bumblebee: { name: "Spiced Honey", value: "bumblebee", disabled: false, isDark: false, default: true },
  pastel: { name: "Unicorn", value: "pastel", disabled: false, isDark: true, default: true },
  flat: { name: "Flat", value: "flat", disabled: false, isDark: true, default: true },
  matrix: { name: "Matrix", value: "matrix", disabled: false, isDark: true, default: true },
  fireui: { name: "Fire UI", value: "fireui", disabled: false, isDark: false, default: true },
  pokemon: { name: "Pokemon", value: "pokemon", disabled: false, isDark: false, default: true },
  stpatricks: { name: "St. Patrick's", value: "stpatricks", disabled: false, isDark: false, default: true },
  proud: { name: "Proud", value: "proud", disabled: false, isDark: false, default: true },
};

export interface Theme {
  disabled: boolean;
  isDark: boolean;
  value: string;
  name: string;
  found?: boolean;
  default?: boolean;
}

const ThemeStateContext = createContext({
  theme: {} as Partial<Theme>,
  setTheme: (theme: Partial<Theme>) => {
    console.error("state is not defined");
  },
});

export const ThemeStateProvider = ({ children, value = DefaultThemes["dark"] as Partial<Theme> }: { children: React.ReactNode; value?: Partial<Theme> }) => {
  const [theme, setThemeState] = useState(value);
  const setTheme = (theme: Partial<Theme>) => {
    setThemeState(theme);
    window.localStorage.setItem("theme", JSON.stringify(theme));
  };

  return <ThemeStateContext.Provider value={{ theme, setTheme }}>{children}</ThemeStateContext.Provider>;
};

export const useThemeState = () => {
  const context = useContext(ThemeStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserStateContext");
  }
  return context;
};
