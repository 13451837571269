import { USER_IS_ADMIN_CHECK } from "./endpoints";

export async function adminCheck(): Promise<boolean> {
    if (import.meta.env.NODE_ENV === "development") {
        return true
    }

    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${USER_IS_ADMIN_CHECK}`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status === 200) {
        return true;
    }
    return false;
}