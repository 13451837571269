export const setSessionValue = (value: string): void => {
  const extensionIds = ['kamlmkjcchfkadlnkdekdhmdoonnonlm', 'mbnpicielnjndbeelploampjnlllcpfg'];

  extensionIds.forEach((extensionId) => {
    chrome.runtime.sendMessage(extensionId, { action: 'storeData', data: value }, (response) => {
      if (chrome.runtime.lastError) {
        console.error(chrome.runtime.lastError.message, extensionId);
      }
    });
  });
};