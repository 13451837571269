import Button from '../../../common/Button'
import DatalistInput from '../../../common/DatalistInput'

export default function AdvancedCollectionSelector({ collections, selectedCollection, setCollection, toggleAdvanced }: { collections: string[], selectedCollection: string, setCollection: (collection: string) => void, toggleAdvanced: () => void }) {
    return (
        <div>
            <div className="form-control flex flex-row gap-0">
                <DatalistInput id="collections-selector" options={collections} placeholder="Collection" className="w-full rounded-r-none" value={selectedCollection} onChange={setCollection} />
                <Button className="rounded-l-none py-0 text-2xl" onClick={toggleAdvanced}>&#215;</Button>
            </div>
        </div>
    )
}
