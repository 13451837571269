import StatusIcon from "./StatusIcon"

import { useEffect, useState } from "react";
import LoadingSplash from '../../common/LoadingSplash';
import { useTenantInfo } from "../../common/TenanInput/TenantInfoProvider";

import { Connections } from "./HPConnections";
import OpenExternal from "./OpenExternal";

export default function HallConnections({ connections }: { connections: Connections }) {

    const { getTenant } = useTenantInfo();
    const tenant = getTenant().id

    const [loaded, setLoaded] = useState(false);
    const [sfId, setSfId] = useState(connections.salesforceId as string)
    useEffect(() => {
        setLoaded(true)
        setSfId(connections.salesforceId as string)
    }, [getTenant, tenant, connections.salesforceId])

    let SFstatus = false
    if (connections.salesforceId !== null && connections.salesforceId !== "") SFstatus = true

    if (!loaded) return <LoadingSplash />;
    return (
        <div className="overflow-x-auto w-full">
            <table className="table table-zebra w-full p-0">
                <thead>
                    <tr className="text-center">
                        <th className="text-left">Metric</th>
                        <th className=" w-screen">Value</th>
                        <th className="text-right">Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Calendar</div>
                            </div>
                        </td>
                        <td>
                            {connections.calendar}
                        </td>
                        <th>
                            <StatusIcon metric="calendarStatus" status={connections.calendarStatus} critical={true} />
                        </th>
                    </tr>
                    {connections.calendar === "Google" && (
                        <tr className="text-center">
                            <td>
                                <div className="text-left">
                                    <div className="font-bold">Global GCal</div>
                                </div>
                            </td>
                            <td>
                                {String(connections.calendarGlobal)}
                            </td>

                            <th>
                                <StatusIcon metric="calendarGlobal" status={connections.calendarGlobal} />
                            </th>
                        </tr>
                    )}
                    {connections.crm !== "" && (
                        <tr className="text-center">
                            <td>
                                <div className="text-left">
                                    <div className="font-bold">CRM</div>
                                </div>
                            </td>
                            <td>
                                {connections.crm}
                            </td>

                            <td>
                                <StatusIcon metric="crm" status={connections.crmStatus} critical={true} />
                            </td>
                        </tr>
                    )}
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">CRM Connection</div>
                            </div>
                        </td>
                        <td>
                            {connections.crmStatus ? (
                                "Connected"
                            ) : (
                                "Disconnected"
                            )}

                        </td>
                        <th>
                            <StatusIcon metric="crmStatus" status={connections.crmStatus} critical={true} />
                        </th>
                    </tr>
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Integration User</div>
                                <span className="badge badge-ghost badge-sm">{connections.crm}</span>
                            </div>
                        </td>
                        <td>
                            {connections.salesforceAdmin}<br />
                            <span className="badge badge-ghost badge-sm">{connections.salesforceEmail}</span>
                        </td>
                        <th>
                            <StatusIcon metric="crmStatus" status={connections.crmStatus} />
                        </th>
                    </tr>
                    {sfId !== "" && (
                        <tr className="text-center">
                            <td>
                                <div className="text-left">
                                    <div className="font-bold">Internal SF Account Id</div>
                                </div>
                            </td>

                            <td>
                                <OpenExternal url={`https://chilipiper.lightning.force.com/lightning/r/Account/${connections.salesforceId}/view`} tooltip="Browse to Salesforce Account" displayText={sfId} />
                            </td>
                            <th> <StatusIcon metric="sfid" status={SFstatus} />
                            </th>
                        </tr>
                    )}
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Instance URL</div>
                            </div>
                        </td>

                        <td>
                            {connections.instanceUrl}<br />
                        </td>
                        <th><StatusIcon metric="instanceUrl" status={true} />
                        </th>
                    </tr>
                    {connections.crm === "HubSpot CRM" && (
                        <tr className="text-center">
                            <td>
                                <div className="text-left">
                                    <div className="font-bold">AppId</div>
                                </div>
                            </td>

                            <td>
                                {connections.appId}<br />
                            </td>
                            <th><StatusIcon metric="appId" status={true} />
                            </th>
                        </tr>
                    )}
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Gong</div>
                            </div>
                        </td>

                        <td>
                            {connections.gongEmail !== "" && (
                                <span className="badge badge-ghost badge-sm">{connections.gongEmail}</span>
                            )}
                        </td>
                        <th><StatusIcon metric="gong" status={connections.gong} />
                        </th>
                    </tr>
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Slack</div>
                            </div>
                        </td>

                        <td>
                            {connections.slackEmail !== "" && (
                                <span className="badge badge-ghost badge-sm">{connections.slackEmail}</span>
                            )}
                        </td>
                        <th><StatusIcon metric="slack" status={connections.slack} />
                        </th>
                    </tr>
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">Intercom</div>
                            </div>
                        </td>

                        <td>
                            {connections.intercomEmail !== "" && (
                                <span className="badge badge-ghost badge-sm">{connections.intercomEmail}</span>
                            )}
                        </td>
                        <th>
                            <StatusIcon metric="intercom" status={connections.intercom} />
                        </th>
                    </tr>
                    <tr className="text-center">
                        <td>
                            <div className="text-left">
                                <div className="font-bold">G2 Integration</div>
                            </div>
                        </td>

                        <td>
                        </td>
                        <th>
                            <StatusIcon metric="G2_Integration_Enabled__c" status={connections.G2_Integration_Enabled__c} />
                        </th>
                    </tr>
                </tbody>

                {/* Footer */}
                <tfoot>
                    <tr className="text-center">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th> </th>
                    </tr>
                </tfoot>

            </table>
        </div>

    )


}