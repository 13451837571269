import SelectorMenu, { SelectorMenuButton } from "../../../common/SelectorMenu";
import { collectionPresets } from ".././presets/collectionPresets";

export default function QuickCollectionSelector({ collection, setCollection, toggleAdvanced }: { collection: string; setCollection: (collection: string) => void; toggleAdvanced: () => void }) {
  const menuButtons: SelectorMenuButton[] = collectionPresets.map((c, i) => ({
    type: "neutral",
    value: c.name,
    label: c.label,
    onClick: (v: string) => setCollection(v),
  }));
  menuButtons.push({
    value: "",
    type: "neutral",
    label: "Advanced",
    onClick: toggleAdvanced,
  });

  return (
    <div className="w-full flex justify-center">
      <SelectorMenu buttons={menuButtons} selectorName="Collection" selectedValue={collection} selectedClass="bg-primary text-primary-content" />
    </div>
  );
}
