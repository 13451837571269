import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Card from "../common/Card";
import Button from "../common/Button";
import StatusIcon from "../common/StatusIcon";
import { StatusIconCheck, StatusIconCross } from "../common/StatusIconPresets";
import { useTenantInfo, GetCachedTenant } from "../common/TenanInput/TenantInfoProvider";
import TenantInput from "../common/TenanInput/TenantInput";
import LoadingSplash from '../common/LoadingSplash';
import { PostSFQuery } from "../endpoints/getSFFields";
import ReactJsonView from "../common/ReactJsonView";
import { useToast } from "../common/Notifications/ToastProvider";
import { ApiResponse } from "../endpoints/apiResponse";
import { SearchTenants } from "../endpoints/searchTenants";
import { QueryCollection } from "../endpoints/queryCollection";
import { TENANT_HUBSPOT_QUERY_FMT } from "../endpoints/endpoints";
import { ParseApiResponse } from "../endpoints/parseApiResponse";

// Pins
import PinsContainer from "../PageComponents/Query/Pins/PinsContainer";
import ImpersonationContainer from "../PageComponents/Query/ImpersonationContainer";
import { useSelectionEventListener } from "../common/useSelectionPinCheckListener";
import { FastAddPinCheck } from "../PageComponents/Query/presets/pinPresets/pinActionsConstructor";
import { AddPinFromSearch } from "../PageComponents/Query/presets/pinPresets/GetPinAction";


export default function Hubspot() {
    const { addToast } = useToast();
    const { getTenant } = useTenantInfo();
    const tenant = getTenant().id;
    const env = getTenant().environment;
    const [query, setQuery] = useState('');
    const [result, setResult] = useState<any>([]);
    const [skipCache, setSkipCache] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const location = useLocation();
    const acocuntInfo = SearchTenants(tenant, false);

    const runQuery = async () => {
        // 1. Get the email address of the sync user
        const acc = await SearchTenants(tenant, false);
        const adminEmail = acc.body[0].connections.hubspot.admin.email


        // 2. Get the access token from the hubspot.credentials collection
        const query = `{'userEmail':'${adminEmail}'}`;
        const queryData = {
            query: query,
            count: false,
            limit: 1,
            order: -1,
        };
        const token = await QueryCollection(
            tenant,
            env,
            'hubspot.credentials',
            JSON.stringify(queryData),
            true
        );
        // call the HubSpot API and insert the details from the admin
        const url = `https://api.hubapi.com/contacts/v1/contact/email/clivingnstonav@gmail.com/profile`;
        try {
          const response = await fetch(url, {
            credentials: "include",
            method: "GET",
            headers: { "Authorization": "Bearer " + token.body.entries[0].accessToken}
          });
          
          const data = await response.json(); // Parse the response body as JSON
          
          console.log("Token: " + token.body.entries[0].accessToken);
          console.log(data);
          setResult(data);
        } catch (error) {
          return {
            ok: false,
            status: 500,
            error: { message: "Failed to get HS details", context: `${error}` },
            body: {},
          };
        }
    };


    // update url with tenant id
    useEffect(() => {
        const urlparam = new URLSearchParams(location.search);
        if (tenant) {
            urlparam.set("id", tenant);
        }
        window.history.replaceState({}, "", `${location.pathname}?${urlparam.toString()}`);
        return () => setSkipCache(false);
    }, [location.search, location.pathname, tenant]);

    // Add pins based on selected text
    useSelectionEventListener((selection: string) => {
        if (!FastAddPinCheck(selection)) return;
        const tenant = GetCachedTenant();
        if (!tenant.id) return;
        AddPinFromSearch(selection, tenant);
    });

    return (
        <>
            <div className="flex gap-4">
                <div className="flex-grow flex flex-col gap-4">
                    <Card title="Select a tenant" titleClass="font-bold text-base-content text-opacity-50">
                        {tenant ? <StatusIcon color={StatusIconCheck.color} icon={StatusIconCheck.icon} /> : <StatusIcon color={StatusIconCross.color} icon={StatusIconCross.icon} />}
                        <TenantInput skipCache={skipCache} setSkipCache={setSkipCache} />
                    </Card>
                    {tenant && (
                        <>
                            <Card title="HubSpot API's">
                                <form>
                                    <div>
                                        <label className="input-group w-full flex shadow-md rounded-box">
                                            <span className="bg-base-300 text-base-content">Email</span>
                                            <input
                                                type="text"
                                                className="input input-bordered flex-grow bg-neutral text-neutral-content border-0 placeholder-neutral-content placeholder-opacity-50"
                                                placeholder="User Email Address"
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <br></br>
                                        <label className="input-group w-full flex shadow-md rounded-box">
                                            <span className="bg-base-300 text-base-content">Access Token</span>
                                            <input
                                                type="text"
                                                className="input input-bordered flex-grow bg-neutral text-neutral-content border-0 placeholder-neutral-content placeholder-opacity-50"
                                                placeholder="Token from hubspot.credentials collection"
                                            />
                                        </label>
                                        <div id="tokenResult"></div>
                                        <Button onClick={runQuery} className={`shadow-md`}>
                                            Run Query
                                        </Button>
                                    </div>
                                </form>
                            </Card>
                            <Card title="Results">
                                {!loaded && <LoadingSplash />}
                                <pre className="bg-neutral p-4 break-all whitespace-pre-wrap rounded-box">
                                    <ReactJsonView json={result || {}} />
                                </pre>
                            </Card>
                        </>
                    )}
                </div>
                <div style={{ width: "400px" }} className="relative flex flex-col gap-4 overflow-visible">
                    <div className="sticky top-4 flex flex-col gap-4 pb-4 overflow-visible">
                        {getTenant().id && <ImpersonationContainer tenant={getTenant()} />}
                        <PinsContainer />
                    </div>
                </div>
            </div>
        </>
    );
};