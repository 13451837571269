import type { RowPresetsDict } from './types';

export const workspaces: RowPresetsDict = {
    cardName: 'Workspace Details',
    presets: [
        {
            key: 'name',
            label: 'Name'
        },
        {
            key: 'link',
            label: 'Link'
        },
        {
            key: 'active',
            label: 'Active'
        },
        {
            key: 'deleted',
            label: 'Deleted'
        },
        {
            key: 'integrations',
            label: 'Integrations'
        },
        {
            key: 'workspaceType',
            label: 'Workspace Type'
        }
    ]
};