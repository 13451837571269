import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';

const container = document.getElementById('root') as Element | DocumentFragment;
const root = createRoot(container);
root.render(
<GoogleOAuthProvider clientId={import.meta.env.REACT_APP_GOOGLE_CLIENT_ID || ""}>
<React.StrictMode>
  <App />
</React.StrictMode>
</GoogleOAuthProvider>
);
