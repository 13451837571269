import { useToast } from "../../../common/Notifications/ToastProvider";
import PinCard from "./PinCard";

export default function DefaultPinCard() {
  const { addToast } = useToast();
  return (
    <PinCard
      id={-1}
      dismissable={false}
      timestamp={Date.now()}
      key={"preset-pin-0"}
      title="Pinned Queries"
      content="You can pin some values in order to run additional contextual queries!"
      buttons={[
        {
          label: "Sick!",
          color: "secondary",
          className: "border-transparent",
          onClick: () => {
            addToast({ type: "success", title: "I know!", message: "Glad you like it!" });
          },
        },
      ]}
    />
  );
}
