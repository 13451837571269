import type { RowPresetsDict } from './types';

export const routeDetailsPreset: RowPresetsDict = {
    cardName: 'Route Details',
    presets: [
        {
            key: 'creationDate',
            label: 'Creation Date'
        },
        {
            key: 'router.name',
            label: 'Router'
        },
        {
            key: 'router._id.$oid',
            label: 'Router ID'
        },
        {
            key: 'queues.booking.$oid',
            label: 'Queue ID'
        },
        {
            key: 'prospectCreatedByChili',
            label: 'Created by Chili'
        },
        {
            key: 'error',
            label: 'Error'
        }
    ]
};