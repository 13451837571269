import { ApiResponse } from "./apiResponse";
import { FAST_SEARCH_TENANTS_ENDPOINT } from "./endpoints";
import { ParseApiResponse } from "./parseApiResponse";

// Does a tenant search using the cached data only
export async function FastSearchTenants(search: string): Promise<ApiResponse> {
    if (!search || search.length === 0) {
        return {
            ok: false,
            status: 400,
            error: { message: "Tenant ID cannot be left blank", context: "" },
            body: {},
        }
    }
    const url = `${import.meta.env.REACT_APP_BACKEND_API_URI}${FAST_SEARCH_TENANTS_ENDPOINT}?search=${search}`;
    try {
        const res = await fetch(url, { credentials: "include" });
        return ParseApiResponse(res)
    } catch (error) {
        return {
            ok: false,
            status: 500,
            error: { message: "Failed to search for a tenant", context: `${error}` },
            body: {},
        }
    }
}