import { marketingFormsPresets } from "./marketingForms";
import { prospectReportsPreset, prospectRoutesPreset } from "./prospectPresets";
import { reportDetailsPreset } from "./reportDetails";
import { routeDetailsPreset } from "./routeDetails";
import { salesforceEvents } from "./salesforceEvents";
import { distroDetailsPreset } from "./distroDetails";
import type { RowPreset, RowPresetsDict } from "./types";
import { usersPreset } from "./users";
import { workspaces } from "./workspaces";

export const allRowPresets: Record<string, RowPresetsDict> = {};

// Global
const globalRowPresets: RowPreset[] = [
  {
    key: "_id.$oid",
    label: "Document ID",
  },
];
allRowPresets.global = { cardName: "", presets: globalRowPresets };
// Marketing
allRowPresets["marketing.forms_details"] = marketingFormsPresets;
// Prospect
// allRowPresets['booker.routes.debug'] = marketingFormsPresets;
allRowPresets["booker.reports_prospect"] = prospectReportsPreset;
allRowPresets["booker.routes_prospect"] = prospectRoutesPreset;
// Report Details
allRowPresets["booker.reports_details"] = reportDetailsPreset;
// Route Details
allRowPresets["booker.routes_details"] = routeDetailsPreset;
// Distro Details
allRowPresets["distro.logs_details"] = distroDetailsPreset;
// Users
allRowPresets["users"] = usersPreset;
// Events
allRowPresets["booker.salesforce.event"] = salesforceEvents;
// Workspaces
allRowPresets["workspaces"] = workspaces;
