export const BookingSourceFromContext = (
    source: string,
    bookingUrl: string,
    suggestedTimesData: unknown,
    routerId: string,
    queueId: string
): string => {
    const suggestedTimes = suggestedTimesData && typeof suggestedTimesData !== 'undefined';
    if (suggestedTimes) {
        return 'Suggested Times';
    }
    switch (source) {
        case 'Online':
            if (bookingUrl?.includes('/book/me/')) {
                return 'Personal Booking Link';
            } else if (routerId) {
                return 'Router Link';
            } else if (queueId) {
                return 'Queue Link';
            } else if (!bookingUrl) {
                return 'Rescheduling Link';
            }
            break;
        case 'Phone':
            if (routerId) {
                return 'Live Call Router';
            } else if (queueId) {
                return 'Live Call Queue';
            }
            break;
        case 'Booker':
            return 'Instant Booker';
        default:
            break;
    }

    return 'This meeting was likely rescheduled and some data was lost';
};